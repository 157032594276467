import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';


const useModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid1: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
      },
    },
    button:{
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    form:{
      backgroundColor: "white",
    },
  }),
);

export default useModalStyles;