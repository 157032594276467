import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../../../services/api';
import IPut from 'iput';

import './styles.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

function IPutComponent(props) {
  const { inputRef, ...other } = props;
  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
    },
  }));

  return <IPut {...other} />;
}

interface ServidorCadastrar {
  id: string;
}

interface Cluster {
  id: string;
  name: string,
}

interface ParamTypes {
  id: string;
}
function ServidorCadastrar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [hostname, setHostname] = useState('');
  const [cloudId, setCloudId] = useState('');
  const [ip, setIp] = useState('');
  const [externalIp, setExternalIp] = useState('');
  const [serverType, setServerType] = useState('');
  const [url, setUrl] = useState('');

  const [isNew, setIsNew] = useState(false);
  const [isIp, setIsIp] = useState(false);
  const [isExternalIp, setIsExternalIp] = useState(false);
  const [cluster, setCluster] = React.useState<Cluster[]>([]);
  const [clusters, setClusters] = React.useState<Cluster[]>([]);


  const handleChangeServerType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setServerType(event.target.value as string);
  }

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (ip.length > 0) {
      setIsIp(true);

    }
  }, [ip]);

  useEffect(() => {
    if (externalIp.length > 0) {
      setIsExternalIp(true);

    }
  }, [externalIp]);

  useEffect(() => {
    if (!isNew) {
      if (id) {
        try {
          const getData = async () => {
            const response = await api.get('/servers/' + id);
            if (response.status && response.status.toString()[0] === '2') {
              const data = await response.json();

              setHostname(data.hostname);
              setIp(data.ip);
              setExternalIp(data.externalIp);
              setCloudId(data.cloudId);
              setServerType(data.serverType);
              setUrl(data.url);
              setCluster(data.clusters);
            }
          };

          getData();
        }
        catch (error) {
          Swal.fire({
            title: 'IP Interno inválido',
            text: 'Preencha todos octetos',
            icon: 'error',
          });
        }

      }

    }
    const getClusters = async () => {
      const response = await api.get('/clusters?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setClusters((await response.json()).data);
      }
    };
    getClusters();
  }, [id, isNew]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢 ' + id,
          icon: 'error',
        });
        return;
      }


      const camposIP = ip.split('.');
      const camposIPExterno = externalIp.split('.');
      if (camposIP.includes("")) {
        Swal.fire({
          title: 'IP Interno inválido',
          text: 'Preencha todos octetos',
          icon: 'error',
        });
        return;
      }

      if (camposIPExterno.includes("")) {
        Swal.fire({
          title: 'IP Externo inválido',
          text: 'Preencha todos octetos',
          icon: 'error',
        });
        return;
      }

      let result;
      if (isNew) {
        result = await api.put("/servers", {
          id: id,
          hostname: hostname,
          ip: ip,
          enabled: true,
          externalIp: externalIp,
          cloudId: cloudId,
          serverType: serverType,
          url: url,
          clusters: cluster,
        });

      }
      else {
        result = await api.patch("/servers/" + id, {
          hostname: hostname,
          ip: ip,
          enabled: true,
          externalIp: externalIp,
          cloudId: cloudId,
          serverType: serverType,
          url: url,
          clusters: cluster,
        });

      }

      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/servidores');
          }
        });
      }
      else {
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }

    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Servidores</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <Autocomplete
                        multiple
                        getOptionLabel={(clusters) => clusters.name}
                        options={clusters}
                        value={cluster}
                        onChange={(event, values) => { setCluster(values) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Clusters"
                          />
                        )}
                      />
                    </div>
                    <div className={classes.grid2}>
                      <TextField
                        
                        label="ID Cloud"
                        variant="outlined"
                        required
                        name="cloudId"
                        value={cloudId}
                        onChange={(e) => { setCloudId(e.target.value) }}
                      />
                      <TextField
                        
                        label="Hostname"
                        variant="outlined"
                        required
                        name="hostname"
                        value={hostname}
                        onChange={(e) => { setHostname(e.target.value) }}
                      />
                    </div>


                    <div className={classes.grid2}>
                      {!isNew ? (
                        <>
                          {isIp ? (
                            <TextField
                              
                              label="IP Interno"
                              variant="outlined"
                              required
                              name="ip"
                              value={ip}
                              InputProps={{
                                inputComponent: IPutComponent,
                                inputProps: { component: IPut, ...(ip ? { defaultValue: ip } : {}) },
                              }}
                              onChange={e => { setIp(e.toString()) }}
                              InputLabelProps={{ shrink: true }}
                            />


                          ) : (
                              <></>
                            )}

                          {isExternalIp ? (
                            <TextField
                              label="IP Externo"
                              variant="outlined"
                              required
                              name="externalIp"
                              value={externalIp}
                              InputProps={{
                                inputComponent: IPutComponent,
                                inputProps: { component: IPut, ...(externalIp ? { defaultValue: externalIp } : {}) },
                              }}
                              InputLabelProps={{ shrink: true }}
                              onChange={e => { setExternalIp(e.toString()) }}
                            />
                          ) : (
                              <></>
                            )}

                        </>) : (
                          <>
                            <TextField
                              
                              label="IP Interno"
                              variant="outlined"
                              required
                              name="ip"
                              value={ip}
                              InputProps={{
                                inputComponent: IPutComponent,
                                inputProps: { component: IPut },
                              }}
                              onChange={e => { setIp(e.toString()) }}
                              InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                              
                              label="IP Externo"
                              variant="outlined"
                              required
                              name="externalIp"
                              value={externalIp}
                              InputProps={{
                                inputComponent: IPutComponent,
                                inputProps: { component: IPut },
                              }}
                              InputLabelProps={{ shrink: true }}
                              onChange={e => { setExternalIp(e.toString()) }}
                            /></>
                        )}
                    </div>


                    <div className={classes.grid2}>
                              
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Tipo de Máquina</InputLabel>
                        <Select
                          id="select"
                          required
                          native
                          value={serverType}
                          onChange={handleChangeServerType}
                          label="Tipo de Máquina"
                        >
                          <option aria-label="None" value="" />
                          <option value={'AD'}>Active Directory</option>
                          <option value={'AS'}>Application Server</option>
                          <option value={'DBS'}>Database Server</option>
                        </Select>

                      </FormControl>
                      <TextField
                        
                        label="URL TSPlus"
                        variant="outlined"
                        name="url"
                        value={url}
                        onChange={(e) => { setUrl(e.target.value) }}
                      />
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/servidores') }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ServidorCadastrar;
