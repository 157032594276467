import React, { SVGProps, useState } from 'react';

import { NavLink } from 'react-router-dom';
import api from '../../services/api';
import './styles.scss';
import Swal from 'sweetalert2';

interface SideMenuProps extends SVGProps<SVGElement> {
  device: string;
  options: Array<{
    title: string;
    link: string;
    icon: SVGProps<SVGElement>;
    submenu?: Array<{ title: string; link: string }>;
  }>;
}

const SideMenu: React.FC<SideMenuProps> = ({ device, options }) => {
  const [menu, setMenu] = useState(true);

  const clickMenu = async (device: string, title: string) => {
    if (title === 'Logout') {

      try {
        if (localStorage.getItem('admin')) {

          const headers = {
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken')
          };
          console.log(headers);
          await api.delete('/auth/admin', headers);


          localStorage.removeItem('admin');
          localStorage.removeItem('signed');
          localStorage.removeItem('username');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('acl');

          window.location.pathname = "/";
        }
        else {

          const headers = {
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken')
          };
          await api.delete('/auth/', headers);


          localStorage.removeItem('admin');
          localStorage.removeItem('user');
          localStorage.removeItem('username');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('acl');

          window.location.pathname = "/";
        }
      } catch (error) {
        console.log('error signing out: ', error);
        Swal.fire({
          title: 'Erro 😢',
          text: 'Erro ao fazer logout, por favor tente novamente!',
          icon: 'error',
        });
      }
    }

    setMenu(!menu);
    if (device === 'mobile') {
      document.getElementsByClassName("app-container")[0].id = '';
    }
  }
  const clickToggle = (menu: boolean) => {
    setMenu(menu);
    if (menu) {
      document.getElementsByClassName("app-container")[0].id = '';
    } else {
      document.getElementsByClassName("app-container")[0].id = 'compact-menu';
    }
  }

  return (
    <>


      <button className="toggle" onClick={() => clickToggle(!menu)}><i></i></button>
      { localStorage.getItem('admin') ? (
        <aside className="side-menu">
          <div className="menu-content">
            <ul>
              {options.map(option => {
                return (
                  <>
                    {option &&
                      <li key={option.title} className="item-menu">
                        <NavLink
                          activeClassName="active"
                          onClick={() => { clickMenu(device, option.title) }}
                          to={option.link}>{option.icon}
                          <span>{option.title}</span>
                        </NavLink>


                        {option.submenu?.map(submenu => {
                          if (submenu.title.length > 0) {
                            return (
                              <ul className="submenu" key={submenu.title}>
                                <li className="item-sub">
                                  <NavLink
                                    activeClassName="active"
                                    onClick={() => { clickMenu(device, option.title) }}
                                    to={submenu.link}>
                                    <span>{submenu.title}</span>
                                  </NavLink>
                                </li>
                              </ul>
                            );
                          }
                        })}
                      </li>
                    }
                  </>
                );
              })}
            </ul>
          </div>
        </aside>
      ) : (
        <div className="sideUser">
          <aside className="side-menu">
            <div className="menu-content">
              <ul>
                {options.map(option => {
                  return (
                    <li key={option.title} className="item-menu">
                      <NavLink
                        activeClassName="active"
                        onClick={() => { clickMenu(device, option.title) }}
                        to={option.link}>{option.icon}
                        <span>{option.title}</span>
                      </NavLink>


                      {option.submenu?.map(submenu => {
                        if (submenu.title.length > 0) {
                          return (
                            <ul className="submenu" key={submenu.title}><li className="item-sub"><NavLink to={submenu.link}> <span>{submenu.title}</span></NavLink></li></ul>
                          );
                        }
                      })}


                    </li>
                  );
                })}
              </ul>
            </div>
          </aside>
        </div>
      )}


    </>
  );
}

export default SideMenu;
