import React, { useState } from 'react'

import useModalStyles from './styles'
import Input from '../Input/index'

import Swal from 'sweetalert2';

import { Button } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import api from '../../services/api';
import { handleResponse } from '../../utils/handleResponse';


interface Props {
  setOpenModal: (e: boolean) => void
  id: string
}

const RedefinePassword = ({ setOpenModal, id }: Props) => {
  const classes = useModalStyles()

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  async function handleSubmitNewPassword(e: React.FormEvent) {
    e.preventDefault()
    try {

      if (newPassword !== passwordRepeat) {
        setOpenModal(false)
        Swal.fire({
          title: 'Senhas diferentes',
          text: 'Digite a mesma senha para os dois campos',
          icon: 'warning',
        });
        return
      }

      const data = { id, newPassword, oldPassword }
      const response = await api.post('/auth/redefine-user-password', data)
      const success = handleResponse(response, "Senha Inválida")

      if (success) {
        setOpenModal(false)
        Swal.fire({
          title: 'Senha redefinida com sucesso!',
          icon: 'success',
        });
      }
    }
    catch (error) {
      setOpenModal(false)
      Swal.fire({
        title: error,
        icon: 'warning',
      });
    }
  }


  return (
    <div className='modalRedefinePassword'>
      <form onSubmit={handleSubmitNewPassword}>
        <h1>Redefinição de Senha </h1>

        <section className={classes.grid1}>
          <Input
            type="password"
            label="Senha"
            name="password"
            onChange={(e) => setOldPassword(e.target.value)}
          />

          <Input
            type="password"
            label="Nova Senha"
            name="newpassword"
            onChange={(e) => setNewPassword(e.target.value)}
          />

          <Input
            type="password"
            label="Repita a nova senha"
            name="passwordrepeat"
            onChange={(e) => setPasswordRepeat(e.target.value)}
          />

        </section>

        <div className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            startIcon={<SaveIcon />}
          >
            Salvar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<BackIcon />}
            onClick={() => setOpenModal(false)}
          >
            Voltar
          </Button>
        </div>
      </form>
    </div>
  )
}


export default RedefinePassword;