import React from 'react';

import './assets/styles/global.scss';

import Routes from './routes';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);

function App() {
  const rootPath = window.location.href.replace(`${window.location.origin}/`, '').split('/')[0];
  const hostname = window.location.hostname;

  let selectedFrontend: 'admin' | 'cliente' | '' = '';
  let isLocalhost = false;

  if (hostname.includes('aokiinova.com.br')) {
    switch (hostname.split('.')[0]) {
      case 'admin':
        selectedFrontend = 'admin';
        break;
      case 'cliente':
      default:
        selectedFrontend = 'cliente';
        break;
    }
  } else if (['admin', 'cliente'].includes(rootPath)) {
    selectedFrontend = rootPath as ('admin' | 'cliente');
    isLocalhost = true;
  }

  return <ThemeProvider theme={theme}><Routes frontend={selectedFrontend} {...{ isLocalhost }}/></ThemeProvider>;
}

export default App;
