import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Button, FormControl, InputLabel, Select } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { unformatDate, formatDate } from '../../../../utils/dateUtils';

import Swal from 'sweetalert2';

import api from '../../../../services/api';


import './styles.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface ParamTypes {
  id: string;
}

interface Admin {
  id: string;
  name: string;
}


function AprovarAtividades() {
  const [token, setToken] = useState('');
  const params = useParams<ParamTypes>();
  const [issueDate, setIssueDate] = useState('');
  const [visitNumber, setVisitNumber] = useState('');
  const [accomplishmentDate, setAccomplishmentDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [breakTimeStart, setBreakTimeStart] = useState('');
  const [breakTimeEnd, setBreakTimeEnd] = useState('');
  const [endTime, setEndTime] = useState('');
  const [type, setType] = useState('Presencial');
  const [activitySite, setActivitySite] = useState('Cliente');
  const [activityType, setActivityType] = useState('Visita');
  const [revenue, setRevenue] = useState('Faturavel');
  const [paidHours, setPaidHours] = useState('0');
  const [resposableClient, setResposableClient] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [activityDescription, setActivityDescription] = useState('');
  const [pendingItems, setPendingItems] = useState('');
  const [confirmedName, setConfirmedName] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [approved, setApproved] = useState(false);
  const [allowance, setAllowance] = useState('');
  const [detour, setDetour] = useState('');
  const [detourHours, setDetourHours] = useState('');
  const [distance, setDistance] = useState('');
  const [toll, setToll] = useState('');
  const [meal, setMeal] = useState('');
  const [otherExpenses, setOtherExpenses] = useState('');
  const [comment, setComment] = useState('');
  const [stay, setStay] = useState('');


  const [responsableAoki, setResponsableAoki] = React.useState<Admin | null | undefined>({ id: '', name: '' });
  const [adminList, setAdminList] = React.useState<Admin[]>([]);

  const classes = useStyles();
  const history = useHistory();


  interface ParamTypes {
    token: string;
  }
  if (!token) {
    if (params.token) {
      setToken(params.token);
    }
  }
  useEffect(() => {

    if (token) {
      const getData = async () => {
        try {
          const response = await api.get('/approve-activity/' + token);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setIssueDate(data.issueDate);
            setVisitNumber(data.visit);
            setAccomplishmentDate(data.accomplishmentDate);
            setStartTime(data.startTime);
            setBreakTimeStart(data.breakTimeStart);
            setBreakTimeEnd(data.breakTimeEnd);
            setEndTime(data.endTime);
            setResponsableAoki(data.responsableAoki);
            setType(data.type);
            setActivitySite(data.activitySite);
            setActivityType(data.activityType);
            setRevenue(data.revenue);
            setPaidHours(data.paidHours);
            setResposableClient(data.resposableClient);
            setClientEmail(data.clientEmail);
            setActivityDescription(data.activityDescription);
            setPendingItems(data.pendingItems);
            setApproved(data.approved);
            setDetourHours(data.detourHours);
            setDetour(data.detour);
            setDistance(data.distance);
            setToll(data.toll);
            setMeal(data.meal);
            setOtherExpenses(data.otherExpenses);
            setComment(data.comment);
            setAllowance(data.allowance);
            setStay(data.stay);
          } else {
            Swal.fire({
              title: 'Erro',
              text: 'O token é inválido ou está expirado. Solicite ao responsável um novo token para seguir com a aprovação.',
              icon: 'error',
            });
          };
        } catch (error) {
          Swal.fire({
            title: 'Erro!',
            text: 'Não foi possível recuperar os dados. Por favor tente mais tarde.',
            icon: 'error',
          });
        }
      }
      getData();
    }
  }, [token, activityDescription]);

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  }
  const handleChangeActivitySite = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActivitySite(event.target.value as string);
  }
  const handleChangeActivityType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActivityType(event.target.value as string);
  }
  const handleChangeRevenue = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRevenue(event.target.value as string);
  }

  async function handleRejectButton() {
    try {
      let result;
      result = await api.post("approve-activity/reject-activity/" + token, {
        accomplishmentDate: formatDate(accomplishmentDate),
        startTime,
        breakTimeStart,
        breakTimeEnd,
        endTime,
        responsableAoki,
        type,
        activitySite,
        activityType,
        revenue,
        paidHours: Number(paidHours),
        resposableClient,
        clientEmail,
        activityDescription,
        pendingItems,
        allowance,
        detour,
        detourHours,
        distance,
        toll,
        meal,
        otherExpenses,
        stay,
        comment,
      }
      );
      if (result.status && result.status.toString()[0] === '2') {

        Swal.fire({
          title: 'Atividade Rejeitada com sucesso!',
          icon: 'error',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.assign('https://aokiinova.com.br');
          } else {
            history.push('/atividades');
          }
        });
      } else {
        throw new Error('Não foi possível executar a ação, tente novamente mais tarde.');
      }
    } catch (error) {

    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      let result;
      result = await api.post("/approve-activity/" + token, {
        accomplishmentDate: formatDate(accomplishmentDate),
        startTime,
        breakTimeStart,
        breakTimeEnd,
        endTime,
        responsableAoki,
        type,
        activitySite,
        activityType,
        revenue,
        paidHours: Number(paidHours),
        resposableClient,
        clientEmail,
        activityDescription,
        pendingItems,
        allowance,
        detour,
        detourHours,
        distance,
        toll,
        meal,
        otherExpenses,
        stay,
        comment,
        confirmedName,
        confirmedEmail,
      });


      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Atividade aprovada com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.assign('https://aokiinova.com.br');
          } else {
            history.push('/atividades');
          }
        });
      }
      else {
        throw new Error('Não foi possível aprovar, verifique o nome e o e-mail digitado.');
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Não foi possível aprovar. Por favor tente novamente.',
        icon: 'error',
      });
    }

  }
  if (approved) return <h1>Atividade já aprovada</h1>
  return (
    <div className="approvalForm">
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Aprovação de Atividade</h1>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.grid}>

                      <TextField
                        variant="outlined"
                        label="Data de Emissão"
                        type="date"
                        disabled
                        value={unformatDate(issueDate)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Nº da Visita"
                        variant="outlined"
                        disabled
                        name="visitNumber"
                        value={visitNumber}
                      />
                      <TextField
                        variant="outlined"
                        label="Data de Realização"
                        type="date"
                        disabled
                        required
                        value={unformatDate(accomplishmentDate)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField

                        label="Hora de Início"
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        disabled
                        required
                        name="startTime"
                        value={startTime}
                      />
                      <TextField

                        label="Início do Intervalo"
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        disabled
                        required
                        name="breakTimeStart"
                        value={breakTimeStart}
                      />
                      <TextField

                        label="Término do Intervalo"
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        disabled
                        required
                        name="breakTimeEnd"
                        value={breakTimeEnd}
                      />
                      <TextField

                        label="Hora de Término"
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        disabled
                        required
                        name="endTime"
                        value={endTime}
                      />
                    </div>
                    <div className={classes.grid}>
                      <Autocomplete
                        value={responsableAoki}
                        getOptionLabel={(admin) => admin.name}
                        options={adminList}
                        onChange={(event, value) => { setResponsableAoki(value) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            disabled
                            variant="outlined"
                            label="Responsável Aoki"
                          />
                        )}
                      />
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Modalidade</InputLabel>
                        <Select
                          required
                          disabled
                          native
                          value={type}
                          onChange={handleChangeType}
                          label="Modalidade"
                        >
                          <option value={'1-Presencial'}>Presencial Cliente</option>
                          <option value={'2-Online'}>Online</option>
                          <option value={'3-Em Terceiro'}>Em terceiro</option>
                          <option value={'4-Interno Aoki'}>Interno Aoki</option>
                          <option value={'5-Home Office'}>Home Office</option>
                        </Select>

                      </FormControl>
                    </div>
                    <div className={classes.grid}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Local da Atividade</InputLabel>
                        <Select
                          required
                          disabled
                          native
                          value={activitySite}
                          onChange={handleChangeActivitySite}
                          label="Local da Atividade"
                        >
                          <option value={'1-Cliente'}>Cliente</option>
                          <option value={'2-Aoki'}>Aoki</option>
                          <option value={'3-Home Office'}>Home Office</option>
                          <option value={'9-Outro Local'}>Outro Local</option>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Natureza</InputLabel>
                        <Select
                          required
                          native
                          disabled
                          value={activityType}
                          onChange={handleChangeActivityType}
                          label="Natureza"
                        >
                          <option value={'1-Visita'}>Visita Técnica</option>
                          <option value={'2-Treinamento'}>Treinamento</option>
                          <option value={'3-Em terceiro'}>Em Terceiro</option>
                          <option value={'4-Suporte'}>Suporte</option>
                          <option value={'5-Desenvolvimento'}>Desenvolvimento</option>
                          <option value={'6-Atividade Interna Projeto'}>Atividade Interna Projeto</option>
                          <option value={'51-Reunião Pós-venda'}>Reunião Pós-venda</option>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Faturamento</InputLabel>
                        <Select
                          required
                          native
                          disabled
                          value={revenue}
                          onChange={handleChangeRevenue}
                          label="Faturamento"
                        >
                          <option value={'Faturavel'}>Faturável</option>
                          <option value={'Bonificada'}>Bonificada</option>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Horas Abonadas"
                        type="number"
                        variant="outlined"
                        disabled
                        required
                        name="paidHours"
                        value={paidHours}
                      />
                      <TextField
                        label="Natureza do Abono"
                        variant="outlined"
                        disabled
                        name="allowance"
                        value={allowance}
                      />
                      <TextField
                        label="Horas Desvio"
                        type="number"
                        variant="outlined"
                        disabled
                        name="detourHours"
                        value={detourHours}
                      />
                      <TextField
                        label="Natureza do Desvio"
                        variant="outlined"
                        disabled
                        name="detour"
                        value={detour}
                      />
                    </div>

                    <div className={classes.grid}>
                      Cliente
                    </div>
                    <div className={classes.grid}>

                      <TextField
                        label="Nome completo do cliente"
                        variant="outlined"
                        disabled
                        required
                        name="resposableClient"
                        value={resposableClient}
                      />
                      <TextField
                        label="Email do Cliente"
                        variant="outlined"
                        disabled
                        required
                        name="clientEmail"
                        value={clientEmail}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Descrição da Atividade"
                        variant="outlined"
                        disabled
                        multiline
                        name="activityDescription"
                        value={activityDescription}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Itens Pendentes"
                        variant="outlined"
                        disabled
                        name="pendingItems"
                        value={pendingItems}
                      />
                    </div>
                    <div className={classes.grid}>
                      Despesas
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Refeição"
                        variant="outlined"
                        disabled
                        name="meal"
                        value={meal}
                      />
                      <TextField
                        label="Estadia"
                        variant="outlined"
                        disabled
                        name="stay"
                        value={stay}
                      />
                      <TextField
                        label="Outros"
                        variant="outlined"
                        disabled
                        name="otherExpenses"
                        value={otherExpenses}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Quilometragem"
                        variant="outlined"
                        disabled
                        name="distance"
                        value={distance}
                      />
                      <TextField
                        label="Valor do pedágio"
                        variant="outlined"
                        disabled
                        name="toll"
                        value={toll}
                      />
                    </div>
                    <div>
                      <p>Para aprovar essa atividade, confirme seu nome completo como no campo Nome completo do cliente e e-mail nos campos abaixo. Em caso de divergência entre em contato com o consultor que realizou o atendimento</p>
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Confirme seu nome completo"
                        variant="outlined"
                        name="confirmedName"
                        value={confirmedName}
                        onChange={(e) => { setConfirmedName(e.target.value) }}
                      />
                      <TextField
                        label="Confirme seu email"
                        variant="outlined"
                        name="confirmedEmail"
                        value={confirmedEmail}
                        onChange={(e) => { setConfirmedEmail(e.target.value) }}
                      />
                    </div>

                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Aprovar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<DeleteIcon />}
                        onClick={handleRejectButton}
                      >
                        Rejeitar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default AprovarAtividades;
