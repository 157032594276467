import React, { ChangeEvent, useEffect, useState, MouseEvent } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import nodata from "../../../assets/images/nodata.svg";

import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';


import { TextField, Button, Select, MenuItem } from '@material-ui/core';


import api from '../../../services/api';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';
import { dateToString } from '../../../utils/dateUtils';
import "./styles.scss";

interface Data {
    author: string;
    description: number;
    createdAt: string;
    target: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'createdAt', numeric: false, disablePadding: true, label: 'Data de Criação' },
    { id: 'author', numeric: false, disablePadding: true, label: 'Autor' },
    { id: 'description', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'target', numeric: false, disablePadding: true, label: 'Registro em Questão' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">

                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: '#fff  ',
                    backgroundColor: '#01a3ff',
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

enum EntityTypes {
    USER = 'user',
    ADMIN = 'admin',
    COMPANY = 'company'
}

export default function Logs() {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof Data>('createdAt');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(0);

    const [selectedEntity, setSelectedEntitiy] = useState<EntityTypes>()
    const [rows, setRows] = useState([]);
    const [searchDescription, setSearchDescription] = useState('');
    const [searchCreatedAtStart, setSearchCreatedAtStart] = useState('');
    const [searchCreatedAtEnd, setSearchCreatedAtEnd] = useState('');
    const [display, setDisplay] = useState('grid');
    const handleFilter = () => {
        if (display === 'grid') {
            setDisplay('none');
        } else {
            setDisplay('grid');
        }
    }

    const handleChangeSelectedEntity = (event: ChangeEvent<{ value: unknown }>) => {
        setSelectedEntitiy(event.target.value as EntityTypes);
    }

    const handleSearch = async () => {
        const queryParams = new URLSearchParams();
        if (searchCreatedAtStart) {
            queryParams.append('createdAtStart', searchCreatedAtStart);
        } if (searchCreatedAtEnd) {
            queryParams.append('createdAtEnd', searchCreatedAtEnd);
        }
        if (searchDescription) {
            queryParams.append('description', searchDescription);
        }
        const getData = async () => {
            if (!selectedEntity)
                return

            const response = await api.get(`/logs/${selectedEntity}?${queryParams.toString()}&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);
            if (response.status && response.status.toString()[0] === '2') {
                const data = await response.json();
                setRows(data.data);
                setTotalPages(data.total);
            }
        };
        getData();
    }


    useEffect(() => {
        handleSearch()
    }, [orderBy, order, page, rowsPerPage, selectedEntity]);

    const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n: any) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const classes = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Logs
                </Typography>
                {selectedEntity &&
                    <Tooltip title="Filtro de dados">
                        <IconButton onClick={handleFilter} aria-label="filter">
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar>
        );
    };

    return (
        <main>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <div className='selectContainer'>
                        <p>
                            Selecione a entidade que você quer ver os logs:
                        </p>
                        <FormControl className="selectEntity" variant='outlined'>
                            <Select
                                id="select"
                                required
                                value={selectedEntity}
                                onChange={handleChangeSelectedEntity}
                            >
                                <MenuItem />
                                <MenuItem value={EntityTypes.ADMIN}>Admins</MenuItem>
                                <MenuItem value={EntityTypes.USER}>Usuários</MenuItem>
                                <MenuItem value={EntityTypes.COMPANY}>Clientes/Empresas</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {selectedEntity &&
                        <TableContainer>
                            <div id="filtros" style={{ display: display, transition: "width 2s, height 4s", transitionDuration: "2s" }}>
                                <div className="filterField">
                                    <TextField
                                        label="Descrição"
                                        variant="standard"
                                        value={searchDescription}
                                        onChange={(e) => { setSearchDescription(e.target.value) }}
                                    />
                                    <TextField
                                        variant="standard"
                                        label="Data Inicial"
                                        type="date"
                                        value={searchCreatedAtStart}
                                        onChange={(e) => { setSearchCreatedAtStart(e.target.value) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                        variant="standard"
                                        label="Data Final"
                                        type="date"
                                        value={searchCreatedAtEnd}
                                        onChange={(e) => { setSearchCreatedAtEnd(e.target.value) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        className="searchButton"
                                        color="primary"
                                        startIcon={<SearchIcon />}
                                        onClick={handleSearch}
                                    >
                                        Pesquisar
                                    </Button>
                                </div>
                            </div>
                            {totalPages > 0 ? (
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {rows.map((row: any, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox" />
                                                    <TableCell align="left">
                                                        {dateToString(row.createdAt)}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row">
                                                        {row.author.name}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.description}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row">
                                                        {row.target?.name}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>
                                </Table>
                            ) : (
                                <>
                                    <div className="no-data">
                                        <img src={nodata} className="no-data-svg" alt="Sem dados" />
                                        <div>Nenhum registro disponível para exibição</div>
                                    </div>
                                </>)}
                        </TableContainer>
                    }
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalPages}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </main >
    );
}
