import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import MaskedInput from 'react-text-mask';

import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../../../services/api';

import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    pass: {
      display: 'abolsute',

      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);


interface ParamTypes {
  id: string;
}

interface PhoneMaskProps {
  inputRef: () => void;
}

function PhoneMask(props: PhoneMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}



interface State {
  phone: string;
}
interface Profile {
  id: string;
  name: string;
}

function AdminCadastrar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState<string | undefined>('');
  const [email, setEmail] = useState('');

  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordIcon, setPasswordIcon] = React.useState(<ShowEye />);

  const [isNew, setIsNew] = useState(false);

  const [profile, setProfile] = React.useState<Profile | null | undefined>({ id: '', name: '' });

  const [profiles, setProfiles] = React.useState<Profile[]>([]);

  const [values, setValues] = React.useState<State>({
    phone: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const classes = useStyles();
  const history = useHistory();

  const getIsNew = useCallback(() => {
    if (!id) {
      if (params.id) {
        setId(params.id);
      } else {
        setIsNew(true);
      }
    }
  }, [id, params.id]);

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }


  useEffect(() => {
    const getProfiles = async () => {
      const response = await api.get('/profiles');
      if (response.status && response.status.toString()[0] === '2') {
        setProfiles((await response.json()).data);
      }
    };
    getProfiles();

    if (!isNew) {

      if (id) {
        const getData = async () => {
          const response = await api.get('/admins/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setValues(values => ({
              ...values,
              phone: data.phone,
            }));
            setName(data.name);
            setLogin(data.login);
            setProfile(data.profile);
            setEmail(data.email);
            setPassword(data.password);
          }
        };
        getData();
      }
    }
    getIsNew();
  }, [id, isNew, getIsNew]);



  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (values.phone.replace(/ /g, '').length < 14) {
        Swal.fire({
          title: 'Telefone inválido',
          text: 'Preencha todos caracteres',
          icon: 'error',
        });
        return;
      }

      let result;
      const object = {
        name,
        login,
        password,
        phone: values.phone,
        email,
        enabled: true,
        profile: { id: profile?.id },
      }
      if (isNew) {
        result = await api.put("/admins", object);
      }
      else {
        delete object.password;
        result = await api.patch("/admins/" + id, object);
      }

      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/admins');
          }
        });
      }
      else {
        if (result.status === 409) {
          const errorText = await result.text();
          if (errorText === 'E_ADMIN_LOGIN') {
            Swal.fire({
              title: 'Login já existente',
              icon: 'warning',
            });
            return;
          }
          if (errorText === 'E_ADMIN_EMAIL') {
            Swal.fire({
              title: 'Email já existente',
              icon: 'warning',
            });
            return;
          }
        }
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro 😢',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Usuários</h1>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.grid2}>
                      <Autocomplete
                        id="controllable-states-demo"
                        value={profile}
                        getOptionLabel={(profile) => profile.name}
                        options={profiles}
                        onChange={(event, value) => { setProfile(value) }}
                        renderInput={(params) => <TextField {...params} label="Perfil" required variant="outlined" />}
                      />
                      <TextField
                        id="login"
                        label="Login"
                        variant="outlined"
                        required
                        name="login"
                        value={login}
                        onChange={(e) => { setLogin(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Nome Completo"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid2}>
                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        required
                        name="email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                      <TextField
                        id="phone"
                        label="Telefone"
                        variant="outlined"
                        name="phone"
                        InputProps={{
                          inputComponent: PhoneMask as any,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </div>
                    {isNew && (
                      <div className={classes.grid}>
                        <div className={classes.pass}>

                          <TextField
                            required
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Senha"
                            variant="outlined"
                            name="password"
                            onChange={(e) => { setPassword(e.target.value) }}
                          />
                          <div className="container-pass">
                            <span className="passMUI" onClick={(e) => { handlePassword() }}>{passwordIcon}</span>
                          </div>
                        </div>
                      </div>
                    )
                    }
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/admins') }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminCadastrar;
