import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RestoreIcon from '@material-ui/icons/Restore';

import nodata from "../../../../assets/images/nodata.svg";
import { NavLink } from 'react-router-dom';

import { Action, Resource } from '../../../../utils/aclUtils';

import Swal from 'sweetalert2';

import api from '../../../../services/api';

interface Data {
    serviceDescription: string;
    category: string;
    executionTime: number;
    minimumPrice: number;
    onlinePrice: number;
    onsitePrice: number;
    options: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'serviceDescription', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'category', numeric: false, disablePadding: true, label: 'Categoria' },
    { id: 'executionTime', numeric: false, disablePadding: true, label: 'Tempo de Execução em horas' },
    { id: 'minimumPrice', numeric: false, disablePadding: true, label: 'Valor hora mínimo' },
    { id: 'onlinePrice', numeric: false, disablePadding: true, label: 'Valor hora online' },
    { id: 'onsitePrice', numeric: false, disablePadding: true, label: 'Valor hora presencial' },
    { id: 'options', numeric: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: '#fff  ',
                    backgroundColor: '#01a3ff',
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
        grid2: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
                width: '50%',
            },
        },

    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selecionado(s)
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Serviços Inativos
                </Typography>
            )}

            <Tooltip title="Voltar">
                <IconButton aria-label="Voltar">
                    <NavLink to="servicos"><ArrowBackIcon /></NavLink>
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        grid2: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
                width: '50%',
            },
        },
        button: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }),
);

let aclItem;
if (localStorage.getItem('acl')) {
    const aclString = localStorage.getItem('acl');
    const acl: { resource: Resource, action: Action[] | '*' }[] = JSON.parse(aclString!);
    aclItem = acl.find(aclItem => aclItem.resource === 'Service');
}


export default function ServicosInativos() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('serviceDescription');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [totalPages, setTotalPages] = React.useState(0);

    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        const getData = async () => {
            const response = await api.get(`/services/?disabled&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);
            if (response.status && response.status.toString()[0] === '2') {
                const data = await response.json();
                setRows(data.data);
                setTotalPages(data.total);
            }
        };

        getData();
    }, [orderBy, order, page, rowsPerPage]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n: any) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;



    const handleEnable = async (id: string) => {
        Swal.fire({
            title: 'Você tem certeza que deseja restaurar o serviço?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#418107",
            confirmButtonText: "Sim, restaurar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    const enableConfirmed = async () => {
                        await api.post("/services/restore/" + id);
                        const response = await api.get(`/services/?disabled&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);
                        if (response.status && response.status.toString()[0] === '2') {
                            const data = await response.json();
                            setRows(data.data);
                            setTotalPages(data.total);
                        }
                    }

                    enableConfirmed();
                } catch (error) {
                    console.log(`Erro: ${error}`);
                }
            }
        });

    };



    return (

        <main>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        {totalPages > 0 ? (
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {rows.map((row: any, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">

                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row">
                                                    {row.serviceDescription}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.category.categoryDescription}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.executionTime}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.minimumPrice}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.onlinePrice}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.onsitePrice}
                                                </TableCell>
                                                <TableCell align="right">

                                                    {(aclItem && (aclItem.action === '*' || aclItem.action.includes('UPDATE'))) && <Tooltip title="Ativar" onClick={() => handleEnable(row.id)} >
                                                        <IconButton aria-label="Ativar" >
                                                            <RestoreIcon className="ativar" />
                                                        </IconButton>
                                                    </Tooltip>}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            </Table>
                        ) : (
                            <>
                                <div className="no-data">
                                    <img src={nodata} className="no-data-svg" alt="Sem dados" />
                                    <div>Nenhum registro disponível para exibição</div>
                                </div>
                            </>)}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalPages}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </main>
    );
}

