
import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete'
import api from '../../../services/api';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

import { v4 as uuidv4 } from 'uuid';




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
                width: '100%',
            },
        },
        grid: {
            display: 'flex',
            cursor: 'not-allowed ',
            '@media screen and (max-width: 991px)': {
                display: 'block',
            },
            '& > *': {
                margin: theme.spacing(1),
                width: '100%',
                '@media screen and (max-width: 991px)': {
                    width: '100%',
                }
            },
        },
        grid2: {
            display: 'flex',
            '@media screen and (max-width: 991px)': {
                display: 'block',
            },
            '& > *': {
                margin: theme.spacing(1),
                width: '50%',
                '@media screen and (max-width: 991px)': {
                    width: '100%',
                }
            },
        },
        grid3: {
            display: 'flex',
            '@media screen and (max-width: 991px)': {
                display: 'block',
            },
            '& > *': {
                margin: theme.spacing(1),
                width: '50%',
                '@media screen and (max-width: 991px)': {
                    width: '100%',
                }
            },
        },
        grid4: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
                width: '25%',
            },
        },
        button: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        table: {
            minWidth: 650,
        },
        head: {
            backgroundColor: '#e8f1fd',
        },
        body: {
            fontSize: 11,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
    }),
);


interface Company {
    id: string;
    name: string;
}

interface ServersList {
    label: string;
    value: string;
}

interface ParamTypes {
    id: string;
}

function ManutencaoEmpresaBuscar() {

    const params = useParams<ParamTypes>();
    const classes = useStyles();

    const [isNew, setIsNew] = useState(false);
    const [id, setId] = useState('');

    const [company, setCompany] = useState<Company | null | undefined>({
        id: '',
        name: '',
    })

    const [companyList, setCompanyList] = useState<Company[]>([]);
    const [serversList, setServersList] = useState<ServersList[]>([])
    const [action, setAction] = useState(1);
    const [server, setServer] = useState("https://apl1.aokiinova.com.br")
    const [user, setUser] = useState('');
    const [url, setURL] = useState('');


    if (!id) {
        if (params.id) {
            setId(params.id);
        } else {
            setId(uuidv4());
            setIsNew(true);
        }
    }

    useEffect(() => {

        const getCompanyList = async () => {
            const response = await api.get('/companies?direction=ASC');
            if (response.status && response.status.toString()[0] === '2') {
                setCompanyList((await response.json()).data);
            }
        };

        getCompanyList();


    }, [id, isNew]);

    useEffect(() => {
        const getServers = async () => {
          const response = await api.get(`/servers`);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            const servers = data.data.filter( list => list.url.includes('https://apl'))

            const serverList = servers.map( server => ({
                label: server.hostname,
                value: server.url
            }))

            setServersList(serverList)
          }
        };
    
        getServers();
      }, []);

    const handleChangeAction = (event) => {
        setAction(event.target.value);
    };

    const handleChangeServer = (event) => {
        setServer(event.target.value);
    };

    const handleUsers = (value) => {
        if (value) {

            const getCompanyUsers = async () => {
                const response = await api.get(`/users?login=${value.cnpj !== '' ? value.cnpj.replace(/\D/g, "") : value.cpf.replace(/\D/g, "")}&company=${value.id}`);
                if (response.status && response.status.toString()[0] === '2') {
                    setUser('')
                    const { data } = await response.json()
                    if (data[0]) {
                        setUser(data[0].login);
                    } else {
                        setUser('')
                    }
                }
            };

            getCompanyUsers();

        }
    }

    useEffect(() => {
        const getURL = async () => {
            try {
                const response = await api.post("/maintenance/", {
                    user: user,
                    server: server,
                    action: action,
                });
                if (response && response.status === 200) {
                    setURL(await response.text());
                }
    
            } catch (err) {
                console.log(err);
                setURL('')
            }
        }
    
        getURL();
      }, [user, server, action]);

    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        window.open(url, "_blank")
    }

    return (
        <>
            <main>
                <div className="cadastrar">
                    <div className="grid-box grid-one">
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <h1>Manutenção</h1>
                                    <form onSubmit={handleSubmit}>
                                        <div className={classes.grid}>
                                            <Autocomplete
                                                value={company}
                                                getOptionLabel={(company) => company.name ? company.name : ""}
                                                options={companyList}
                                                onChange={(event, value) => {
                                                    setCompany(value);
                                                    handleUsers(value)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        variant="outlined"
                                                        label="Empresa"
                                                    />
                                                )}
                                            />  

                                            <FormControl
                                                variant="outlined"
                                                required
                                                className={classes.formControl}
                                            >
                                                <InputLabel variant="outlined">Servidor</InputLabel>
                                                <Select
                                                    value={server}
                                                    onChange={handleChangeServer}
                                                    label="Servidor"
                                                >
                                                    {serversList.map( server => {
                                                        return <MenuItem key={server.label} value={server.value}>{server.label}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                                
                                        </div>
                                        <div className={classes.grid}>
                                            <TextField
                                                label="Usuário"
                                                disabled
                                                variant="outlined"
                                                value={user}
                                                onChange={(e: any) => {
                                                    setUser(e.target.value);
                                                }}
                                            />
                                            <FormControl
                                                variant="outlined"
                                                required
                                                className={classes.formControl}
                                            >
                                                <InputLabel variant="outlined">Ação</InputLabel>
                                                <Select
                                                    value={action}
                                                    onChange={handleChangeAction}
                                                    label="Ação"
                                                >
                                                    <MenuItem value={1}>E2Corp</MenuItem>
                                                    <MenuItem value={2}>E2UpdTools</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Button
                                                disabled={!url}
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                type="submit"
                                            >
                                                Acessar
                                            </Button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default ManutencaoEmpresaBuscar;