import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';

import './styles.scss';
import Swal from 'sweetalert2';


import logo from '../../../../assets/images/logo.svg';
import oracle from '../../../../assets/images/oracle.png';


function EsqueciUser() {
  const [email, setEmail] = useState('');
  const history = useHistory();




  async function signIn(e: FormEvent) {
    e.preventDefault();

    try {
      const data = {
        email: email,
      };

      const response = await api.post('/auth/start-recovery', data);
      if (response.status === 200) {
        Swal.fire({
          title: 'Email enviado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: 'Voltar para o login',
          confirmButtonColor: '#418107',
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/');
          }
        });
      }
      else {
        Swal.fire({
          title: 'Email não encontrado',
          icon: 'error',
          showCancelButton: true,
          cancelButtonColor: '#418107',
          cancelButtonText: 'Tentar novamente',
          confirmButtonText: 'Voltar para o login',
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/');
          }
        });
      }
    }
    catch (error) {
      Swal.fire({
        title: 'Erro 😢',
        text: 'Ocorreu algum problema',
        icon: 'error',
        showCancelButton: true,
        cancelButtonColor: '#418107',
        cancelButtonText: 'Tentar novamente',
        confirmButtonText: 'Voltar para o login',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/');
        }
      });
    }
  }

  return (
    <>
      <div className="login">
        <div className="grid-container">
          <div className="item-left">
            <div>Mais do que números,<br></br>pessoas</div>
          </div>
          <div className="item-right">

            <div className="form">

              <img src={logo} className="logo" alt="Logo Aoki" />
              <form className="materializeForm" onSubmit={signIn}>
                <input
                  type="text"
                  className="txtbox"
                  placeholder="Email"
                  autoFocus
                  required
                  name="username"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />

                <button>ENVIAR</button>
                <button onClick={() => history.goBack()} style={{ margin: "0px" }}>VOLTAR</button>
              </form>
              <img src={oracle} className="oracle" alt="Logo Oracle" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EsqueciUser;
