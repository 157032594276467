import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BackIcon from '@material-ui/icons/ArrowBack';

import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';
import { UF } from '../../../../services/ibge';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '25%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface ParamTypes {
  id: string;
}

interface State {
  cnpj: string;
  cpf: string;
  phone: string;
  cellphone: string;
}

function ClienteVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [movideskId, setMovideskId] = useState('');

  const [contactEmail, setContactEmail] = useState('');
  const [financeEmail, setFinanceEmail] = useState('');
  const [boardEmail, setBoardEmail] = useState('');
  const [pontoFocal, setPontoFocal] = useState('');
  const [status, setStatus] = useState('');

  const [isNew, setIsNew] = useState(false);
  const [radioValue] = useState('cnpj');

  const [ufs] = useState<UF[]>([]);
  const [state, setState] = useState<UF | null>({ name: '', initials: '' });

  const [cities] = useState<string[]>([]);
  const [city, setCity] = useState<string | null>("");

  const [values, setValues] = React.useState<State>({
    cnpj: '',
    cpf: '',
    phone: '',
    cellphone: '',
  });

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/companies/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setValues(values => ({
              ...values,
              cnpj: data.cnpj,
              cpf: data.cpf,
              phone: data.telephone,
              cellphone: data.cellphone,
            }));
            setName(data.name);
            setCompanyName(data.companyName);
            setMovideskId(data.movideskId);
            setCity(data.city);
            setState({ name: data.state, initials: data.state });
            setContactEmail(data.contactEmail);
            setFinanceEmail(data.financeEmail);
            setBoardEmail(data.boardEmail);
            setPontoFocal(data.focalPoint);
            setStatus(data.status);
          };
        }
        getData();
      }
    }
  }, [id, isNew]);

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Cliente</h1>
                  <form >
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <FormControl className="radioButton">
                        <RadioGroup row aria-label="doc" name="doc" value={radioValue}>
                          <FormControlLabel value="cnpj" control={<Radio />} label="Pessoa Jurídica" disabled={(isNew) ? false : true} />
                          <FormControlLabel value="cpf" control={<Radio />} label="Pessoa Física" disabled={(isNew) ? false : true} />
                          <FormControlLabel value="foreign" control={<Radio />} label="Estrangeiro" disabled={(isNew) ? false : true} />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className={classes.grid}>
                      {radioValue === 'cnpj' && (
                        <TextField
                          disabled
                          label="CNPJ"
                          variant="outlined"
                          required
                          name="cnpj"
                          value={values.cnpj}
                        />
                      )}
                      {radioValue === 'cpf' && (
                        <TextField
                          disabled
                          label="CPF"
                          variant="outlined"
                          required
                          name="cpf"
                          value={values.cpf}
                        />
                      )}
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Status*</InputLabel>
                        <Select
                          disabled
                          id="select"
                          native
                          required
                          value={status}
                          label="Status*"
                        >
                          <option aria-label="None" value="" />
                          <option value={1}>Pendente</option>
                          <option value={2}>Ativo</option>
                          <option value={3}>Suspenso</option>
                          <option value={4}>Inativo</option>
                        </Select>
                      </FormControl>
                      <TextField
                        disabled
                        label="ID Movidesk"
                        variant="outlined"
                        name="movideskId"
                        value={movideskId}
                        onChange={(e) => { setMovideskId(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      {radioValue === 'cnpj' ? (
                        <>
                          <TextField
                            disabled
                            label="Nome Fantasia"
                            variant="outlined"
                            required
                            name="name"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                          />
                          <TextField
                            disabled
                            label="Razão Social"
                            variant="outlined"
                            required
                            name="companyName"
                            value={companyName}
                            onChange={(e) => { setCompanyName(e.target.value) }}
                          />
                        </>
                      ) : (
                        <TextField
                          disabled
                          label="Nome"
                          variant="outlined"
                          required
                          name="name"
                          value={name}
                          onChange={(e) => { setName(e.target.value) }}
                        />
                      )}
                    </div>
                    <div className={classes.grid}>
                      {(radioValue === 'cnpj' || radioValue === 'cpf') ? (
                        <>
                          <Autocomplete
                            disabled
                            id="controllable-states-demo"
                            value={state}
                            getOptionLabel={(uf) => uf.initials}
                            options={ufs}
                            onChange={(event, value) => { setState(value); setCity(null) }}
                            renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" />}
                          />
                          <Autocomplete
                            disabled
                            id="controllable-states-demo"
                            value={city}
                            getOptionLabel={(city) => city}
                            options={cities}
                            onChange={(event, value) => { setCity(value) }}
                            renderInput={(params) => <TextField {...params} label="Cidade" variant="outlined" />}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <TextField
                        disabled
                        label="Telefone"
                        variant="outlined"
                        name="phone"
                        value={values.phone}
                      />
                      <TextField
                        disabled
                        label="Celular"
                        variant="outlined"
                        name="cellphone"
                        value={values.cellphone}
                      />
                    </div>
                    <div className={classes.grid3}>
                      <TextField
                        disabled
                        label="Email de Contato"
                        variant="outlined"
                        name="state"
                        value={contactEmail}
                        onChange={(e) => { setContactEmail(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Email Financeiro"
                        variant="outlined"
                        name="financeEmail"
                        value={financeEmail}
                        onChange={(e) => { setFinanceEmail(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Email da Direção"
                        variant="outlined"
                        name="boardEmail"
                        value={boardEmail}
                        onChange={(e) => { setBoardEmail(e.target.value) }}
                      />
                      <TextField
                        label="pontoFocal"
                        variant="outlined"
                        name="pontoFocal"
                        value={pontoFocal}
                        onChange={(e) => { setPontoFocal(e.target.value) }}
                      />
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/clientes') }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ClienteVisualizar;