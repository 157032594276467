
import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BackIcon from '@material-ui/icons/ArrowBack';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },

    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface AlertaCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
}


interface Child {
  id: string;
  name: string;
}

function AlertaVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [value, setValue] = useState('');
  const [comparison, setComparison] = useState('');
  const [tiposAlerta, setTiposAlertas] = React.useState<Child | null | undefined>({ id: '', name: '' });
  const [warningType, setWarningType] = React.useState<Child | null | undefined>({ id: '', name: '' });
  const [alertLevel, setAlertLevel] = React.useState<Child | null | undefined>({ id: '', name: '' });

  const [isNew, setIsNew] = useState(false);

  const [listTiposAlertas, setListTiposAlertas] = React.useState<Child[]>([]);
  const [listWarningType, setListWarningType] = React.useState<Child[]>([]);
  const [listAlertLevel, setListAlertLevel] = React.useState<Child[]>([]);

  const handleChangeComparacao = (event: React.ChangeEvent<{ value: unknown }>) => {
    setComparison(event.target.value as string);
  }

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {

    const getTiposAlertas = async () => {
      const response = await api.get('/alert-types/');
      if (response.status && response.status.toString()[0] === '2') {
        setListTiposAlertas((await response.json()).data);
      }
    };
    getTiposAlertas();

    const getWarningType = async () => {
      const response = await api.get('/warnings/');
      if (response.status && response.status.toString()[0] === '2') {
        setListWarningType((await response.json()).data);
      }
    };
    getWarningType();

    const getAlertLevel = async () => {
      const response = await api.get('/alert-level/');
      if (response.status && response.status.toString()[0] === '2') {
        setListAlertLevel((await response.json()).data);
      }
    };
    getAlertLevel();

    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/alerts/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setValue(data.value);
            setComparison(data.comparison);
            setTiposAlertas(data.alertType);
            setWarningType(data.warningType);
            setAlertLevel(data.alertLevel);
          }
        };

        getData();
      }
    }
  }, [id, isNew]);


  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Alerta</h1>
                  <form >
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid3}>
                      <TextField
                        disabled
                        label="Valor"
                        variant="outlined"
                        required
                        name="value"
                        value={value}
                        onChange={(e) => { setValue(e.target.value) }}
                      />

                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Comparação</InputLabel>
                        <Select
                        disabled
                          id="select"
                          required
                          native
                          value={comparison}
                          onChange={handleChangeComparacao}
                          label="Comparação"
                        >
                          <option aria-label="None" value="" />
                          <option value={'maior'}>Maior</option>
                          <option value={'menor'}>Menor</option>
                          <option value={'maior ou igual'}>Maior ou igual</option>
                          <option value={'menor ou igual'}>Menor ou igual</option>
                        </Select>

                      </FormControl>

                      <Autocomplete
                      disabled
                        id="controllable-states-demo"
                        value={tiposAlerta}
                        getOptionLabel={(e) => e.name}
                        options={listTiposAlertas}
                        onChange={(event, value) => { setTiposAlertas(value) }}
                        renderInput={(params) => <TextField {...params} label="Tipo de Alertas" required variant="outlined" />}
                      />
                    </div>
                    <div className={classes.grid2}>
                      <Autocomplete
                      disabled
                        id="controllable-states-demo"
                        value={warningType}
                        getOptionLabel={(e) => e.name}
                        options={listWarningType}
                        onChange={(event, value) => { setWarningType(value) }}
                        renderInput={(params) => <TextField {...params} label="Avisos" required variant="outlined" />}
                      />
                      <Autocomplete
                      disabled
                        id="controllable-states-demo"
                        value={alertLevel}
                        getOptionLabel={(e) => e.name}
                        options={listAlertLevel}
                        onChange={(event, value) => { setAlertLevel(value) }}
                        renderInput={(params) => <TextField {...params} label="Nível de Alerta" required variant="outlined" />}
                      />
                    </div>


                    <div className={classes.button}>                   
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/alertas') }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AlertaVisualizar;
;
