import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import VisibilityIcon from '@material-ui/icons/Visibility';

import nodata from "../../../../assets/images/nodata.svg";
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

import { Action, Resource } from '../../../../utils/aclUtils';
import includesAll from '../../../../utils/includesAll';

import { TextField, Button, Select } from '@material-ui/core';

import { NavLink } from 'react-router-dom';

import { format } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';

import Swal from 'sweetalert2';


import api from '../../../../services/api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';

interface Data {
  status?: number;
  visit?: number;
  responsableAoki?: string;
  resposableClient?: string;
  issueDate?: Date;
  company?: { name: string };
  resposableAoki?: { name: string };
  options?: string;
  none?: null;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'visit', numeric: false, disablePadding: true, label: 'Nº Visita' },
  { id: 'issueDate', numeric: false, disablePadding: true, label: 'Data de emissão' },
  { id: 'company', numeric: false, disablePadding: true, label: 'Cliente' },
  { id: 'resposableAoki', numeric: false, disablePadding: true, label: 'Responsável Aoki' },
  { id: 'options', numeric: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

let aclItem;
if (localStorage.getItem('acl')) {
  const aclString = localStorage.getItem('acl');
  const acl: { resource: Resource, action: Action[] | '*' }[] = JSON.parse(aclString!);
  aclItem = acl.find(aclItem => aclItem.resource === 'Activity');
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface Company {
  id: string;
  name: string;
}

export function statusFunction(number: number) {
  switch (number) {
    case 1:
      return ('Aberta');
    case 2:
      return ('Pendente');
    case 3:
      return ('Aprovada');
    case 4:
      return ('Rejeitada');
    default:
      return ('');
  }
}

export default function Atividades() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('none');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);


  const [company, setCompany] = React.useState<Company | null | undefined>({ id: '', name: '' });
  const [companyList, setCompanyList] = React.useState<Company[]>([]);

  const [rows, setRows] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowId, setRowId] = React.useState('');
  const [rowStatus, setRowStatus] = React.useState(1);
  const [searchIssueDateStart, setSearchIssueDateStart] = React.useState('');
  const [searchStatus, setSearchStatus] = React.useState('');
  const [searchIssueDateEnd, setSearchIssueDateEnd] = React.useState('');
  const [display, setDisplay] = React.useState('none');
  const handleFilter = () => {
    if (display === 'grid') {
      setDisplay('none');
    } else {
      setDisplay('grid');
    }
  }

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchStatus(event.target.value as string);
  }

  const handleSearch = async () => {
    const queryParams = new URLSearchParams();
    if (searchIssueDateStart) {
      queryParams.append('issueDateStart', searchIssueDateStart);
    } if (searchIssueDateEnd) {
      queryParams.append('issueDateEnd', searchIssueDateEnd);
    }
    if (company) {
      queryParams.append('company', company.id);
    }
    if (searchStatus) {
      queryParams.append('status', searchStatus);
    }
    const getData = async () => {
      const response = await api.get(
        `${orderBy != 'none' 
        ? `/activities/?${queryParams.toString()}&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}` 
        : '/activities/'}`
      );
      if (response.status && response.status.toString()[0] === '2') {
        const data = await response.json();
        setRows(data.data);
        setTotalPages(data.total);
      }
    };

    getData();
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetRowId = (rowId) => {
    setRowId(rowId);
  };

  const handleSetRowStatus = (rowStatus) => {
    setRowStatus(rowStatus);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getCompanyList = async () => {
      const response = await api.get('/companies');
      if (response.status && response.status.toString()[0] === '2') {
        setCompanyList((await response.json()).data);
      }
    };
    getCompanyList();
  }, []);

  useEffect(() => {

    handleSearch()

  }, [orderBy, order, page, rowsPerPage]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;



  const handleDelete = async (id: string) => {
    handleCloseMenu();
    Swal.fire({
      title: 'Você tem certeza que deseja excluir o cadastro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim, Excluir!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const deleteConfirmed = async () => {
            await api.delete(`/activities/${id}`);
            const response = await api.get(`/activities?order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);

            if (response.status && response.status.toString()[0] === '2') {
              const data = await response.json();
              setRows(data.data);
              setTotalPages(data.total);
            }
          }

          deleteConfirmed();
        } catch (error) {
          console.log(`erroou: ${error}`);
        }
      }
    });

  };

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selecionado(s)
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Atividades
          </Typography>
        )}
        <Tooltip title="Filtro de dados">
          <IconButton onClick={handleFilter} aria-label="filter">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Listar inativos">
          <IconButton aria-label="Listar inativos">
            <NavLink to="atividades-inativas"><DeleteSweepIcon /></NavLink>
          </IconButton>
        </Tooltip>
        {(aclItem && (aclItem.action === '*' || aclItem.action.includes('CREATE'))) && <Tooltip title="Cadastrar">
          <IconButton aria-label="Cadastrar">
            <NavLink to="cadastro-atividade"><AddIcon /></NavLink>
          </IconButton>
        </Tooltip>}
      </Toolbar>
    );
  };

  return (
    <main>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <div id="filtros" style={{ display: display, transition: "width 2s, height 4s", transitionDuration: "2s" }}>
              <div className="filterField">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="select"
                    native
                    required
                    value={searchStatus}
                    onChange={handleChangeStatus}
                    label="Status"
                  >
                    <option value={'1'}>Aberta</option>
                    <option value={'2'}>Pendente</option>
                    <option value={'3'}>Aprovada</option>
                    <option value={'4'}>Rejeitada</option>
                  </Select>
                </FormControl>

                <Autocomplete
                  aria-required
                  id="controllable-states-demo"
                  className="autocompleteSearch"
                  value={company}
                  getOptionLabel={(company) => company.name}
                  options={companyList}
                  onChange={(event, value) => { setCompany(value) }}
                  renderInput={(params) =>
                    <TextField {...params} label="Cliente" />
                  }
                />

                <TextField
                  variant="standard"
                  label="Data Inicial"
                  type="date"
                  value={searchIssueDateStart}
                  onChange={(e) => { setSearchIssueDateStart(e.target.value) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  variant="standard"
                  label="Data Final"
                  type="date"
                  value={searchIssueDateEnd}
                  onChange={(e) => { setSearchIssueDateEnd(e.target.value) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  variant="contained"
                  className="searchButton"
                  color="primary"
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}
                >Pesquisar</Button>
              </div>
            </div>
            {totalPages > 0 ? (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">

                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {statusFunction(row.status)}
                        </TableCell>
                        <TableCell align="left">
                          {row.visit}
                        </TableCell>
                        {row.issueDate ? (
                          <TableCell align="left">
                            {format(new Date(row.issueDate), 'dd \'de\' MMMM \'de\' yyyy, HH:mm', { locale: dateFnsPtBR })}
                          </TableCell>
                        ) :
                          (
                            <TableCell align="left">
                              Sem data de emissão
                            </TableCell>
                          )}
                        <TableCell align="left">
                          {row.company.name}
                        </TableCell>
                        <TableCell align="left">
                          {row.responsableAoki.name}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => { handleClick(e); handleSetRowId(row.id); handleSetRowStatus(row.status) }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >
                            {(aclItem && (aclItem.action === '*' || includesAll(['READ', 'UPDATE'], aclItem.action))) &&
                              <NavLink to={"editar-atividade/" + rowId}>
                                <StyledMenuItem>
                                  {(rowStatus > 2) ? (
                                    <>
                                      <ListItemIcon>
                                        <VisibilityIcon />
                                      </ListItemIcon>
                                      <div className="actionText">Visualizar</div>
                                    </>
                                  ) : (
                                    <>
                                      <ListItemIcon>
                                        <EditIcon />
                                      </ListItemIcon>
                                      <div className="actionText">Editar</div>
                                    </>
                                  )}

                                </StyledMenuItem>
                              </NavLink>}
                            {(aclItem && (aclItem.action === '*' || aclItem.action.includes('DELETE'))) &&

                              <StyledMenuItem onClick={() => handleDelete(rowId)}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <div className="actionText">Excluir</div>
                              </StyledMenuItem>}

                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            ) : (
              <>
                <div className="no-data">
                  <img src={nodata} className="no-data-svg" alt="Sem dados" />
                  <div>Nenhum registro disponível para exibição</div>
                </div>
              </>)}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </main >
  );
}
