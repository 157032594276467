import React, { useState, useEffect, FormEvent } from 'react';

import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';
import api from '../../../services/api';

import './styles.scss';
import Swal from 'sweetalert2';


import gear from '../../../assets/images/gear.svg';
import logo from '../../../assets/images/logo.svg';
import oracle from '../../../assets/images/oracle.png';
import { NavLink } from 'react-router-dom';


function LoginAdmin() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = React.useState(<ShowEye />);


  localStorage.removeItem('admin');
  localStorage.removeItem('user');
  localStorage.removeItem('login');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('acl');
  
  

  function handlePassword() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setPasswordIcon(<ShowEye />);
    } else {
      setPasswordIcon(<HideEye />);
    }
  }

  useEffect(() => {

    const loginAdmin = localStorage.getItem('loginAdmin');
    if (loginAdmin) {
      setLogin(loginAdmin);
    }
  }, []);

  async function signIn(e: FormEvent) {
    e.preventDefault();

    try {
      const data = {
        login: login,
        password: password
      };
      const response = await api.post('/auth/admin', data);

      if (response.status && response.status.toString()[0] === '2') {
        localStorage.setItem('accessToken', response.headers.get('X-Access-Token')!);
        localStorage.setItem('refreshToken', response.headers.get('X-Refresh-Token')!);
        
        const acl = await response.json();
        localStorage.setItem('acl', JSON.stringify(acl.acl));

        localStorage.setItem('admin', 'true');
        localStorage.setItem('login', login);

        window.location.reload(false);
      }
      else{
        Swal.fire({
          title: 'Erro 😢',
          text: 'Login e/ou senha inválidos',
          icon: 'error',
        });
      }
    }
    catch (error) {
      Swal.fire({
        title: 'Erro 😢',
        text: 'Ocorreu um problema ao tentar fazer login.',
        icon: 'error',
      });
      localStorage.removeItem('admin');
      localStorage.removeItem('login');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('acl');
    }


  }

  return (
    <>

      <div className="loginAdmin">
        <div className="grid-container">
          <div className="item-left">
            <img src={gear} className="gear" alt="Engrenagem" />
          </div>
          <div className="item-right">

            <div className="form">

              <img src={logo} className="logo" alt="Logo Aoki" />
              <br></br><p>A D M I N</p>
              <form className="materializeForm" onSubmit={signIn}>
                <input
                  type="text"
                  className="txtbox"
                  placeholder="Login"
                  autoFocus
                  required
                  name="login"
                  value={login}
                  onChange={(e) => { setLogin(e.target.value) }}
                />
                <div className="container-pass">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="txtbox"
                    placeholder="Senha"
                    required
                    name="password"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                  />
                  <span onClick={() => { handlePassword() }}>{passwordIcon}</span>
                </div>

                <button>LOGIN</button>
                <div className="esqueci"><NavLink to="/esqueci">Esqueci minha senha</NavLink></div>
                <img src={oracle} className="oracle" alt="Logo Oracle" />
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginAdmin;
