import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/CreateNewFolder';

import { useParams } from 'react-router-dom';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import { format } from 'date-fns';

import nodata from "../../../../../assets/images/nodata.svg";
import BackIcon from '@material-ui/icons/ArrowBack';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';


import { Action, Resource } from '../../../../../utils/aclUtils';
import includesAll from '../../../../../utils/includesAll';

import { NavLink } from 'react-router-dom';

import Swal from 'sweetalert2';

import './styles.scss';

import api from '../../../../../services/api';


interface Data {
  product: string;
  version: string;
  installDate: Date;
  status: string;
  options: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'product', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'version', numeric: false, disablePadding: true, label: 'Versão' },
  { id: 'installDate', numeric: false, disablePadding: true, label: 'Data de Instalação' },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'options', numeric: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

let aclItem;
if (localStorage.getItem('acl')) {
  const aclString = localStorage.getItem('acl');
  const acl: { resource: Resource, action: Action[] | '*' }[] = JSON.parse(aclString!);
  aclItem = acl.find(aclItem => aclItem.resource === 'Product');
}

interface ParamTypes {
  companyId: string;
}

interface Cliente {
  id: string;
  name: string;
  cnpj: string;
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Produtos() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('installDate');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);


  const [company, setCompany] = React.useState<Cliente | null | undefined>({ id: '', name: '', cnpj: '' });
  const [companiesList, setCompaniesList] = React.useState<Cliente[]>([]);

  const params = useParams<ParamTypes>();
  const [companyId, setCompanyId] = React.useState('');

  const [rows, setRows] = React.useState<{ company: { name: string } }[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowId, setRowId] = React.useState('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetRowId = (rowId) => {
    setRowId(rowId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  if (!companyId) {
    if (params.companyId) {
      setCompanyId(params.companyId);
    }
  }


  useEffect(() => {
    const getData = async () => {
      const response = await api.get(`/company-versions?company=${companyId}&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);
      if (response.status && response.status.toString()[0] === '2') {
        const data = await response.json();
        setRows(data.data);
        setTotalPages(data.total);
      }
    };

    const getCompanies = async () => {
      const response = await api.get('/companies?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setCompaniesList((await response.json()).data);
      }
    };
    getCompanies();

    getData();
  }, [order, orderBy, rowsPerPage, page, companyId]);

  useEffect(() => {
    if (companyId) {
      companiesList.forEach(element => {
        if (element.id === companyId)
          setCompany(element);
        return;
      });
    }
  }, [companiesList, companyId]);

  function statusFuncion(number: number) {
    switch (number) {
      case 1:
        return ('Pendente');
      case 2:
        return ('Ativo');
      case 3:
        return ('Suspenso');
      case 4:
        return ('Inativo');
      default:
        return ('');
    }
  }


  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;



  const handleDelete = async (id: string) => {
    handleCloseMenu();
    Swal.fire({
      title: 'Você tem certeza que deseja excluir o cadastro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim, Excluir!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const deleteConfirmed = async () => {
            await api.delete(`/company-versions/${id}`);
            const response = await api.get(`/company-versions?company=${companyId}&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);
            if (response.status && response.status.toString()[0] === '2') {
              const data = await response.json();
              setRows(data.data);
              setTotalPages(data.total);
            }
          }

          deleteConfirmed();
        } catch (error) {
          console.log(`erroou: ${error}`);
        }
      }
    });

  };
  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >

        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Produtos {companyId && `da empresa ${company?.name}`}
        </Typography>

        <Tooltip title="Voltar para Clientes">
          <IconButton aria-label="Voltar para Clientes">
            <NavLink to="/clientes"><BackIcon /></NavLink>
          </IconButton>
        </Tooltip>
        {(aclItem && (aclItem.action === '*' || aclItem.action.includes('CREATE'))) && <Tooltip title="Cadastrar">
          <IconButton aria-label="Cadastrar">
            <NavLink to={"/cadastro-produtos-por-empresa/" + companyId}><AddIcon /></NavLink>
          </IconButton>
        </Tooltip>}
      </Toolbar>
    );
  };

  return (
    <main>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            {totalPages > 0 ? (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          
                        </TableCell>
                        <TableCell align="left">
                          {row.version.product.name}
                        </TableCell>
                        <TableCell align="left">
                          {row.version.name}
                        </TableCell>
                        {row.installDate ? (
                          <TableCell align="left">
                            {format(new Date(row.installDate), 'dd \'de\' MMMM \'de\' yyyy, HH:mm', { locale: dateFnsPtBR })}
                          </TableCell>
                        ) :
                          (
                            <TableCell align="left">

                            </TableCell>
                          )}
                        <TableCell align="left">
                          {statusFuncion(row.status)}
                        </TableCell>
                        <TableCell align="right">
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => { handleClick(e); handleSetRowId(row.id) }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >
                            {(aclItem && (aclItem.action === '*' || includesAll(['READ', 'UPDATE'], aclItem.action))) &&
                              <NavLink to={"/editar-produtos-por-empresa/" + rowId}>
                                <StyledMenuItem>
                                  <ListItemIcon>
                                    <EditIcon />
                                  </ListItemIcon>
                                  <div className="actionText">Editar</div>
                                </StyledMenuItem>
                              </NavLink>}
                            {(aclItem && (aclItem.action === '*' || aclItem.action.includes('DELETE'))) &&

                              <StyledMenuItem onClick={() => handleDelete(rowId)}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <div className="actionText">Excluir</div>
                              </StyledMenuItem>}
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            ) : (
                <>
                  <div className="no-data">
                    <img src={nodata} className="no-data-svg" alt="Sem dados" />
                    <div>Nenhum registro disponível para exibição</div>
                  </div>
                </>)}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </main>
  );
}
