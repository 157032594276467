import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),

        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    pass: {
      display: 'abolsute',

      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface ParamTypes {
  id: string;
}

interface Product {
  id: string;
  name: string,
}

function VersoesVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [isNew, setIsNew] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [defaultDir, setDefaultDir] = useState('');
  const [product, setProduct] = React.useState<Product | null | undefined>({ id: '', name: '' });
  const [productsDB, setProductsDB] = React.useState<Product[]>([]);

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    const getCompanies = async () => {
      const response = await api.get('/products?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setProductsDB((await response.json()).data);
      }
    };
    getCompanies();

    const getProducts = async () => {
      const response = await api.get('/products');
      if (response.status && response.status.toString()[0] === '2') {
        setProductsDB((await response.json()).data);
      }
    };
    getProducts();

    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/versions/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setName(data.name);
            setCode(data.code);
            setProduct(data.product);
            setDefaultDir(data.defaultDir);
          }
        };

        getData();
      }
    }
  }, [id, isNew]);


  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Versão</h1>
                  <form >
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <Autocomplete
                      disabled
                        aria-required
                        id="controllable-states-demo"
                        value={product}
                        getOptionLabel={(p) => p.name}
                        options={productsDB}
                        onChange={(event, value) => { setProduct(value) }}
                        renderInput={(params) => <TextField {...params} label="Produto" required variant="outlined" />}
                      />
                      <TextField
                      disabled
                        label="Nome"
                        variant="outlined"
                        required
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                      disabled
                        label="Código"
                        variant="outlined"
                        required
                        value={code}
                        onChange={(e) => { setCode(e.target.value) }}
                      />
                      <TextField
                      disabled
                        label="Diretório Padrão"
                        variant="outlined"
                        required
                        value={defaultDir}
                        onChange={(e) => { setDefaultDir(e.target.value) }}
                      />
                    </div>
                    <div className={classes.button}>                   
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.goBack() }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default VersoesVisualizar;