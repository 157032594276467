import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BackIcon from '@material-ui/icons/ArrowBack';
import ShowEye from '@material-ui/icons/Visibility';
import MaskedInput from 'react-text-mask';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),

        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    pass: {
      display: 'abolsute',

      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface ParamTypes {
  id: string;
}

interface Cliente {
  id: string;
  name: string;
  cnpj: string;
}
interface Profile {
  id: string;
  name: string,
  code: string,
}

interface CellMaskProps {
  inputRef: () => void;
}

function CellMask(props: CellMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />

  );
}


interface State {
  cellphone: string;
}

function UsuarioVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [email, setEmail] = useState('');
  const [movideskId, setMovideskId] = useState('');
  const [status, setStatus] = useState('');
  const [userTSPlus, setUserTSPlus] = useState('');
  const [passwordTSPlus, setPasswordTSPlus] = useState('');
  const [admin, setAdmin] = useState(false);
  const [values, setValues] = React.useState<State>({
    cellphone: '',
  });

  const [showPassword1, setShowPassword1] = React.useState(false);
  const [passwordIcon1, setPasswordIcon1] = React.useState(<ShowEye />);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [company, setCompany] = React.useState<Cliente | null | undefined>({ id: '', name: '', cnpj: '' });
  const [companiesDB, setCompaniesDB] = React.useState<Cliente[]>([]);
  const [profile, setProfile] = React.useState<Profile | null | undefined>({ id: '', name: '', code: '' });
  const [profiles, setProfiles] = React.useState<Profile[]>([]);

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }


  useEffect(() => {
    const getCompanies = async () => {
      const response = await api.get('/companies?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setCompaniesDB((await response.json()).data);
      }
    };
    getCompanies();

    const getProfiles = async () => {
      const response = await api.get('/profiles');
      if (response.status && response.status.toString()[0] === '2') {
        setProfiles((await response.json()).data);
      }
    };
    getProfiles();

    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/users/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setValues(values => ({
              ...values,
              cellphone: data.cellphone,
            }));
            setName(data.name);
            setLogin(data.login);
            setPassword(data.password);
            setMovideskId(data.movideskId);
            setEmail(data.email);
            setStatus(data.status);
            setUserTSPlus(data.userTSPlus);
            setAdmin(data.admin);
            setCompany(data.company);
            setProfile(data.profile);
            setPasswordTSPlus(data.passwordTSPlus);
          }
        };

        getData();
      }
    }
  }, [id, isNew]);

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Usuário</h1>
                  <form >
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Status</InputLabel>
                        <Select
                          disabled
                          required
                          id="select"
                          native
                          value={status}                       
                          label="Status"
                        >
                          <option aria-label="None" value="" />
                          <option value={1}>Pendente</option>
                          <option value={2}>Ativo</option>
                          <option value={3}>Suspenso</option>
                          <option value={4}>Inativo</option>
                        </Select>

                      </FormControl>
                      <Autocomplete
                        disabled
                        aria-required
                        id="controllable-states-demo"
                        value={profile}
                        getOptionLabel={(profile) => profile.name}
                        options={profiles}
                        onChange={(event, value) => { setProfile(value) }}
                        renderInput={(params) => <TextField {...params} label="Perfil" required variant="outlined" />}
                      />
                      <TextField
                        disabled
                        label="ID Movidesk"
                        variant="outlined"
                        name="movideskId"
                        value={movideskId}
                        onChange={(e) => { setMovideskId(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <Autocomplete
                        disabled
                        aria-required
                        id="controllable-states-demo"
                        value={company}
                        getOptionLabel={(company) => company.name}
                        options={companiesDB}
                        onChange={(event, value) => { setCompany(value) }}
                        renderInput={(params) => <TextField {...params} label="Cliente" required variant="outlined" />}
                      />
                      <TextField
                        disabled
                        label="Nome"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        disabled
                        label="Login"
                        variant="outlined"
                        required
                        name="login"
                        value={login}
                        onChange={(e) => { setLogin(e.target.value) }}
                      />
                      {isNew ? (
                        <>
                          <div className="userPass">
                            <div className={classes.pass}>
                              <TextField
                                disabled
                                type={showPassword1 ? 'text' : 'password'}

                                label="Senha"
                                variant="outlined"
                                required
                                name="password"
                                onChange={(e) => { setPassword(e.target.value) }}
                              />
                              <div className="container-pass">
                   
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (<></>)}
                      <TextField
                        disabled
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Celular"
                        variant="outlined"
                        name="cellphone"
                        InputProps={{
                          inputComponent: CellMask as any,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={values.cellphone}
                      
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        disabled
                        label="Login TSPlus"
                        variant="outlined"
                        required
                        name="userTSPlus"
                        value={userTSPlus}
                        onChange={(e) => { setUserTSPlus(e.target.value) }}
                      />
                      <div className={classes.pass}>
                        <TextField
                          type={showPassword2 ? 'text' : 'password'}
                          disabled
                          label="Senha TSPlus"
                          variant="outlined"
                          required
                          name="passwordTSPlus"
                          value={passwordTSPlus}
                          onChange={(e) => { setPasswordTSPlus(e.target.value) }}
                        />
                        <div className="container-pass">    
                        </div>
                      </div>
                    </div>            

                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.goBack() }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default UsuarioVisualizar;
