import React, { useState, useEffect, FormEvent } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MaskedInput from 'react-text-mask';
import { Modal } from '@material-ui/core';

import Swal from 'sweetalert2';
import jwt from 'jsonwebtoken';

import api from '../../../services/api';


import './styles.scss';
import RedefinePassword from '../../../components/RedefinePassword';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '25%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface UsuarioCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
}

interface Company {
  id: string;
  name: string;
  cnpj: string;
}
interface CellMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CellMask(props: CellMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />

  );
}



interface State {
  cellphone: string;
}

function UsuarioCadastrar() {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [values, setValues] = React.useState<State>({
    cellphone: '',
  });

  const [company, setCompany] = React.useState<Company | null | undefined>({ id: '', name: '', cnpj: '' });

  const [showEmailModal, setShowEmailModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  const classes = useStyles();

  if (!id) {
    setId((jwt.decode(localStorage.getItem('accessToken')!, { json: true })!).sub);
  }

  useEffect(() => {
    if (id) {
      const getData = async () => {
        const response = await api.get('/users/' + id);
        if (response.status && response.status.toString()[0] === '2') {
          const data = await response.json();

          setValues(values => ({
            ...values,
            cellphone: data.cellphone,
          }));
          setName(data.name);
          setLogin(data.login);
          setEmail(data.email);
          setCompany(data.company);

          const emailModal = localStorage.getItem("hideEmailModal") == undefined ? true : false

          if (!data.email && emailModal) {
            setShowEmailModal(true)
          }
        }
      };

      getData();
    }

  }, [id]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }

      if (values.cellphone.length > 0) {
        if (values.cellphone.replace(/ /g, '').length < 16) {
          Swal.fire({
            title: 'Celular inválido',
            text: 'Preencha todos caracteres',
            icon: 'error',
          });
          return;
        }
      }

      const result = await api.patch("/users/" + id, {
        login: login,
        name: name,
        email: email,
        cellphone: values.cellphone,
        company: { id: company?.id },
        enabled: true,
      });

      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          }
        });
      }
      else {
        if (result.status === 409) {
          const errorText = await result.text();
          if (errorText === 'E_USER_LOGIN') {
            Swal.fire({
              title: 'Login já existente',
              icon: 'error',
            });
            return;
          }
          if (errorText === 'E_USER_LOGIN_TSPLUS') {
            Swal.fire({
              title: 'Login TSPlus já existente',
              icon: 'error',
            });
            return;
          }
          if (errorText === 'E_USER_EMAIL') {
            Swal.fire({
              title: 'Email já existente',
              icon: 'error',
            });
            return;
          }
        }
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Minha Conta</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid2}>
                      <TextField

                        label="Empresa"
                        variant="outlined"
                        required
                        disabled
                        name="company"
                        value={company?.name}
                      />
                      <TextField

                        label="Nome"
                        variant="outlined"
                        required
                        disabled
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField

                        label="Login"
                        variant="outlined"
                        required
                        name="login"
                        value={login}
                        onChange={(e) => { setLogin(e.target.value) }}
                      />
                      <TextField

                        label="Email"
                        variant="outlined"
                        name="email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                      <TextField

                        label="Celular"
                        variant="outlined"
                        name="cellphone"
                        InputProps={{
                          inputComponent: CellMask as any,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={values.cellphone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<VpnKeyIcon />}
                        onClick={() => setOpenModal(true)}
                      >
                        Alterar Senha
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <RedefinePassword
            setOpenModal={setOpenModal}
            id={id}
          />
        </Modal>

        <Modal
          open={showEmailModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modalContainer">
            <div className="modalSquare">
              <h1>Atenção!</h1>
              <p>
                Para ter acesso a todas as funções do sistema é necessário cadastrar um email em sua conta
              </p>

              <div className="buttonContainer">
                <button onClick={() => setShowEmailModal(false)}>
                  Fechar
                </button>
                <button onClick={() => { setShowEmailModal(false); localStorage.setItem('hideEmailModal', 'true') }}>
                  Não mostrar novamente
                </button>
              </div>

            </div>
          </div>
        </Modal>
      </main>
    </>
  )
}

export default UsuarioCadastrar;
