import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import { CSVReader } from 'react-papaparse';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { DataGrid, ColDef } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import Swal from 'sweetalert2';

import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';


import './styles.scss';
import arrayEquals from '../../../../utils/arrayEquals';
import arrayDifference from '../../../../utils/arrayDifference';


const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    grid2: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
      },
    },

  }),
);



const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();


  return (
    <Toolbar
      className={classes.root}
    >

      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Upload de Usuário
          </Typography>


      <Tooltip title="Voltar">
        <IconButton aria-label="Voltar">
          <NavLink to="usuarios"><ArrowBackIcon /></NavLink>
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    grid: {
      display: 'block',
      padding: '2rem',
      alignItems: 'center',
    },
    button: {
      width: '100%',
      marginTop: '1rem',
      textAlign: 'center',
      '& > *': {
        width: '40%',
        margin: theme.spacing(1),
      },
    },
  }),
);

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


const validHeader = ['Cliente', 'Nome', 'Login', 'Senha', 'Login E2Corp', 'Senha E2Corp', 'Modo E2Corp', 'Status', 'Perfil'];
const headerIndexes = {
  COMPANY: 0,
  NAME: 1,
  LOGIN: 2,
  PASSWORD: 3,
  LOGIN_E2CORP: 4,
  PASSWORD_E2CORP: 5,
  MODE_E2CORP: 6,
  STATUS: 7,
  PROFILE: 8,
};

function UploadUser() {
  interface Errors {
    id: number;
    line: number;
    login: string;
    company: string;
    errorStatus: number;
    errorMessage: string;
  }

  const classes = useStyles();
  const history = useHistory();

  const [csvReaderRef, setCsvReaderRef] = useState<CSVReader | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [dataCSV, setDataCSV] = useState<{ data: {}[] }[]>([]);
  const [importDataLength, setImportDataLength] = useState(0);

  const handleOnDrop = (data, file) => {
    setButtonEnabled(false);
    setImportDataLength(0);
    setProgress(0);
    console.log(data);
    const type = file.name.split('.')[file.name.split('.').length - 1];
    if (type !== 'csv') {
      Swal.fire({
        title: 'Arquivo não suportado',
        text: `A extensão .${type} não é suportada, utilize um arquivo .csv`,
        icon: 'warning',
      });
      csvReaderRef?.removeFile();
    }
    else {
      if (data.length > 1501) {
        Swal.fire({
          title: 'Arquivo excede o limite de linhas',
          text: `Por favor, selecione um arquivo com até 1500 linhas`,
          icon: 'warning',
        });
        csvReaderRef?.removeFile();
      }
      else {
        var format = /[ `!@#$%^&*()_+\-=[\]{};':"|,<>?~áéíóúàèìòùâêîôûãõ]/;
        if (file.name.match(format)) {
          console.log(file.name);
          Swal.fire({
            title: 'Nome do arquivo inválido',
            text: `Por favor, selecione um arquivo cujo nome não tenha símbolos, acentous ou espaço`,
            icon: 'warning',
          });
          csvReaderRef?.removeFile();
        }
        else {
          setErrors([]);
          setButtonEnabled(true);
          setDataCSV(data);
        }
      }
    }

  }

  const handlePut = async () => {
    const result = await Swal.fire({
      title: 'Você tem certeza que deseja incluir o arquivo selecionado?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    });
    if (result.isConfirmed) {
        if (dataCSV && dataCSV.length > 1) {
          const header = dataCSV[0].data;
          const defaultE2CorpMode = process.env.REACT_APP_DEFAULT_E2CORP_MODE;
          const defaultStatus = Number(process.env.REACT_APP_DEFAULT_STATUS);
          const defaultProfile = process.env.REACT_APP_DEFAULT_PROFILE;
          if (header.length >= 6) {
            if (arrayEquals(validHeader.slice(0, 6), header) || (arrayDifference(validHeader, header).length <= 0 && arrayEquals(validHeader.slice(0, header.length), header))) {
              setButtonEnabled(false);
              const importData = dataCSV.slice(1);
              setImportDataLength(importData.length);
              let errorsCount = 0;
              let dataCount = 0;
              for(let cont = 0; cont < importData.length; cont++) {
                if (importData[cont]) {
                  if (importData[cont].data.length > 1) {
                    const docInfo = (importData[cont].data[headerIndexes.COMPANY] as string).split('_');
                    const insertDoc = {};
                    if (['cpf', 'cnpj'].includes(docInfo[0])) {
                      insertDoc[docInfo[0]] = docInfo[1];
                    } else {
                      insertDoc['id'] = importData[cont].data[headerIndexes.COMPANY];
                    }
                    try {
                      const userInsertResult = await api.put("/users", {
                        id: uuidv4(),
                        login: importData[cont].data[headerIndexes.LOGIN],
                        password: importData[cont].data[headerIndexes.PASSWORD],
                        name: importData[cont].data[headerIndexes.NAME],
                        status: Number(importData[cont].data[headerIndexes.STATUS] || defaultStatus),
                        modeLoginTSPlus: importData[cont].data[headerIndexes.MODE_E2CORP] || defaultE2CorpMode,
                        userTSPlus: importData[cont].data[headerIndexes.LOGIN_E2CORP],
                        passwordTSPlus: importData[cont].data[headerIndexes.PASSWORD_E2CORP],
                        company: insertDoc,
                        profile: { id: importData[cont].data[headerIndexes.PROFILE] || defaultProfile },
                        enabled: true,
                        admin: false,
                      });
                      dataCount += 1;
                      if (userInsertResult) {
                        if (userInsertResult.status && userInsertResult.status.toString()[0] === '2') {
                          console.log('success');
                        }
                        else {
                          if (userInsertResult.status) {
                            const errorText = await userInsertResult.text();

                            if (userInsertResult.status === 409) {
                              switch (errorText) {
                                case 'E_USER_LOGIN':
                                  setErrors(storedErrors => storedErrors.concat({
                                    id: cont+2,
                                    line: cont+2,
                                    login: importData[cont].data[2] as string,
                                    company: importData[cont].data[0] as string,
                                    errorStatus: userInsertResult.status,
                                    errorMessage: 'Login já existe',
                                  }));
                                  break;
                                case 'E_USER_LOGIN_TSPLUS':
                                  setErrors(storedErrors => storedErrors.concat({
                                    id: cont+2,
                                    line: cont+2,
                                    login: importData[cont].data[2] as string,
                                    company: importData[cont].data[0] as string,
                                    errorStatus: userInsertResult.status,
                                    errorMessage: 'LoginTSPlus já existe',
                                  }));
                                  break;
                                case 'E_USER_EMAIL':
                                  setErrors(storedErrors => storedErrors.concat({
                                    id: cont+2,
                                    line: cont+2,
                                    login: importData[cont].data[2] as string,
                                    company: importData[cont].data[0] as string,
                                    errorStatus: userInsertResult.status,
                                    errorMessage: 'Email já existe',
                                  }));
                                  break;
                                case 'E_USER_COMPANY_NOT_EXISTS':
                                  setErrors(storedErrors => storedErrors.concat({
                                    id: cont+2,
                                    line: cont+2,
                                    login: importData[cont].data[2] as string,
                                    company: importData[cont].data[0] as string,
                                    errorStatus: userInsertResult.status,
                                    errorMessage: 'Empresa não existe',
                                  }));
                                  break;
                                case 'E_USER_PROFILE_NOT_EXISTS':
                                  setErrors(storedErrors => storedErrors.concat({
                                    id: cont+2,
                                    line: cont+2,
                                    login: importData[cont].data[2] as string,
                                    company: importData[cont].data[0] as string,
                                    errorStatus: userInsertResult.status,
                                    errorMessage: 'Perfil não existe',
                                  }));
                                  break;
                                default:
                                  setErrors(storedErrors => storedErrors.concat({
                                    id: cont+2,
                                    line: cont+2,
                                    login: importData[cont].data[2] as string,
                                    company: importData[cont].data[0] as string,
                                    errorStatus: userInsertResult.status,
                                    errorMessage: errorText,
                                  }));
                              }
                            }
                            else
                              setErrors(storedErrors => storedErrors.concat({
                                id: cont+2,
                                line: cont+2,
                                login: importData[cont].data[2] as string,
                                company: importData[cont].data[0] as string,
                                errorStatus: userInsertResult.status,
                                errorMessage: errorText,
                              }));
                          }
                          else {
                            setErrors(storedErrors => storedErrors.concat({
                              id: cont+2,
                              line: cont+2,
                              login: importData[cont].data[2] as string,
                              company: importData[cont].data[0] as string,
                              errorStatus: 400,
                              errorMessage: 'Requisição inválida',
                            }));
                          }
                          errorsCount += 1;
                        }
                      }
                    } catch (error) {
                      console.log(`erroou: ${error}`);
                    }
            
                  }
                  setProgress(storedProgress => storedProgress + 1);
                }
              }
              if (errorsCount > 0) {

                if (errorsCount === dataCount) {
                  Swal.fire({
                    title: 'Nenhum dado importado',
                    text: 'Os dados selecionados não são válidos, verifique a tabela de erros',
                    icon: 'error',
                  });
                }
                else {
                  Swal.fire({
                    title: 'Dados processados',
                    text: 'Porém nem todos obtiveram êxito, confirme a tabela de erros para ver o que não deu certo',
                    icon: 'warning',
                  });

                }
              }
              else {
                Swal.fire({
                  title: 'Sucesso',
                  text: 'Todos dados foram salvos com êxito!',
                  icon: 'success',
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonText: 'Continuar',
                  cancelButtonColor: '#418107',
                  cancelButtonText: 'Ver Todos'
                }).then((result) => {
                  if (!result.isConfirmed) {
                    history.push('/usuarios');
                  }
                });
              }
            } else {
              Swal.fire({
                title: 'Erro',
                text: 'Erro no formato da tabela, tente novamente com uma tabela válida',
                icon: 'error',
              });
              csvReaderRef?.removeFile();
            }
          }
        }
        else {
          Swal.fire({
            title: 'O arquivo selecionado está com problemas...',
            text: 'Por favor, selecione um arquivo ou tente selecioná-lo novamente',
            icon: 'warning',
          });
        }

    }

  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
    setButtonEnabled(false);
    setProgress(0);
    setImportDataLength(0);
    setErrors([]);
  }

  return (
    <>
      <main>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar />
          {importDataLength === 0 &&
            <div className={classes.grid}>
              <div className="uploadRules">
                <span>
                  <h2>Requisitos para a importação</h2>
                  <ul>
                    <li> O arquivo deve estar com a extensão CSV, e os dados precisam estar separados por vírgulas ou ponto-e-vírgula.</li>
                    <li> O arquivo deve ter 1500 linhas ou menos para importação. Arquivos com um número maior de linhas serão rejeitados e deverão ser processados separadamente.</li>
                    <li> O nome do arquivo não pode ter acentos, traços ou espaços.</li>
                    <li> O arquivo deve ter a primeira linha como um cabeçalho. Este cabeçalho deve conter as colunas nomeadas da maneira: Cliente, Nome, Login, Senha, Login E2Corp, Senha E2Corp.</li>
                    <li> O cabeçalho do arquivo também pode ter as seguintes colunas: Modo E2Corp, Status, Perfil. Caso as colunas ou seus valores não sejam informados um valor padrão será utilizado.</li>
                  </ul>
                </span>
              </div>
            </div>

          }

          <div className={classes.grid}>
            <div className="csv">
              <CSVReader
                ref={setCsvReaderRef}
                onDrop={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                onRemoveFile={handleOnRemoveFile}
              >
                <span>Arraste o CSV ou clique para carregar.</span>
              </CSVReader>
            </div>

            <div className={classes.grid}>
              <LinearProgressWithLabel value={((value: number) => importDataLength > 0 ? (value * 100 / importDataLength) : 0)(progress)} />
            </div>

            <div className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                disabled={!buttonEnabled}
                size="large"
                type="button"
                onClick={handlePut}
                startIcon={<PublishIcon />}
              >
                Upload
              </Button>

            </div>
            {errors.length > 0 &&
              <div className={classes.grid} style={{ height: '600px', width: 'auto' }}>
                <DataGrid autoHeight rows={errors} pageSize={10} columns={[
                  { field: 'line', headerName: 'Linha', width: 90 },
                  { field: 'login', headerName: 'Login', width: 130 },
                  { field: 'company', headerName: 'Empresa', width: 220 },
                  { field: 'errorStatus', headerName: 'Status', width: 95 },
                  { field: 'errorMessage', headerName: 'Mensagem', width: 200 }
                ] as ColDef[]} />
              </div>
            }

          </div>

        </Paper>
      </main>
    </>
  )
}

export default UploadUser;
