import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { Button,Select,  FormControl, InputLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/CreateNewFolder';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import nodata from '../../../../assets/images/nodata.svg'
import { Action, Resource } from '../../../../utils/aclUtils';
import includesAll from '../../../../utils/includesAll';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

import api from '../../../../services/api';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FilterListIcon, SearchIcon } from '@material-ui/data-grid';

interface Admin {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
}

interface Data {
  type: string;
  registrationDate: Date;
  responsable: User;
  orderOrigin: string;
  orderType: string;
  orderSite: string;
  description: string;
  comment: string;
  expectHours: string;
  hourlyRate: string;
  totalValue: string;
  approvalDate: Date;
  approvalResponsable: Admin;
  approvalProtocol: string;
  discount: string;
  grossValue: string;
  netValue: string;
  totalHours: string;
  paidHours: string;
  netHours: string;
  totalServiceValue: string;
  allowance: string;
  allowanceResponsable: Admin;
  status: string;
  enabled: boolean;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Descrição',
  },
  {
    id: 'orderType',
    numeric: false,
    disablePadding: true,
    label: 'Tipo de Solicitação',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Natureza',
  },
  {
    id: 'expectHours',
    numeric: false,
    disablePadding: true,
    label: 'Horas estimadas',
  },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: '#fff  ',
            backgroundColor: '#01a3ff',
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  })
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

let aclItem;
if (localStorage.getItem('acl')) {
  const aclString = localStorage.getItem('acl');
  const acl: { resource: Resource; action: Action[] | '*' }[] = JSON.parse(
    aclString!
  );
  aclItem = acl.find((aclItem) => aclItem.resource === 'Service');
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface Profile {
  id: string;
  name: string;

}
export default function Solicitacoes() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('description');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);

  const [rows, setRows] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowId, setRowId] = React.useState('');
  const [display, setDisplay] = React.useState('none');
  const [searchStatus, setSearchStatus] = React.useState('');
  const [searchServices, setSearchServices] = React.useState("");
  const [searchNatureza, setSearchNatureza] = React.useState("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchStatus(event.target.value as string);
  };
  const handleChangeTypeService = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchServices(event.target.value as string);
  };

  const handleChangeTypeNatureza = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchNatureza(event.target.value as string);
  };
  const handleSetRowId = (rowId) => {
    setRowId(rowId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleFilter = () => {
    if (display === 'grid') {
      setDisplay('none');
    } else {
      setDisplay('grid');
    }
  };
  useEffect(() => {
    const getData = async () => {
      const response = await api.get(
        `/recommendation?order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`
      );
      if (response.status && response.status.toString()[0] === '2') {
        const data = await response.json();
        setRows(data.data);
        setTotalPages(data.total);
      }
    };

    getData();
  }, [orderBy, order, page, rowsPerPage]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleDelete = async (id: string) => {
    handleCloseMenu();
    Swal.fire({
      title: 'Você tem certeza que deseja excluir o cadastro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#418107',
      confirmButtonText: 'Sim, Excluir!',
      cancelButtonText: 'Cancelar!',
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const deleteConfirmed = async () => {
            await api.delete(`/recommendation/${id}`);
            const response = await api.get(
              `/recommendation?order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`
            );

            if (response.status && response.status.toString()[0] === '2') {
              const data = await response.json();
              setRows(data.data);
              setTotalPages(data.total);
            }
          };

          deleteConfirmed();
        } catch (error) {
          console.log(`Erro: ${error}`);
        }
      }
    });
  };

  const handleSearch = async () => {
    const queryParams = new URLSearchParams();
    if (searchNatureza) {
      queryParams.append('type', searchNatureza);
    }
    if (searchServices) {
      queryParams.append('orderType', searchServices);
    }
    if (searchStatus) {
      queryParams.append('status', searchStatus);
    }

    const getData = async () => {
      const response = await api.get(
        `/recommendation?${queryParams.toString()}&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`
      );
      if (response.status && response.status.toString()[0] === '2') {
        const data = await response.json();
        setRows(data.data);
        setTotalPages(data.total);
      }
    };
    getData();
  };

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selecionado(s)
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Solicitações/Recomendações
          </Typography>
        )}
        <Tooltip title="Filtro de dados">
          <IconButton onClick={handleFilter} aria-label="filter">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Listar inativos">
          <IconButton aria-label="Listar inativos">
            <NavLink to="solicitacoes-inativos">
              <DeleteSweepIcon />
            </NavLink>
          </IconButton>
        </Tooltip>

        {aclItem &&
          (aclItem.action === '*' || aclItem.action.includes('CREATE')) && (
            <Tooltip title="Cadastrar">
              <IconButton aria-label="Cadastrar">
                <NavLink to="cadastro-solicitacao">
                  <AddIcon />
                </NavLink>
              </IconButton>
            </Tooltip>
          )}
      </Toolbar>
    );
  };

  return (
    <main>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
            
          <div id="filtros" style={{ display: display, transition: "width 2s, height 4s", transitionDuration: "2s" }}>
            <div className="filterField" style={{width:"100%", display: 'flex', alignItems:  'center', justifyContent:  'center'}}>
              <FormControl aria-atomic>
                      <InputLabel>Tipo Solicitação</InputLabel>
                      <Select
                        fullWidth={true}
                        id="select"
                        native
                        required
                        value={searchServices}
                        onChange={handleChangeTypeService}
                        label="Tipo de Solicitação*"
                      >
                        <option aria-label="none" value="" />
                        <option value={"SL-Solicitação"}>SL-Solicitação</option>
                        <option value={'RC-Recomendação'}>RC-Recomendação</option>
                  
                        
                      </Select>
                </FormControl>  
            
                <FormControl aria-atomic>
                  <InputLabel>Natureza</InputLabel>
                  <Select
                    fullWidth={true}
                    id="select"
                    native
                    required
                    value={searchNatureza}
                    onChange={handleChangeTypeNatureza}
                    label="Natureza*"
                  >
                    <option aria-label="none" value="" />
                    <option value={"1-Configuração"}>Configuração</option>
                    <option value={'2-Implantação'}>Implantação</option>
                    <option value={'3-Treinamento'}>Treinamento</option>
                    <option value={'4-Desenvolvimento'}>Desenvolvimento</option>
                    <option value={'6-Visita Técnica'}>Visita Técnica</option>
                    <option value={'7-Reunião Técnica'}>Reunião Técnica</option>                 
                    
                  </Select>

                </FormControl>

                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="select"
                    native
                    required
                    value={searchStatus}
                    onChange={handleChangeStatus}
                    label="Status*"
                  >
                    <option aria-label="none" value="" />
                    <option value={"1-Em Aberto"}>Em Aberto</option>
                    <option value={'2-Firmada'}>Firmada</option>
                    
                  </Select>

                </FormControl>
                <Button
                  variant="contained"
                  className="searchButton"
                  color="primary"
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}
                >Pesquisar</Button>
            </div>
          </div>
          <TableContainer>
            {totalPages > 0 ? (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.description}
                        </TableCell>
                        <TableCell align="left">{row.orderType}</TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.expectHours}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClick(e);
                              handleSetRowId(row.id);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >
                            {aclItem &&
                              (aclItem.action === '*' ||
                                aclItem.action.includes('READ')) && (
                                <NavLink
                                  to={'visualizar-solicitacoes/' + rowId}
                                >
                                  <StyledMenuItem>
                                    <ListItemIcon>
                                      <VisibilityIcon />
                                    </ListItemIcon>
                                    <div className="actionText">Visualizar</div>
                                  </StyledMenuItem>
                                </NavLink>
                              )}
                            {aclItem &&
                              (aclItem.action === '*' ||
                                includesAll(
                                  ['READ', 'UPDATE'],
                                  aclItem.action
                                )) && (
                                <NavLink to={'editar-solicitacao/' + rowId}>
                                  <StyledMenuItem>
                                    <ListItemIcon>
                                      <EditIcon />
                                    </ListItemIcon>
                                    <div className="actionText">Editar</div>
                                  </StyledMenuItem>
                                </NavLink>
                              )}
                            {aclItem &&
                              (aclItem.action === '*' ||
                                aclItem.action.includes('DELETE')) && (
                                <StyledMenuItem
                                  onClick={() => handleDelete(rowId)}
                                >
                                  <ListItemIcon>
                                    <DeleteIcon />
                                  </ListItemIcon>
                                  <div className="actionText">Excluir</div>
                                </StyledMenuItem>
                              )}
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <>
                <div className="no-data">
                  <img src={nodata} className="no-data-svg" alt="Sem dados" />
                  <div>Nenhum registro disponível para exibição</div>
                </div>
              </>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </main>
  );
}
