import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, FormControl, InputLabel, Select, Link } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';

import { unformatDate, formatDate, formatDateWithHoursAndMinutes } from '../../../../utils/dateUtils';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../../../services/api';
import { ImageLoader } from '../../../../components/ImageLoader';


import './styles.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { statusFunction } from '../Listagem';
import CurrencyMask from '../../../../components/masks/currency';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface ParamTypes {
  id: string;
}

interface Admin {
  id: string;
  name: string;
}
interface Company {
  id: string;
  name: string;
}


function AtividadesCadastrar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [issueDate, setIssueDate] = useState('');
  const [visitNumber, setVisitNumber] = useState('');
  const [accomplishmentDate, setAccomplishmentDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [breakTimeStart, setBreakTimeStart] = useState('');
  const [breakTimeEnd, setBreakTimeEnd] = useState('');
  const [endTime, setEndTime] = useState('');
  const [type, setType] = useState('Presencial');
  const [status, setStatus] = useState(1);
  const [resposableClient, setResposableClient] = useState('');
  const [activitySite, setActivitySite] = useState('Cliente');
  const [activityType, setActivityType] = useState('Visita');
  const [revenue, setRevenue] = useState('Faturavel');
  const [paidHours, setPaidHours] = useState('0');
  const [clientEmail, setClientEmail] = useState('');
  const [approved, setApproved] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [approvalProtocol, setApprovalProtocol] = useState('');
  const [activityDescription, setActivityDescription] = useState('');
  const [pendingItems, setPendingItems] = useState('');
  const [allowance, setAllowance] = useState('');
  const [detour, setDetour] = useState('');
  const [detourHours, setDetourHours] = useState('');
  const [distance, setDistance] = useState('');
  const [toll, setToll] = useState('');
  const [meal, setMeal] = useState('');
  const [otherExpenses, setOtherExpenses] = useState('');
  const [comment, setComment] = useState('');
  const [stay, setStay] = useState('');
  const [imageProof, setImageProof] = useState('');
  

  const [responsableAoki, setResponsableAoki] = React.useState<Admin | null | undefined>({ id: '', name: '' });
  const [adminList, setAdminList] = React.useState<Admin[]>([]);

  const [company, setCompany] = React.useState<Company | null | undefined>({ id: '', name: '' });
  const [companyList, setCompanyList] = React.useState<Company[]>([]);


  const [isNew, setIsNew] = useState(false);

  const [disableFields, setDisableFields] = useState(false);


  const handleImage = (image: string) => {
    setImageProof(image);
  }

  const clearImage = () => setImageProof('')

  const classes = useStyles();
  const history = useHistory();

    
  const handleChangeAmount: any = (event: React.ChangeEvent<HTMLInputElement>, setState: any) => {
    let value: string = event.target.value.replace('R$ ', '')
    setState(value)
  }
    

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/activities/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setIssueDate(data.issueDate);
            setVisitNumber(data.visit);
            setAccomplishmentDate(data.accomplishmentDate);
            setStartTime(data.startTime);
            setBreakTimeStart(data.breakTimeStart);
            setBreakTimeEnd(data.breakTimeEnd);
            setEndTime(data.endTime);
            setStatus(data.status);
            setResponsableAoki(data.responsableAoki);
            setResposableClient(data.resposableClient);
            setType(data.type);
            setActivitySite(data.activitySite);
            setActivityType(data.activityType);
            setRevenue(data.revenue);
            setPaidHours(data.paidHours);
            setCompany(data.company);
            setClientEmail(data.clientEmail);
            setApproved(data.approved);
            setApprovalDate(data.approvalDate);
            setApprovalProtocol(data.approvalProtocol);
            setActivityDescription(data.activityDescription);
            setPendingItems(data.pendingItems);
            setDetourHours(data.detourHours);
            setDetour(data.detour);
            setDistance(data.distance);
            setToll(data.toll);
            setMeal(data.meal);
            setOtherExpenses(data.otherExpenses);
            setComment(data.comment);
            setAllowance(data.allowance);
            setStay(data.stay);
            setImageProof(data.proofImage)

          }
        };

        getData();
      }
    }
    const getAdminList = async () => {
      const response = await api.get('/admins?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setAdminList((await response.json()).data);
      }
    };
    getAdminList();

    const getCompanyList = async () => {
      const response = await api.get('/companies?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setCompanyList((await response.json()).data);
      }
    };
    getCompanyList();
  }, [id, isNew]);

  useEffect(() => {
    if (!isNew && approvalDate) {
      setDisableFields(true);
    } else {
      setDisableFields(false);
    }

  }, [isNew, approvalDate])

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  }
  const handleChangeActivitySite = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActivitySite(event.target.value as string);
  }
  const handleChangeActivityType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActivityType(event.target.value as string);
  }
  const handleChangeRevenue = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRevenue(event.target.value as string);
  }

  async function handleResendEmail() {
    try {
      const result = await api.post('/activities/resend-token/' + id);

      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Email enviado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/atividades');
          }
        });
      }
      else {
        Swal.fire({
          title: 'Não foi possível enviar o email. Tente novamente mais tarde.',
          icon: 'error',
        });
      }

    } catch (error) {
      Swal.fire({
        title: 'Erro no envio de email. Contate o suporte.',
        text: error,
        icon: 'error',
      });
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (breakTimeStart && breakTimeEnd) {
      if (startTime >= breakTimeStart) {
        Swal.fire({
          title: 'Erro',
          text: 'O horário de início de intervalo deve ser maior que o valor de início da atividade',
          icon: 'error',
        });
        return;
      }
      if (breakTimeStart >= breakTimeEnd) {
        Swal.fire({
          title: 'Erro',
          text: 'O horário de término de intervalo deve ser maior que o valor de início de intervalo',
          icon: 'error',
        });
        return;
      }
      if (breakTimeEnd >= endTime) {
        Swal.fire({
          title: 'Erro',
          text: 'O horário de término deve ser maior que o valor de término do intervalo',
          icon: 'error',
        });
        return;
      }
    }
    if (startTime >= endTime) {
      Swal.fire({
        title: 'Erro',
        text: 'O horário de início da atividade deve ser maior que o valor de término da atividade',
        icon: 'error',
      });
      return;
    }


    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }

      let result;
      if (isNew) {
        result = await api.put("/activities", {
          id: id,
          accomplishmentDate: formatDate(accomplishmentDate),
          startTime,
          breakTimeStart,
          breakTimeEnd,
          endTime,
          responsableAoki,
          resposableClient,
          type,
          status: Number(status),
          activitySite,
          activityType,
          revenue,
          paidHours: Number(paidHours),
          company,
          clientEmail,
          activityDescription,
          pendingItems,
          allowance,
          detour,
          detourHours,
          distance,
          toll,
          meal,
          otherExpenses,
          stay,
          comment,
          enabled: true,
          approved: false,
          proofImage: imageProof
        });
      }

      else {
        result = await api.patch("/activities/" + id, {
          accomplishmentDate: formatDate(accomplishmentDate),
          startTime,
          breakTimeStart,
          breakTimeEnd,
          endTime,
          status: Number(status),
          responsableAoki,
          resposableClient,
          type,
          activitySite,
          activityType,
          revenue,
          paidHours: Number(paidHours),
          company,
          clientEmail,
          activityDescription,
          pendingItems,
          allowance,
          detour,
          detourHours,
          distance,
          toll,
          meal,
          otherExpenses,
          stay,
          comment,
        });
      }


      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/atividades');
          }
        });
      }
      else {
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (

    <main>
      <div className="cadastrar">
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Atividade</h1>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="hidden"
                    name="id"
                    value={id}
                  />
                  <div className={classes.grid}>
                    <TextField
                      label="Status"
                      variant="outlined"
                      disabled
                      name="status"
                      value={statusFunction(status)}
                    />
                    {
                      (!isNew && approvalDate) &&
                      <>
                        <TextField
                          label="Nº de Protocolo de Aprovação"
                          variant="outlined"
                          disabled={disableFields}
                          name="approvalProtocol"
                          value={approvalProtocol}
                        />
                        <TextField
                          variant="outlined"
                          label="Data e Hora de Aprovação"
                          type="datetime"
                          disabled={disableFields}
                          value={formatDateWithHoursAndMinutes(approvalDate)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </>
                    }
                  </div>
                  <div className={classes.grid}>
                    {!isNew &&
                      <>
                        <TextField
                          variant="outlined"
                          label="Data de Emissão"
                          type="date"
                          disabled={disableFields}
                          value={unformatDate(issueDate)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          label="Nº da Visita"
                          variant="outlined"
                          disabled
                          name="visitNumber"
                          value={visitNumber}
                        />
                      </>
                    }

                    <TextField
                      variant="outlined"
                      label="Data de Realização"
                      type="date"
                      required
                      disabled={disableFields}
                      value={unformatDate(accomplishmentDate)}
                      onChange={(e) => { setAccomplishmentDate(e.target.value) }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </div>

                  <div className={classes.grid}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel>Tipo de visita</InputLabel>
                      <Select
                        required
                        native
                        disabled={disableFields}
                        value={activityType}
                        onChange={handleChangeActivityType}
                        label="Tipo de Visita"
                      >
                        <option value={'3'}>Avulsa</option>
                        <option value={'2'}>Ordem de Serviço</option>
                        <option value={'1'}>Projeto</option>
                      </Select>
                    </FormControl>
                  </div>

                  <div className={classes.grid}>

                    <TextField

                      label="Hora de Início"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={disableFields}
                      variant="outlined"
                      required
                      name="startTime"
                      value={startTime}
                      onChange={(e) => { setStartTime(e.target.value) }}
                    />
                    <TextField

                      label="Início do Intervalo"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={disableFields}
                      variant="outlined"
                      name="breakTimeStart"
                      value={breakTimeStart}
                      onChange={(e) => { setBreakTimeStart(e.target.value) }}
                    />
                    <TextField

                      label="Término do Intervalo"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={disableFields}
                      variant="outlined"
                      name="breakTimeEnd"
                      value={breakTimeEnd}
                      onChange={(e) => { setBreakTimeEnd(e.target.value) }}
                    />
                    <TextField

                      label="Hora de Término"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={disableFields}
                      variant="outlined"
                      required
                      name="endTime"
                      value={endTime}
                      onChange={(e) => { setEndTime(e.target.value) }}
                    />
                  </div>
                  <div className={classes.grid}>
                    <Autocomplete
                      value={responsableAoki}
                      getOptionLabel={(admin) => admin.name}
                      options={adminList}
                      disabled={disableFields}
                      onChange={(event, value) => { setResponsableAoki(value) }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          disabled={disableFields}
                          variant="outlined"
                          label="Responsável Aoki"
                        />
                      )}
                    />
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel>Modalidade</InputLabel>
                      <Select
                        required
                        native
                        disabled={disableFields}
                        value={type}
                        onChange={handleChangeType}
                        label="Modalidade"
                      >
                        <option value={'1-Presencial'}>Presencial Cliente</option>
                        <option value={'2-Online'}>Online</option>
                        <option value={'3-Em Terceiro'}>Em terceiro</option>
                        <option value={'4-Interno Aoki'}>Interno Aoki</option>
                        <option value={'5-Home Office'}>Home Office</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.grid}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel>Local da Atividade</InputLabel>
                      <Select
                        required
                        native
                        disabled={disableFields}
                        value={activitySite}
                        onChange={handleChangeActivitySite}
                        label="Local da Atividade"
                      >
                        <option value={'1-Cliente'}>Cliente</option>
                        <option value={'2-Aoki'}>Aoki</option>
                        <option value={'3-Home Office'}>Home Office</option>
                        <option value={'9-Outro Local'}>Outro Local</option>
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel>Natureza</InputLabel>
                      <Select
                        required
                        native
                        disabled={disableFields}
                        value={activityType}
                        onChange={handleChangeActivityType}
                        label="Natureza"
                      >
                        <option value={'1-Visita'}>Visita Técnica</option>
                        <option value={'2-Treinamento'}>Treinamento</option>
                        <option value={'3-Em terceiro'}>Em Terceiro</option>
                        <option value={'4-Suporte'}>Suporte</option>
                        <option value={'5-Desenvolvimento'}>Desenvolvimento</option>
                        <option value={'6-Atividade Interna Projeto'}>Atividade Interna Projeto</option>
                        <option value={'51-Reunião Pós-venda'}>Reunião Pós-venda</option>
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel>Faturamento</InputLabel>
                      <Select
                        required
                        native
                        disabled={disableFields}
                        value={revenue}
                        onChange={handleChangeRevenue}
                        label="Faturamento"
                      >
                        <option value={'Faturavel'}>Faturável</option>
                        <option value={'Bonificada'}>Bonificada</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      label="Horas Abonadas"
                      type="number"
                      variant="outlined"
                      disabled={disableFields}
                      name="paidHours"
                      value={paidHours}
                      onChange={(e) => { setPaidHours(e.target.value) }}
                    />
                    <TextField
                      label="Natureza do Abono"
                      variant="outlined"
                      disabled={disableFields}
                      name="allowance"
                      value={allowance}
                      onChange={(e) => { setAllowance(e.target.value) }}
                    />
                    <TextField
                      label="Horas Desvio"
                      type="number"
                      variant="outlined"
                      disabled={disableFields}
                      name="detourHours"
                      value={detourHours}
                      onChange={(e) => { setDetourHours(e.target.value) }}
                    />
                    <TextField
                      label="Natureza do Desvio"
                      variant="outlined"
                      disabled={disableFields}
                      name="detour"
                      value={detour}
                      onChange={(e) => { setDetour(e.target.value) }}
                    />
                    </div>
                    
                    <div className={classes.grid}>
                      <h2>
                        Cliente
                      </h2>
                    </div>
                    <div className={classes.grid}>
                    <Autocomplete
                      value={company}
                      getOptionLabel={(company) => company.name}
                      options={companyList}
                      disabled={disableFields}
                      onChange={(event, value) => { setCompany(value) }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          disabled={disableFields}
                          variant="outlined"
                          label="Empresa"
                        />
                      )}
                    />
                    <TextField
                      label="Nome completo do cliente"
                      variant="outlined"
                      required
                      disabled={disableFields}
                      name="resposableClient"
                      value={resposableClient}
                      onChange={(e) => { setResposableClient(e.target.value) }}
                    />
                    <TextField
                      label="Email do Cliente"
                      variant="outlined"
                      required
                      disabled={disableFields}
                      name="clientEmail"
                      value={clientEmail}
                      onChange={(e) => { setClientEmail(e.target.value) }}
                    />
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      label="Descrição da Atividade"
                      variant="outlined"
                      multiline
                      disabled={disableFields}
                      name="activityDescription"
                      value={activityDescription}
                      onChange={(e) => { setActivityDescription(e.target.value) }}
                    />
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      label="Itens Pendentes"
                      variant="outlined"
                      disabled={disableFields}
                      name="pendingItems"
                      value={pendingItems}
                      onChange={(e) => { setPendingItems(e.target.value) }}
                    />
                  </div>
                  <div className={classes.grid}>
                    <h2>
                      Despesas
                    </h2>
                    </div>
                    <div className={classes.grid}>
                    <TextField
                      label="Refeição"
                      variant="outlined"
                      disabled={disableFields}
                      name="meal"
                      InputProps={{
                        inputComponent: CurrencyMask as React.FC,
                        onBlur: (e) => handleChangeAmount(e, setMeal)
                        }}
                      value={meal}
                    />
                    <TextField
                      label="Estadia"
                      variant="outlined"
                      disabled={disableFields}
                      name="stay"
                      value={stay}
                      InputProps={{
                        inputComponent: CurrencyMask as React.FC,
                        onBlur: (e) => handleChangeAmount(e, setStay)
                        }}
                    />
                    <TextField
                      label="Outros"
                      variant="outlined"
                      disabled={disableFields}
                      name="otherExpenses"
                      value={otherExpenses}
                      InputProps={{
                        inputComponent: CurrencyMask as React.FC,
                        onBlur: (e) => handleChangeAmount(e, setOtherExpenses)
                        }}
                    />
                    </div>
                    <div className={classes.grid}>
                    <TextField
                      label="Quilometragem"
                      variant="outlined"
                      disabled={disableFields}
                      name="distance"
                      value={distance}
                      InputProps={{
                        inputComponent: CurrencyMask as React.FC,
                        onBlur: (e) => handleChangeAmount(e, setDistance)
                        }}
                    />
                    <TextField
                      label="Valor do pedágio"
                      variant="outlined"
                      disabled={disableFields}
                      name="toll"
                      value={toll}
                      InputProps={{
                        inputComponent: CurrencyMask as React.FC,
                        onBlur: (e) => handleChangeAmount(e, setToll)
                        }}
                    />
                    </div>
                  <div className={classes.grid}>
                    <TextField
                      label="Observação (USO INTERNO)"
                      variant="outlined"
                      disabled={disableFields}
                      name="comment"
                      value={comment}
                      onChange={(e) => { setComment(e.target.value) }}
                    />
                  </div>
                  <div className="imageUpload">
                      <ImageLoader
                        withIcon={true}
                        label='Adicione uma imagem como comprovante'
                        buttonText='Clicando aqui'
                        fileSizeError="A imagem selecionada excede o tamanho permitido (1MB)."
                        fileTypeError="Extensão não suportada."
                        imageSetter={handleImage}
                        singleImage
                        imgExtension={['.jpg', '.png']}
                        maxFileSize={1048576}
                      />
                      {imageProof &&
                        <div className="filesimages">
                          <div>
                            <img src={imageProof} height="150px" alt='icon' />
                          </div>
                          <div>
                            <IconButton className="deleteButton" onClick={clearImage}>
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                      }
                    </div>
                  <div className={classes.grid}>
                    {!isNew && !approvalDate &&
                      <div style={{ color: '#d61f1f' }}>
                        <b>ATENÇÃO:</b> A atividade ainda não foi aprovada pelo cliente...
                      </div>
                    }
                  </div>
                  <div className={classes.button}>
                    {
                      !disableFields &&
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<SaveIcon />}
                          type="submit"

                        >
                          Salvar
                        </Button>

                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          startIcon={<EmailIcon />}
                          onClick={handleResendEmail}

                        >
                          {status === 1 ? 'Enviar email' : 'Reenviar email'}
                        </Button>
                      </>
                    }
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<BackIcon />}
                      onClick={() => { history.push('/atividades') }}

                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main >
  )
}

export default AtividadesCadastrar;
