import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import MaskedInput from 'react-text-mask';

import 'date-fns';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import api from '../../../../../services/api';


import './styles.scss';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 1130px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 1130px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);
interface ProdutosEmpresaCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
  companyId: string;
}

interface CnpjMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CnpjMask(props: CnpjMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function FiliaisCadastrar() {
  const [id, setId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const params = useParams<ParamTypes>();

  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnpj, setCnpj] = useState<string | null>('');

  const [isNew, setIsNew] = useState(false);

  const [company, setCompany] = React.useState<{ id: string, name: string }>({ id: '', name: '' });

  const classes = useStyles();
  const history = useHistory();


  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }
  if (!companyId) {
    if (params.companyId) {
      setCompanyId(params.companyId);
    }
  }


  useEffect(() => {
    if (isNew) {
      if (companyId) {
        const getData = async () => {
          const response = await api.get(`/companies/${companyId}`);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setCompany(data);
          }
        };
        getData();
      }
    } else {
      const getData = async () => {
        const response = await api.get(`/branches/${id}`);
        if (response.status && response.status.toString()[0] === '2') {
          const data = await response.json();
          setName(data.name);
          setCompanyName(data.companyName);
          setCnpj(data.cnpj);
          setCompany(data.company);
        }
      };
      getData();
    }
  }, [id, companyId, isNew]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }
      let result;
      if (isNew) {
        result = await api.put("/branches", {
          id: id,
          name: name,
          companyName: companyName,
          cnpj: cnpj,
          company: { id: companyId },
          enabled: true,
        });
      }
      else {
        result = await api.patch("/branches/" + id, {
          id: id,
          name: name,
          companyName: companyName,
          cnpj: cnpj,
          company
        });
      }


      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.goBack();
          }
        });
      }
      else {
        if (result.status === 409) {
          const errorText = await result.text();
          if (errorText === 'E_BRANCH_EXISTS') {
            Swal.fire({
              title: 'Filial já cadastrada',
              text: 'Essa filial já está cadasstrada nesta empresa',
              icon: 'warning',
            });
            return;
          }
        }
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }


  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Filial da Empresa</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />

                    <div className={classes.grid}>
                      <TextField
                        label="Empresa"
                        disabled
                        variant="outlined"
                        name="empresa"
                        value={company.name}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Nome"
                        variant="outlined"
                        name="name"
                        required
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                      <TextField
                        label="Razão Social"
                        variant="outlined"
                        name="companyName"
                        required
                        value={companyName}
                        onChange={(e) => { setCompanyName(e.target.value) }}
                      />
                      <TextField
                        label="CNPJ Filial"
                        variant="outlined"
                        name="cnpj"
                        InputProps={{
                          inputComponent: CnpjMask as any,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={cnpj}
                        onChange={(e) => { setCnpj(e.target.value) }}
                      />
                    </div>

                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.goBack() }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default FiliaisCadastrar;
