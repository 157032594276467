import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {unformatDate, formatDate} from '../../../../../utils/dateUtils';

import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import 'date-fns';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import api from '../../../../../services/api';


import './styles.scss';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 1130px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 1130px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);
interface ProdutosEmpresaCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
  companyId: string;
}

interface Product {
  id: string;
  name: string;
}
interface Installer {
  id: string;
  name: string;
}


interface Version {
  id: string,
  name: string,
  code: string,
  product: { id: string, name: string },
  enabled: true,
}

function ProdutosEmpresaCadastrar() {
  const [id, setId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const params = useParams<ParamTypes>();

  const [status, setStatus] = useState('');
  const [license, setLicense] = useState('');
  const [installMode, setInstallMode] = useState('');
  const [modules, setModules] = useState('');
  const [usersOriginal, setUsersOriginal] = useState('');
  const [usersActual, setUsersActual] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [installDate, setInstallDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [suspensionDate, setSuspensionDate] = useState<string | null>('');
  const [cancelDate, setCancelDate] = useState<string | null>('');

  const [isNew, setIsNew] = useState(false);

  const [company, setCompany] = React.useState<{ id: string, name: string }>({ id: '', name: '' });

  const [product, setProduct] = React.useState<Product | null | undefined>({ id: '', name: '' });
  const [productsList, setProductsList] = React.useState<Product[]>([]);

  const [installer, setInstaller] = React.useState<Installer | null | undefined>({ id: '', name: '' });
  const [installerList, setInstallerList] = React.useState<Installer[]>([]);

  const [versionsList, setVersionsList] = useState<Version[]>([]);
  const [version, setVersion] = useState<Version | null | undefined>({
    id: '',
    name: '',
    code: '',
    product: { id: '', name: '' },
    enabled: true,
  });
  
  const [build, setBuild] = useState('');

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };
  const handleChangeInstallMode = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInstallMode(event.target.value as string);
  };

  const classes = useStyles();
  const history = useHistory();


  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }
  if (!companyId) {
    if (params.companyId) {
      setCompanyId(params.companyId);
    }
  }


  useEffect(() => {
    if (product && product.name.length > 0) {
      const getVersions = async () => {
        const responseVersions = await api.get(`/versions?product=${product.id}`);
        if (responseVersions.status && responseVersions.status.toString()[0] === '2') {
          const dataVersions = (await responseVersions.json()).data;

          if (dataVersions) {
            setVersionsList(dataVersions);
          }
        }
      };
      getVersions();
    }

  }, [product])




  useEffect(() => {
    if (isNew) {
      if (companyId) {
        const getData = async () => {
          const response = await api.get(`/companies/${companyId}`);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setCompany(data);
          }
        };
        getData();
      }
    } else {
      const getData = async () => {
        const response = await api.get(`/company-versions/${id}`);
        if (response.status && response.status.toString()[0] === '2') {
          const data = await response.json();
          setProduct(data.version.product);
          setVersion(data.version);
          setStatus(data.status);
          setLicense(data.licence);
          setCompany(data.company);
          setInstallDate(data.installDate);
          setInstaller(data.installer);
          setInstallMode(data.installMode);
          setUsersOriginal(data.usersOriginal);
          setUsersActual(data.usersActual);
          setPurchaseDate(data.purchaseDate);
          setDueDate(data.dueDate);
          setCancelDate(data.cancelDate);
          setSuspensionDate(data.suspensionDate);
          setModules(data.modules);
          setBuild(data.build);
        }
      };
      getData();
    }

    const getProductsList = async () => {
      const response = await api.get('/products');
      if (response.status && response.status.toString()[0] === '2') {
        setProductsList((await response.json()).data);
      }
    };
    getProductsList();

    const getInstallerList = async () => {
      const response = await api.get('/admins');
      if (response.status && response.status.toString()[0] === '2') {
        setInstallerList((await response.json()).data);
      }
    };
    getInstallerList();




  }, [id, companyId, isNew]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }
      let result;
      if (isNew) {
        result = await api.put("/company-versions", {
          id: id,
          version,
          status: Number(status),
          licence: license,
          company,
          installer,
          installMode,
          usersOriginal: Number(usersOriginal),
          usersActual: Number(usersActual),
          purchaseDate: formatDate(purchaseDate),
          dueDate: formatDate(dueDate),
          modules,
          build
        });
      }
      else {
        if (!cancelDate) {
          setCancelDate(null)
        }
        if (!suspensionDate)
          setSuspensionDate(null)

        result = await api.patch("/company-versions/" + id, {
          version,
          status: Number(status),
          licence: license,
          company,
          installer,
          installMode,
          usersOriginal: Number(usersOriginal),
          usersActual: Number(usersActual),
          purchaseDate: formatDate(purchaseDate),
          dueDate: formatDate(dueDate),
          cancelDate: formatDate(cancelDate),
          suspensionDate: formatDate(suspensionDate),
          modules,
          build
        });
      }


      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.goBack();
          }
        });
      }
      else {
        if (result.status === 409) {
          const errorText = await result.text();
          if (errorText === 'E_PRODUCT_EXISTS') {
            Swal.fire({
              title: 'Produto já cadastrado',
              text: 'Essa versão do produto já existe nesta empresa',
              icon: 'warning',
            });
            return;
          }
        }
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }


  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Produto da Empresa</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <Autocomplete
                        value={product}
                        getOptionLabel={(productsList) => productsList.name}
                        options={productsList}
                        onChange={(event, value) => { setProduct(value); setVersion(null); }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            label="Produtos"
                          />
                        )}
                      />
                      <Autocomplete
                        value={version}
                        getOptionLabel={(versions) => `${versions.name} (${versions.code})`}
                        options={versionsList}
                        onChange={(event, value) => { setVersion(value) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            label="Versão"
                          />
                        )}
                      />
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Status *</InputLabel>
                        <Select

                          id="select"
                          native
                          required
                          value={status}
                          onChange={handleChangeStatus}
                          label="Status *"
                        >
                          <option aria-label="None" value="" />
                          <option value={1}>Pendente</option>
                          <option value={2}>Ativo</option>
                          <option value={3}>Suspenso</option>
                          <option value={4}>Inativo</option>
                        </Select>

                      </FormControl>
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Licença"
                        variant="outlined"
                        name="license"
                        required
                        value={license}
                        onChange={(e) => { setLicense(e.target.value) }}
                      />
                      <TextField
                        label="Build"
                        variant="outlined"
                        name="build"
                        value={build}
                        onChange={(e) => { setBuild(e.target.value) }}
                      />
                      <TextField
                        label="Empresa"
                        disabled
                        variant="outlined"
                        name="empresa"
                        value={company.name}
                      />
                    </div>
                    <div className={classes.grid}>
                      <Autocomplete
                        value={installer}
                        getOptionLabel={(installer) => installer.name}
                        options={installerList}
                        onChange={(event, value) => { setInstaller(value) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            label="Instalador"
                          />
                        )}
                      />
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Local de Instalação *</InputLabel>
                        <Select

                          id="select"
                          native
                          required
                          value={installMode}
                          onChange={handleChangeInstallMode}
                          label="Local de Instalação *"
                        >
                          <option aria-label="None" value="" />
                          <option value={'cloud'}>Cloud</option>
                          <option value={'local'}>Local</option>
                        </Select>

                      </FormControl>
                      <TextField
                        label="Nº de Usuários Originais"
                        type="number"
                        variant="outlined"
                        name="usersOriginal"
                        value={usersOriginal}
                        onChange={(e) => { setUsersOriginal(e.target.value) }}
                      />
                      <TextField
                        label="Nº Atual de Usuários"
                        type="number"
                        variant="outlined"
                        name="usersActual"
                        value={usersActual}
                        onChange={(e) => { setUsersActual(e.target.value) }}
                      />

                    </div>
                    <div className={classes.grid}>
                      {!isNew &&
                        <TextField
                          variant="outlined"
                          disabled
                          label="Data de Instalação"
                          type="date"
                          value={unformatDate(installDate)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />}
                      <TextField
                        variant="outlined"
                        label="Data de Compra"
                        type="date"
                        required
                        value={unformatDate(purchaseDate)}
                        onChange={(e) => { setPurchaseDate(e.target.value) }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <TextField
                        variant="outlined"
                        label="Data de Vencimento"
                        type="date"
                        value={unformatDate(dueDate)}
                        onChange={(e) => { setDueDate(e.target.value) }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className={classes.grid}>
                      {!isNew &&
                        <>
                          <TextField
                            variant="outlined"
                            label="Data de Cancelamento"
                            type="date"
                            value={unformatDate(cancelDate)}
                            onChange={(e) => { setCancelDate(e.target.value) }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            variant="outlined"
                            label="Data de Suspensão"
                            type="date"
                            value={unformatDate(suspensionDate)}
                            onChange={(e) => { setSuspensionDate(e.target.value) }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      }
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        label="Módulos"
                        multiline
                        variant="outlined"
                        name="modules"
                        value={modules}
                        onChange={(e) => { setModules(e.target.value) }}
                      />
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.goBack() }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ProdutosEmpresaCadastrar;
