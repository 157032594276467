import React, { useState, useEffect,useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField,Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BackIcon from '@material-ui/icons/ArrowBack';
import api from '../../../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    pass: {
      display: 'abolsute',

      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
);

interface ParamTypes {
  id: string;
}

interface State {
  phone: string;
}
interface Profile {
  id: string;
  name: string;
}

function AdminVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState<string | undefined>('');
  const [email, setEmail] = useState('');
  const [showPassword] = React.useState(false);
  const [isNew, setIsNew] = useState(false);
  const [profile, setProfile] = React.useState<Profile | null | undefined>({
    id: '',
    name: '',
  });

  const [profiles, setProfiles] = React.useState<Profile[]>([]);
  const [values, setValues] = React.useState<State>({
    phone: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const classes = useStyles();
  const history = useHistory();

  const getIsNew = useCallback(() => {
    if (!id) {
      if (params.id) {
        setId(params.id);
      } else {
        setIsNew(true);
      }
    }
  }, [id, params.id]);

  useEffect(() => {
    const getProfiles = async () => {
      const response = await api.get('/profiles');
      if (response.status && response.status.toString()[0] === '2') {
        setProfiles((await response.json()).data);
      }
    };
    getProfiles();

    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/admins/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setValues((values) => ({
              ...values,
              phone: data.phone,
            }));
            setName(data.name);
            setLogin(data.login);
            setProfile(data.profile);
            setEmail(data.email);
            setPassword(data.password);
          }
        };
        getData();
      }
    }
    getIsNew();
  }, [id, isNew, getIsNew]);

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Usuários</h1>
                  <form >
                    <div className={classes.grid2}>
                      <Autocomplete
                        disabled
                        id="controllable-states-demo"
                        value={profile}
                        getOptionLabel={(profile) => profile.name}
                        options={profiles}
                        onChange={(event, value) => {
                          setProfile(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Perfil"
                            required
                            variant="outlined"
                          />
                        )}
                      />
                      <TextField
                        disabled
                        id="login"
                        label="Login"
                        variant="outlined"
                        required
                        name="login"
                        value={login}
                        onChange={(e) => {
                          setLogin(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField
                        disabled
                        label="Nome Completo"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.grid2}>
                      <TextField
                        disabled
                        id="email"
                        label="Email"
                        variant="outlined"
                        required
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <TextField
                        disabled
                        id="phone"
                        label="Telefone"
                        variant="outlined"
                        name="phone"                                           
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </div>
                    {isNew && (
                      <div className={classes.grid}>
                        <div className={classes.pass}>
                          <TextField
                            disabled
                            required
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Senha"
                            variant="outlined"
                            name="password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                          <div className="container-pass">                    
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={classes.button}>              
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => {
                          history.push('/admins');
                        }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AdminVisualizar;
