import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Home from '../pages/Admin/Home';

import Ajuda from '../pages/Admin/Ajuda';

import Perfil from '../pages/Admin/Perfil/Listagem';
import PerfilInativos from '../pages/Admin/Perfil/Inativos';
import PerfilCadastrar from '../pages/Admin/Perfil/Cadastro';
import PerfilVisualizar from '../pages/Admin/Perfil/Visualizar';


import Admins from '../pages/Admin/Admins/Listagem';
import AdminsInativos from '../pages/Admin/Admins/Inativos';
import AdminsCadastrar from '../pages/Admin/Admins/Cadastro';

import Servidores from '../pages/Admin/Servidores/Listagem';
import ServidoresInativos from '../pages/Admin/Servidores/Inativos';
import ServidoresCadastrar from '../pages/Admin/Servidores/Cadastro';

import Clusters from '../pages/Admin/Clusters/Listagem';
import ClustersInativos from '../pages/Admin/Clusters/Inativos';
import ClustersCadastrar from '../pages/Admin/Clusters/Cadastro';
import ClustersVisualizar from '../pages/Admin/Clusters/Visualizar';

import Alertas from '../pages/Admin/Alertas/Listagem';
import AlertasInativos from '../pages/Admin/Alertas/Inativos';
import AlertasCadastrar from '../pages/Admin/Alertas/Cadastro';
import AlertaVisualizar from '../pages/Admin/Alertas/Visualizar'

import Atividades from '../pages/Admin/Atividades/Listagem';
import AtividadesInativos from '../pages/Admin/Atividades/Inativos';
import AtividadesCadastrar from '../pages/Admin/Atividades/Cadastro';
import AprovarAtividades from '../pages/Admin/Atividades/Aprovacao';

import TiposAlerta from '../pages/Admin/TiposAlerta/Listagem';
import TiposAlertaInativos from '../pages/Admin/TiposAlerta/Inativos';
import TiposAlertaCadastrar from '../pages/Admin/TiposAlerta/Cadastro';
import TiposAlertaVisualizar from '../pages/Admin/TiposAlerta/Visualizar';

import Avisos from '../pages/Admin/Avisos/Listagem';
import AvisosInativos from '../pages/Admin/Avisos/Inativos';
import AvisoCadastrar from '../pages/Admin/Avisos/Cadastro';

import NiveisAlerta from '../pages/Admin/NiveisAlerta/Listagem';
import NiveisAlertaInativos from '../pages/Admin/NiveisAlerta/Inativos';
import NiveisAlertaCadastrar from '../pages/Admin/NiveisAlerta/Cadastro';
import NiveisAlertaVisualizar from '../pages/Admin/NiveisAlerta/Visualizar'

import Produtos from '../pages/Admin/Produtos/Listagem';
import ProdutosInativos from '../pages/Admin/Produtos/Inativos';
import ProdutosCadastrar from '../pages/Admin/Produtos/Cadastro';
import ProdutosEmpresaCadastrar from '../pages/Admin/Clientes/ProdutosEmpresa/Cadastro';
import ProdutosEmpresa from '../pages/Admin/Clientes/ProdutosEmpresa/Listagem';

import FiliaisCadastrar from '../pages/Admin/Clientes/Filiais/Cadastro';
import Filiais from '../pages/Admin/Clientes/Filiais/Listagem';
import FiliaisInativas from '../pages/Admin/Clientes/Filiais/Inativos';

import Clientes from '../pages/Admin/Clientes/Listagem';
import ClientesInativos from '../pages/Admin/Clientes/Inativos';
import ClienteCadastrar from '../pages/Admin/Clientes/Cadastro';
import ClienteVisualizar from '../pages/Admin/Clientes/Visualizar';

import ManutencaoEmpresaBuscar from '../pages/Admin/Manutencao';

import Versoes from '../pages/Admin/Versoes/Listagem';
import VersoesCadastrar from '../pages/Admin/Versoes/Cadastro';
import VersoesVisualizar from '../pages/Admin/Versoes/Visualizar';

import Usuarios from '../pages/Admin/Usuarios/Listagem';
import UsuariosInativos from '../pages/Admin/Usuarios/Inativos';
import UsuarioCadastrar from '../pages/Admin/Usuarios/Cadastro';
import UsuarioUpload from '../pages/Admin/Usuarios/Upload';
import UsuarioVisualizar from '../pages/Admin/Usuarios/Visualizar';

import AdminLogin from '../pages/Admin/Login';
import AdminVisualizar from '../pages/Admin/Admins/Visualizar';

import Esqueci from '../pages/Admin/Login/Esqueci';

import NovaSenha from '../pages/Admin/Login/NovaSenha'

import NotFound from '../pages/404Page'

import Servicos from '../pages/Admin/Servicos/Listagem'
import ServicosCadastrar from '../pages/Admin/Servicos/Cadastro'
import ServicosInativos from '../pages/Admin/Servicos/Inativos'
import ServicosVisualizar from '../pages/Admin/Servicos/Visualizar';
import ServidorVisualizar from '../pages/Admin/Servidores/Visualizar';

import Solicitacoes from '../pages/Admin/Solicitacoes/Listagem'
import SolicitacoesCadastrar from '../pages/Admin/Solicitacoes/Cadastro'
import SolicitacoesInativos from '../pages/Admin/Solicitacoes/Inativos'

import TopMenu from '../components/TopMenu';
import SideMenu from '../components/SideMenu';
import { Action, Resource } from '../utils/aclUtils'; import ProdutosVisualizar from '../pages/Admin/Produtos/Visualizar';
import AvisoVisualizar from '../pages/Admin/Avisos/Visualizar';
import Logs from '../pages/Admin/LogsList';
interface AdminRoutesProps {
  useBasename: boolean;
};

const resources: { key: Resource, name?: string, parent?: Resource, icon?: JSX.Element, pages: { action: Action, component: () => JSX.Element, path: string }[] }[] = [
  {
    key: "User",
    name: 'Usuários',
    parent: 'Company',
    pages: [
      {
        action: "CREATE",
        component: UsuarioCadastrar,
        path: "/cadastro-usuario-por-empresa/:companyid?"
      },
      {
        action: "CREATE",
        component: UsuarioCadastrar,
        path: "/cadastro-usuario"
      },
      {
        action: "CREATE",
        component: UsuarioUpload,
        path: "/upload-usuario"
      },
      {
        action: "UPDATE",
        component: UsuarioCadastrar,
        path: "/editar-usuario/:id"
      },
      {
        action: "LIST",
        component: Usuarios,
        path: "/usuarios"
      },
      {
        action: "LIST",
        component: Usuarios,
        path: "/usuarios-por-empresa/:companyId"
      },
      {
        action: "LIST",
        component: UsuariosInativos,
        path: "/usuarios-inativos"
      },
      {
        action: "LIST",
        component: UsuarioVisualizar,
        path: "/visualizar-usuario/:id"
      },

    ]
  },
  {
    key: "Company",
    name: 'Clientes',
    icon: <svg xmlns="http://www.w3.org/2000/svg" stroke="#fff" strokeWidth="0.5" height="25" width="25" fill="#fff" viewBox="0 0 64 64"><g id="Briefcase"><path d="M61,14H50V9a5.0059,5.0059,0,0,0-5-5H19a5.0059,5.0059,0,0,0-5,5v5H3a3.0033,3.0033,0,0,0-3,3V27.5879A3.0056,3.0056,0,0,0,1.9961,30.415L3,30.7714V53a7.0085,7.0085,0,0,0,7,7H54a7.0085,7.0085,0,0,0,7-7V30.7714l1.0039-.3564A3.0056,3.0056,0,0,0,64,27.5879V17A3.0033,3.0033,0,0,0,61,14ZM16,9a3.0033,3.0033,0,0,1,3-3H45a3.0033,3.0033,0,0,1,3,3v5H46V9a1,1,0,0,0-1-1H19a1,1,0,0,0-1,1v5H16Zm28,5H20V10H44ZM40,40H24V34H40ZM10,58a5.0059,5.0059,0,0,1-5-5V48.0449A11.0183,11.0183,0,0,1,14.9551,58Zm49-5a5.0059,5.0059,0,0,1-5,5H49.0449A11.0183,11.0183,0,0,1,59,48.0449Zm0-21.5137V46.0507A13.0039,13.0039,0,0,0,47.0507,58H16.9493A13.0039,13.0039,0,0,0,5,46.0507V31.4863c1.29.4575,15.867,5.6274,17,6.03V41a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V37.5159C43.1343,37.1132,57.7114,31.9434,59,31.4863Zm3-3.8984a1.0026,1.0026,0,0,1-.666.9424L42,35.3936V33a1,1,0,0,0-1-1H23a1,1,0,0,0-1,1v2.3936L2.666,28.53A1.0026,1.0026,0,0,1,2,27.5879V17a1.0006,1.0006,0,0,1,1-1H61a1.0006,1.0006,0,0,1,1,1Z" /></g></svg>,
    pages: [
      {
        action: "CREATE",
        component: ClienteCadastrar,
        path: "/cadastro-cliente"
      },
      {
        action: "UPDATE",
        component: ClienteCadastrar,
        path: "/editar-cliente/:id"
      },
      {
        action: "LIST",
        component: Clientes,
        path: "/clientes"
      },
      {
        action: "LIST",
        component: ClientesInativos,
        path: "/clientes-inativos"
      },
      {
        action: "CREATE",
        component: FiliaisCadastrar,
        path: "/cadastrar-filial/:companyId"
      },
      {
        action: "UPDATE",
        component: FiliaisCadastrar,
        path: "/editar-filial/:id"
      },
      {
        action: "LIST",
        component: Filiais,
        path: "/filiais/:companyId"
      },
      {
        action: "LIST",
        component: FiliaisInativas,
        path: "/filiais-inativas/:companyId"
      },
      {
        action: "LIST",
        component: ClienteVisualizar,
        path: "/visualizar-cliente/:id"
      },
    ]
  },
  {
    key: "Maintenance",
    name: 'Manutenção',
    icon: <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.55454 10.5729C5.4606 10.5649 5.35268 10.5647 5.26011 10.5733C5.25364 10.5739 5.24718 10.5747 5.24074 10.5755C4.09708 10.7324 2.9922 11.4563 2.14134 12.4327C1.5359 13.1276 1.03927 13.9721 0.737256 14.8826C0.6397 14.6225 0.551678 14.3058 0.49952 13.9318C0.321387 12.6548 0.553652 10.6466 2.38119 7.87665C2.55296 7.61757 2.5534 7.28508 2.39456 7.03055L5.07857 4.27726C5.46001 4.53957 5.98865 4.49864 6.32415 4.15447L6.92502 3.5381C7.26083 3.19362 7.29555 2.65494 7.0292 2.2703L8.59926 0.659705L8.59927 0.659712L8.60131 0.657593C8.86648 0.381996 9.29699 0.378905 9.57072 0.659705L12.0558 3.20897L12.0558 3.20897L12.0579 3.21107C12.3329 3.48945 12.3358 3.95118 12.0558 4.23835L10.5048 5.82946C10.1689 5.57397 9.688 5.60212 9.38404 5.91392L8.60234 6.7158C8.29915 7.02682 8.27633 7.51372 8.53388 7.85122L5.82551 10.6295C5.7321 10.5965 5.63985 10.5797 5.55454 10.5729ZM5.55454 10.5729C5.55505 10.5729 5.55556 10.573 5.55607 10.573L5.51774 11.0214L5.55314 10.5728C5.55361 10.5728 5.55407 10.5728 5.55454 10.5729Z" stroke="white" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M24.6984 25.9777L24.6973 25.9789L23.6239 27.08C23.012 27.7076 22.023 27.69 21.4327 27.044C21.4324 27.0437 21.4321 27.0433 21.4318 27.043L7.81441 11.9252L9.94037 9.74435L24.6612 23.7034C24.6615 23.7037 24.6619 23.704 24.6622 23.7043C25.2983 24.3159 25.3149 25.35 24.6984 25.9777Z" stroke="white" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.2566 27.044C4.59276 26.3631 4.59276 25.2594 5.2566 24.5785L11.0236 18.6626L13.3188 21.2065L7.62813 27.044C6.97054 27.7186 5.91419 27.7186 5.2566 27.044Z" stroke="white" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M23.4641 11.7228L23.4662 11.7236C23.9668 11.9127 24.5348 11.7863 24.91 11.4013L27.5401 8.70337C27.6254 10.0548 27.1644 11.4331 26.1611 12.467C24.8024 13.8605 22.8244 14.2302 21.1397 13.576C20.9715 13.5107 20.7805 13.5522 20.6546 13.6814L18.9811 15.398L16.5012 13.0436L18.2889 11.2098C18.4096 11.0859 18.4487 10.9038 18.3894 10.7413C17.7567 9.00886 18.1133 6.97806 19.4516 5.58874L19.4518 5.58855C20.4573 4.54359 21.8072 4.06871 23.1294 4.15909L20.4737 6.88335C20.1002 7.26649 19.983 7.83728 20.1624 8.33696L20.1624 8.33696L20.1631 8.33892L20.8447 10.2092C20.9782 10.5893 21.2753 10.8808 21.6355 11.0216L21.6355 11.0216L21.6382 11.0226L23.4641 11.7228Z" stroke="white" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,

    pages: [
      {
        action: "CREATE",
        component: ManutencaoEmpresaBuscar,
        path: "/manutencao-buscar-empresa"
      },
      {
        action: "LIST",
        component: ManutencaoEmpresaBuscar,
        path: "/manutencao-buscar-empresa"
      },
      {
        action: "UPDATE",
        component: ManutencaoEmpresaBuscar,
        path: "/manutencao-buscar-empresa"
      },
      {
        action: "DELETE",
        component: ManutencaoEmpresaBuscar,
        path: "/manutencao-buscar-empresa"
      },

    ]
  },
  {
    key: "Activity",
    name: 'Atividades',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 64 64" fill="#fff">
      <path d="M59,9H57V5a1,1,0,0,0-1-1H50a1,1,0,0,0-1,1V9H43V5a1,1,0,0,0-1-1H36a1,1,0,0,0-1,1V9H29V5a1,1,0,0,0-1-1H22a1,1,0,0,0-1,1V9H15V5a1,1,0,0,0-1-1H8A1,1,0,0,0,7,5V9H5a5.0059,5.0059,0,0,0-5,5V55a5.0059,5.0059,0,0,0,5,5H59a5.0059,5.0059,0,0,0,5-5V14A5.0059,5.0059,0,0,0,59,9ZM51,6h4v8H51ZM37,6h4v8H37ZM23,6h4v8H23ZM9,6h4v8H9ZM2,14a3.0033,3.0033,0,0,1,3-3H7v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V11h6v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V11h6v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V11h6v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V11h2a3.0033,3.0033,0,0,1,3,3v6H2ZM62,55a3.0033,3.0033,0,0,1-3,3H5a3.0033,3.0033,0,0,1-3-3V22H62Z" />
      <path d="M29,33h6a1,1,0,0,0,1-1V26a1,1,0,0,0-1-1H29a1,1,0,0,0-1,1v6A1,1,0,0,0,29,33Zm1-6h4v4H30Z" />
      <path d="M18,33h6a1,1,0,0,0,1-1V26a1,1,0,0,0-1-1H18a1,1,0,0,0-1,1v6A1,1,0,0,0,18,33Zm1-6h4v4H19Z" />

      <path d="M29,44h6a1,1,0,0,0,1-1V37a1,1,0,0,0-1-1H29a1,1,0,0,0-1,1v6A1,1,0,0,0,29,44Zm1-6h4v4H30Z" />
      <path d="M18,44h6a1,1,0,0,0,1-1V37a1,1,0,0,0-1-1H18a1,1,0,0,0-1,1v6A1,1,0,0,0,18,44Zm1-6h4v4H19Z" />
      <path d="M7,44h6a1,1,0,0,0,1-1V37a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1v6A1,1,0,0,0,7,44Zm1-6h4v4H8Z" />
      <path d="M40,44h6a1,1,0,0,0,1-1V37a1,1,0,0,0-1-1H40a1,1,0,0,0-1,1v6A1,1,0,0,0,40,44Zm1-6h4v4H41Z" />

      <path d="M18,55h6a1,1,0,0,0,1-1V48a1,1,0,0,0-1-1H18a1,1,0,0,0-1,1v6A1,1,0,0,0,18,55Zm1-6h4v4H19Z" />

      <path d="M51,55h6a1,1,0,0,0,1-1V48a1,1,0,0,0-1-1H51a1,1,0,0,0-1,1v6A1,1,0,0,0,51,55Zm1-6h4v4H52Z" />
    </svg>,
    pages: [
      {
        action: "CREATE",
        component: AtividadesCadastrar,
        path: "/cadastro-atividade"
      },
      {
        action: "UPDATE",
        component: AtividadesCadastrar,
        path: "/editar-atividade/:id"
      },
      {
        action: "LIST",
        component: Atividades,
        path: "/atividades"
      },
      {
        action: "LIST",
        component: AtividadesInativos,
        path: "/atividades-inativas"
      }
    ]
  },
  {
    key: "Admin",
    name: 'Usuários',
    icon: <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
      width="30" height="30" viewBox="0 0 90 90" enableBackground="new 0 0 100 100">
      <circle fill="none" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" cx="49.999" cy="49.999" r="14.144" />
      <path fill="none" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" d="
            M78.57,49.999c0-1.251-0.108-2.474-0.263-3.683l10.618-5.961l-1.769-5.432l-12.063,1.412c-1.188-2.177-2.669-4.166-4.358-5.953
            l5.087-11.063l-4.625-3.359l-8.95,8.255c-2.199-1.048-4.541-1.827-7.003-2.285L52.855,10h-5.713L44.754,21.93
            c-2.463,0.458-4.805,1.24-7.004,2.285l-8.95-8.255l-4.624,3.356l5.087,11.066c-1.693,1.788-3.173,3.776-4.36,5.953l-12.065-1.412
            l-1.764,5.432l10.618,5.961c-0.156,1.209-0.265,2.432-0.265,3.683c0,1.25,0.106,2.476,0.265,3.682l-10.618,5.961l1.764,5.437
            l12.065-1.416c1.188,2.176,2.668,4.169,4.36,5.952L24.176,80.68l4.624,3.36l8.95-8.259c2.199,1.049,4.543,1.83,7.006,2.289
            L47.142,90h5.713l2.387-11.93c2.465-0.459,4.807-1.24,7.007-2.289l8.949,8.259l4.625-3.362l-5.087-11.063
            c1.689-1.787,3.171-3.776,4.358-5.952l12.063,1.416l1.769-5.437l-10.618-5.961C78.462,52.471,78.57,51.248,78.57,49.999z"/>
    </svg>,
    pages: [
      {
        action: "CREATE",
        component: AdminsCadastrar,
        path: "/cadastro-admin",
      },
      {
        action: "UPDATE",
        component: AdminsCadastrar,
        path: "/editar-admin/:id"
      },
      {
        action: "LIST",
        component: Admins,
        path: "/admins"
      },
      {
        action: "LIST",
        component: AdminsInativos,
        path: "/admins-inativos"
      },
      {
        action: "LIST",
        component: AdminVisualizar,
        path: "/visualizar-admin/:id"
      }
    ]
  },
  {
    key: "Log",
    name: "Logs",
    icon: <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
      width="30" height="30" viewBox="0 0 90 90" enableBackground="new 0 0 100 100">
      <circle fill="none" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" cx="49.999" cy="49.999" r="14.144" />
      <path fill="none" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" d="
            M78.57,49.999c0-1.251-0.108-2.474-0.263-3.683l10.618-5.961l-1.769-5.432l-12.063,1.412c-1.188-2.177-2.669-4.166-4.358-5.953
            l5.087-11.063l-4.625-3.359l-8.95,8.255c-2.199-1.048-4.541-1.827-7.003-2.285L52.855,10h-5.713L44.754,21.93
            c-2.463,0.458-4.805,1.24-7.004,2.285l-8.95-8.255l-4.624,3.356l5.087,11.066c-1.693,1.788-3.173,3.776-4.36,5.953l-12.065-1.412
            l-1.764,5.432l10.618,5.961c-0.156,1.209-0.265,2.432-0.265,3.683c0,1.25,0.106,2.476,0.265,3.682l-10.618,5.961l1.764,5.437
            l12.065-1.416c1.188,2.176,2.668,4.169,4.36,5.952L24.176,80.68l4.624,3.36l8.95-8.259c2.199,1.049,4.543,1.83,7.006,2.289
            L47.142,90h5.713l2.387-11.93c2.465-0.459,4.807-1.24,7.007-2.289l8.949,8.259l4.625-3.362l-5.087-11.063
            c1.689-1.787,3.171-3.776,4.358-5.952l12.063,1.416l1.769-5.437l-10.618-5.961C78.462,52.471,78.57,51.248,78.57,49.999z"/>
    </svg>,
    pages: [
      {
        action: "CREATE",
        component: Logs,
        path: "/logs",
      },
      {
        action: "LIST",
        component: Logs,
        path: "/logs"
      },
    ]
  },
  {
    key: "Profile",
    name: 'Perfil',
    icon: <svg width="27" height="27" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 0.46875C12.126 0.46875 9.31653 1.32099 6.92688 2.91771C4.53723 4.51442 2.67472 6.78389 1.57488 9.43913C0.475046 12.0944 0.187279 15.0161 0.74797 17.8349C1.30866 20.6537 2.69263 23.2429 4.72486 25.2751C6.75709 27.3074 9.34631 28.6913 12.1651 29.252C14.9839 29.8127 17.9056 29.525 20.5609 28.4251C23.2161 27.3253 25.4856 25.4628 27.0823 23.0731C28.679 20.6835 29.5313 17.874 29.5313 15C29.5269 11.1474 27.9945 7.45386 25.2703 4.72966C22.5462 2.00547 18.8526 0.473105 15 0.46875ZM15 28.5938C12.3114 28.5938 9.6832 27.7965 7.44772 26.3028C5.21224 24.8091 3.4699 22.686 2.44102 20.2021C1.41214 17.7182 1.14294 14.9849 1.66746 12.348C2.19197 9.71106 3.48665 7.28889 5.38777 5.38777C7.28889 3.48665 9.71107 2.19197 12.348 1.66745C14.9849 1.14293 17.7182 1.41213 20.2021 2.44101C22.686 3.46989 24.8091 5.21224 26.3028 7.44772C27.7965 9.6832 28.5938 12.3114 28.5938 15C28.5897 18.604 27.1561 22.0593 24.6077 24.6077C22.0593 27.1561 18.604 28.5896 15 28.5938Z" fill="white" />
      <path d="M25.1182 21.9154C22.4069 19.2842 18.7773 17.8125 14.9991 17.8125C11.2209 17.8125 7.59132 19.2842 4.88002 21.9154C4.79182 22.0023 4.74158 22.1206 4.74026 22.2444C4.73894 22.3682 4.78665 22.4874 4.87298 22.5762C4.9593 22.6649 5.07723 22.7159 5.20102 22.718C5.32481 22.7201 5.4444 22.6732 5.53369 22.5874C8.07 20.1264 11.4651 18.75 14.9991 18.75C18.5331 18.75 21.9282 20.1264 24.4645 22.5874C24.5538 22.6732 24.6734 22.7201 24.7972 22.718C24.921 22.7159 25.0389 22.6649 25.1252 22.5762C25.2116 22.4874 25.2593 22.3682 25.258 22.2444C25.2566 22.1206 25.2064 22.0023 25.1182 21.9154Z" fill="white" />
      <path d="M15 15.9375C16.1125 15.9375 17.2001 15.6076 18.1251 14.9895C19.0501 14.3714 19.7711 13.4929 20.1968 12.4651C20.6226 11.4373 20.734 10.3063 20.5169 9.21512C20.2999 8.12398 19.7642 7.1217 18.9775 6.33503C18.1908 5.54836 17.1885 5.01263 16.0974 4.79559C15.0062 4.57854 13.8752 4.68994 12.8474 5.11568C11.8196 5.54142 10.9411 6.26239 10.323 7.18742C9.7049 8.11245 9.375 9.19998 9.375 10.3125C9.37666 11.8038 9.96983 13.2336 11.0244 14.2881C12.0789 15.3427 13.5087 15.9358 15 15.9375ZM15 5.625C15.9271 5.625 16.8334 5.89992 17.6042 6.41499C18.3751 6.93006 18.9759 7.66215 19.3307 8.51867C19.6855 9.3752 19.7783 10.3177 19.5974 11.227C19.4166 12.1363 18.9701 12.9715 18.3146 13.6271C17.659 14.2826 16.8238 14.7291 15.9145 14.9099C15.0052 15.0908 14.0627 14.998 13.2062 14.6432C12.3496 14.2884 11.6176 13.6876 11.1025 12.9167C10.5874 12.1459 10.3125 11.2396 10.3125 10.3125C10.3139 9.06973 10.8082 7.87827 11.687 6.9995C12.5658 6.12073 13.7572 5.62642 15 5.625Z" fill="white" />
    </svg>,
    pages: [
      {
        action: "CREATE",
        component: PerfilCadastrar,
        path: "/cadastro-perfil"
      },
      {
        action: "UPDATE",
        component: PerfilCadastrar,
        path: "/editar-perfil/:id"
      },
      {
        action: "LIST",
        component: Perfil,
        path: "/perfis"
      },
      {
        action: "LIST",
        component: PerfilInativos,
        path: "/perfis-inativos"
      },
      {
        action: "LIST",
        component: PerfilVisualizar,
        path: "/visualizar-perfil/:id"
      }
    ]
  },
  {
    key: "Product",
    name: 'Produtos',
    icon: <svg xmlns="http://www.w3.org/2000/svg" stroke="#fff" strokeWidth="0.5" width="25" height="25" viewBox="0 0 64 64" fill="#fff"><g id="Label_tag" data-name="Label tag"><path d="M62,1.001H38.8281a2.9782,2.9782,0,0,0-2.1211.8779L2.708,35.8779a5.8294,5.8294,0,0,0,0,8.2442l17.1694,17.17a5.8382,5.8382,0,0,0,8.2447,0l33.999-33.999A2.98,2.98,0,0,0,63,25.1719V2.001A1,1,0,0,0,62,1.001ZM61,25.1719a.9929.9929,0,0,1-.2925.707L26.708,59.8779a3.8355,3.8355,0,0,1-5.4165,0L4.1221,42.708a3.83,3.83,0,0,1,0-5.416L38.1211,3.293a.9924.9924,0,0,1,.707-.292H61Z" /><path d="M48,23a7,7,0,1,0-7-7A7.0081,7.0081,0,0,0,48,23Zm0-12a5,5,0,1,1-5,5A5.0059,5.0059,0,0,1,48,11Z" /></g></svg>,
    pages: [
      {
        action: "CREATE",
        component: ProdutosCadastrar,
        path: "/cadastro-produto"
      },
      {
        action: "UPDATE",
        component: ProdutosCadastrar,
        path: "/editar-produto/:id"
      },
      {
        action: "LIST",
        component: Produtos,
        path: "/produtos"
      },
      {
        action: "LIST",
        component: ProdutosEmpresa,
        path: "/produtos-por-empresa/:companyId"
      },
      {
        action: "LIST",
        component: ProdutosEmpresaCadastrar,
        path: "/cadastro-produtos-por-empresa/:companyId"
      },
      {
        action: "LIST",
        component: ProdutosEmpresaCadastrar,
        path: "/editar-produtos-por-empresa/:id"
      },
      {
        action: "LIST",
        component: ProdutosInativos,
        path: "/produtos-inativos"
      },
      {
        action: "LIST",
        component: ProdutosVisualizar,
        path: "/visualizar-produtos/:id"
      }
    ]
  },
  {
    key: "Version",
    name: 'Versões',
    parent: 'Product',
    pages: [
      {
        action: "CREATE",
        component: VersoesCadastrar,
        path: "/cadastro-versao"
      },
      {
        action: "UPDATE",
        component: VersoesCadastrar,
        path: "/editar-versao/:id"
      },
      {
        action: "LIST",
        component: Versoes,
        path: "/versoes"
      },
      {
        action: "LIST",
        component: Versoes,
        path: "/versoes-por-produto/:id"
      },
      {
        action: "LIST",
        component: VersoesVisualizar,
        path: "/visualizar-versoes/:id"
      },
    ]
  },
  {
    key: "Alert",
    name: 'Alertas',
    icon: <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="#fff"
      width="25px" height="25px" viewBox="0 0 90 90">
      <path d="M89.048,75.896L53.694,20.714c-2.035-3.174-5.362-3.174-7.4,0L10.951,75.896c-2.031,3.17-0.697,5.771,2.968,5.771H86.08
      C89.745,81.667,91.083,79.065,89.048,75.896z M19.446,75l30.547-47.701L80.556,75H19.446z"/>
      <rect x="46.666" y="63.725" width="5.667" height="6.666" />
      <rect x="46.666" y="42.058" width="5.667" height="16.667" />
    </svg>,
    pages: [
      {
        action: "CREATE",
        component: AlertasCadastrar,
        path: "/cadastro-alerta"
      },
      {
        action: "UPDATE",
        component: AlertasCadastrar,
        path: "/editar-alerta/:id"
      },
      {
        action: "LIST",
        component: Alertas,
        path: "/alertas"
      },
      {
        action: "LIST",
        component: AlertasInativos,
        path: "/alertas-inativos"
      },
      {
        action: "LIST",
        component: AlertaVisualizar,
        path: "/visualizar-alertas/:id"
      }
    ]
  },
  {
    key: "AlertLevel",
    name: 'Níveis de Alerta',
    parent: 'Alert',
    pages: [
      {
        action: "CREATE",
        component: NiveisAlertaCadastrar,
        path: "/cadastro-niveis-alerta"
      },
      {
        action: "UPDATE",
        component: NiveisAlertaCadastrar,
        path: "/editar-niveis-alerta/:id"
      },
      {
        action: "LIST",
        component: NiveisAlerta,
        path: "/niveis-alerta"
      },
      {
        action: "LIST",
        component: NiveisAlertaInativos,
        path: "/niveis-alerta-inativos"
      },
      {
        action: "LIST",
        component: NiveisAlertaVisualizar,
        path: "/visualizar-niveis-alerta/:id"
      }
    ]
  },
  {
    key: "AlertType",
    name: 'Tipos de alerta',
    parent: 'Alert',
    pages: [
      {
        action: "CREATE",
        component: TiposAlertaCadastrar,
        path: "/cadastro-tipo-alerta"
      },
      {
        action: "UPDATE",
        component: TiposAlertaCadastrar,
        path: "/editar-tipo-alerta/:id"
      },
      {
        action: "LIST",
        component: TiposAlerta,
        path: "/tipos-alerta"
      },
      {
        action: "LIST",
        component: TiposAlertaInativos,
        path: "/tipos-alerta-inativos"
      },
      {
        action: "LIST",
        component: TiposAlertaVisualizar,
        path: "/visualizar-tipo-alerta/:id"
      }
    ]
  },
  {
    key: "Server",
    name: 'Servidores',
    icon: <svg xmlns="http://www.w3.org/2000/svg" stroke="#fff" strokeWidth="1" height="32" width="32" fill="#fff" viewBox="0 0 74 74"><g id="Cloud"><path d="M52.9878,25.542c.0078-.18.0122-.36.0122-.542a15.9991,15.9991,0,0,0-28.85-9.5317A10.8731,10.8731,0,0,0,21,15,11.0127,11.0127,0,0,0,10.001,25.8628,14.9969,14.9969,0,0,0,15,55H49a14.9987,14.9987,0,0,0,3.9878-29.458ZM49,53H15a12.9971,12.9971,0,0,1-3.6875-25.4609,1.0006,1.0006,0,0,0,.7153-1.0171l-.0112-.1695C12.0088,26.2358,12,26.1191,12,26a8.98,8.98,0,0,1,12.1816-8.41.9992.9992,0,0,0,1.1851-.3779A13.9995,13.9995,0,0,1,51,25c0,.4141-.0273.8218-.0625,1.2271a1,1,0,0,0,.7876,1.0644A12.9985,12.9985,0,0,1,49,53Z" /></g></svg>,
    pages: [
      {
        action: "CREATE",
        component: ServidoresCadastrar,
        path: "/cadastro-servidor"
      },
      {
        action: "UPDATE",
        component: ServidoresCadastrar,
        path: "/editar-servidor/:id"
      },
      {
        action: "LIST",
        component: Servidores,
        path: "/servidores"
      },
      {
        action: "LIST",
        component: ServidoresInativos,
        path: "/servidores-inativos"
      },
      {
        action: "LIST",
        component: ServidorVisualizar,
        path: "/visualizar-servidores/:id"
      }
    ]
  },
  {
    key: "Cluster",
    name: 'Clusters',
    parent: 'Server',
    pages: [
      {
        action: "CREATE",
        component: ClustersCadastrar,
        path: "/cadastro-cluster"
      },
      {
        action: "UPDATE",
        component: ClustersCadastrar,
        path: "/editar-cluster/:id"
      },
      {
        action: "LIST",
        component: Clusters,
        path: "/clusters"
      },
      {
        action: "LIST",
        component: ClustersInativos,
        path: "/clusters-inativos"
      },
      {
        action: "LIST",
        component: ClustersVisualizar,
        path: "/visualizar-clusters/:id"
      }
    ]
  },
  {
    key: "WarningType",
    name: 'Avisos',
    parent: 'Alert',
    pages: [
      {
        action: "CREATE",
        component: AvisoCadastrar,
        path: "/cadastro-aviso"
      },
      {
        action: "UPDATE",
        component: AvisoCadastrar,
        path: "/editar-aviso/:id"
      },
      {
        action: "LIST",
        component: Avisos,
        path: "/avisos"
      },
      {
        action: "LIST",
        component: AvisosInativos,
        path: "/avisos-inativos"
      },
      {
        action: "LIST",
        component: AvisoVisualizar,
        path: "/visualizar-avisos/:id"
      }
    ]
  },
  {
    key: "Service",
    name: 'Serviços',
    icon: <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 1507.87 1376"><title>Ativo 1</title><g id="Camada_2" data-name="Camada 2"><g id="Camada_1-2" data-name="Camada 1"><polygon id="_407096032" data-name=" 407096032" fill="#fff" points="192.1 899.93 192.1 31.26 192.1 0 224.11 0 1259.95 0 1259.95 62.52 256.11 62.52 256.11 902.89 404.61 755.75 450.11 799.46 248.71 999.02 226.1 1021.42 203.33 999.14 0 800.22 45.25 756.26 192.1 899.93" /><polygon id="_407095960" data-name=" 407095960" fill="#fff" points="1462.62 370.57 1315.76 230.48 1315.76 1345.52 1315.76 1376 1283.76 1376 220.98 1376 188.98 1376 188.98 1345.52 188.98 1071.77 252.98 1071.77 252.98 1315.04 1251.76 1315.04 1251.76 227.6 1103.26 371.07 1057.76 328.45 1259.16 133.87 1281.77 112.03 1304.54 133.75 1507.87 327.71 1462.62 370.57" /><path id="_407095888" data-name=" 407095888" fill="#fff" d="M754.63,906.77V727q-85.21-24.18-125-72.82c-26.41-32.52-39.76-71.94-39.76-118.23q0-70.32,44.95-118.23,45.16-47.91,119.86-55.24V320h62.9v42.49c46.14,5.57,82.78,20.95,110.07,46.59S972.11,469,979.67,511.93L869.75,526.14q-10-50.76-52.22-68.71V625.18Q921.9,653.1,959.65,697.56t37.82,114q0,77.58-47.47,130.83-47.61,53.42-132.47,65.35V1088h-62.9v-78.23q-75.21-9-122.23-55.38-46.95-46.15-59.93-130.84l113.48-12c4.6,22.86,13.35,42.64,26.11,59.19s26.85,28.57,42.57,36Zm0-450.66a82.19,82.19,0,0,0-40.94,29.16,75.45,75.45,0,0,0-15.13,45.56,71.47,71.47,0,0,0,13.8,42.2q14,19.56,42.27,31.64V456.11Zm62.9,456.67c21.81-4,39.46-13.92,53.11-29.74s20.47-34.58,20.47-56.11c0-19.2-5.79-35.61-17.21-49.53s-30.26-24.61-56.37-31.93V912.78Z" /></g></g></svg>,
    pages: [
      {
        action: "CREATE",
        component: ServicosCadastrar,
        path: "/cadastro-servico"
      },
      {
        action: "UPDATE",
        component: ServicosCadastrar,
        path: "/editar-servico/:id"
      },
      {
        action: "LIST",
        component: Servicos,
        path: "/servicos"
      },
      {
        action: "LIST",
        component: ServicosInativos,
        path: "/servicos-inativos"
      },
      {
        action: "READ",
        component: ServicosVisualizar,
        path: "/visualizar-servicos/:id"
      },

    ]
  },
  {
    key: "Quote",
    name: 'Solicitações',
    icon: <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.1898 4.63553L20.5601 2.12708V22.093V22.6388H19.9871H0.956905H0.383911V22.093V17.1913H1.5299V21.5473H19.4141V2.07551L16.7551 4.64449L15.9403 3.88133L19.5466 0.397172L19.9515 0.00610352L20.3592 0.395023L24 3.86808L23.1898 4.63553Z" fill="white" />
      <path d="M11.5 2H4.7C4.24913 2 3.81673 2.17911 3.49792 2.49792C3.17911 2.81673 3 3.24913 3 3.7V17.3C3 17.7508 3.17911 18.1832 3.49792 18.502C3.81673 18.8209 4.24913 19 4.7 19H14.9C15.3508 19 15.7832 18.8209 16.1021 18.502C16.4209 18.1832 16.6 17.7508 16.6 17.3V7.09999L11.5 2Z" stroke="#FEFBFB" />
      <path d="M11.5 2V7.09999H16.6" stroke="#FEFBFB" />
      <path d="M13.2 11.3501H6.40002" stroke="#FEFBFB" />
      <path d="M13.2 14.7502H6.40002" stroke="#FEFBFB" />
      <path d="M8.10002 7.94995H7.25002H6.40002" stroke="#FEFBFB" />
      <path d="M11.5 2V7.09999H16.6" stroke="#FEFBFB" />
      <path d="M13.2 11.3501H6.40002" stroke="#FEFBFB" />
      <path d="M13.2 14.7502H6.40002" stroke="#FEFBFB" />
      <path d="M8.10002 7.94995H7.25002H6.40002" stroke="#FEFBFB" />
    </svg>
    ,
    pages: [
      {
        action: "CREATE",
        component: SolicitacoesCadastrar,
        path: "/cadastro-solicitacao"
      },
      {
        action: "UPDATE",
        component: SolicitacoesCadastrar,
        path: "/editar-solicitacao/:id"
      },
      {
        action: "READ",
        component: SolicitacoesCadastrar,
        path: "/visualizar-solicitacoes/:id"
      },
      {
        action: "LIST",
        component: Solicitacoes,
        path: "/solicitacoes"
      },
      {
        action: "LIST",
        component: SolicitacoesInativos,
        path: "/solicitacoes-inativos"
      }
    ]
  },
];



let routes: { component: () => JSX.Element, path: string }[] = [];
let menu: { title: string, link: string, icon: JSX.Element, submenu?: { title: string, link: string }[] }[] = [
  {
    title: 'Home',
    link: '/home',
    icon:
      <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.7525 8.77766L15.2212 0.992937C15.1532 0.956425 15.0771 0.937317 14.9999 0.937317C14.9227 0.937317 14.8466 0.956425 14.7785 0.992937L0.2473 8.77766C0.137752 8.83638 0.0560101 8.9362 0.0200489 9.05518C-0.0159122 9.17415 -0.00314818 9.30254 0.0555343 9.41211L1.82591 12.7181C1.85484 12.7724 1.89425 12.8205 1.94186 12.8596C1.98948 12.8986 2.04435 12.9279 2.10332 12.9456C2.22234 12.9812 2.3506 12.9686 2.46036 12.9103L14.9999 6.19264L27.5394 12.9103C27.5937 12.9394 27.6532 12.9574 27.7144 12.9635C27.7757 12.9696 27.8375 12.9635 27.8965 12.9457C27.9554 12.9278 28.0102 12.8985 28.0578 12.8595C28.1054 12.8205 28.1448 12.7724 28.1739 12.7182L29.9443 9.4122C30.003 9.30263 30.0157 9.17421 29.9798 9.05521C29.9438 8.93622 29.8621 8.83638 29.7525 8.77766ZM27.569 11.8625L15.2212 5.24736C15.1531 5.21101 15.0771 5.192 14.9999 5.192C14.9227 5.192 14.8467 5.21101 14.7785 5.24736L2.43083 11.8625L1.1031 9.38281L14.9999 1.93822L28.8967 9.38281L27.569 11.8625Z" fill="white" />
        <path d="M25.3125 12.8435C25.1882 12.8435 25.069 12.8929 24.981 12.9808C24.8931 13.0687 24.8438 13.1879 24.8438 13.3123V24.8438H19.2188V16.4063C19.2188 16.282 19.1694 16.1627 19.0815 16.0748C18.9935 15.9869 18.8743 15.9375 18.75 15.9375H11.25C11.1257 15.9375 11.0065 15.9869 10.9185 16.0748C10.8306 16.1627 10.7812 16.282 10.7812 16.4063V24.8438H5.15625V13.3123C5.15625 13.1879 5.10686 13.0687 5.01896 12.9808C4.93105 12.8929 4.81182 12.8435 4.6875 12.8435C4.56318 12.8435 4.44395 12.8929 4.35604 12.9808C4.26814 13.0687 4.21875 13.1879 4.21875 13.3123V27.6563C4.21875 27.7806 4.26814 27.8998 4.35604 27.9877C4.44395 28.0756 4.56318 28.125 4.6875 28.125H25.3125C25.4368 28.125 25.556 28.0756 25.644 27.9877C25.7319 27.8998 25.7812 27.7806 25.7812 27.6563V13.3123C25.7812 13.1879 25.7319 13.0687 25.644 12.9808C25.556 12.8929 25.4368 12.8435 25.3125 12.8435ZM5.15625 25.7813H10.7812V27.1875H5.15625V25.7813ZM11.7188 16.875H18.2812V27.1875H11.7188V16.875ZM24.8438 27.1875H19.2188V25.7813H24.8438V27.1875Z" fill="white" />
        <path d="M17.3438 21.0938C17.2194 21.0938 17.1002 21.1431 17.0123 21.231C16.9244 21.319 16.875 21.4382 16.875 21.5625V22.0312C16.875 22.1556 16.9244 22.2748 17.0123 22.3627C17.1002 22.4506 17.2194 22.5 17.3438 22.5C17.4681 22.5 17.5873 22.4506 17.6752 22.3627C17.7631 22.2748 17.8125 22.1556 17.8125 22.0312V21.5625C17.8125 21.4382 17.7631 21.319 17.6752 21.231C17.5873 21.1431 17.4681 21.0938 17.3438 21.0938Z" fill="white" />
      </svg>,
  },
];

const aclString = localStorage.getItem('acl');
const acl: { resource: Resource, action: Action[] | '*' }[] = JSON.parse(aclString!);
const extractComponentRoute = page => ({ component: page.component, path: page.path });

if (aclString) {
  routes = resources.map(resource => {
    const aclItemFound = acl.find(aclItem => aclItem.resource === resource.key);
    if (aclItemFound) {
      if (aclItemFound.action === '*') {
        return resource.pages.map(extractComponentRoute);
      }
      return resource.pages
        .filter(page => aclItemFound.action.includes(page.action))
        .map(extractComponentRoute);
    }
    return [];
  }).flat();
  menu = menu.concat(resources.filter(resource => resource.name && !resource.parent)
    .filter(resource => {
      const aclItemFound = acl.find(aclItem => aclItem.resource === resource.key);
      return aclItemFound && resource.pages.find(page => page.action === 'LIST') && resource.pages.find(page => page.action === 'CREATE');
    }).map(resource => {
      const aclItemFound = acl.find(aclItem => aclItem.resource === resource.key)!;
      const submenuResources = resources.filter(innerResource => innerResource.parent === resource.key);
      let menuItem: any;
      if (aclItemFound.action === '*' || aclItemFound.action.includes('LIST')) {
        menuItem = {
          title: resource.name!,
          link: resource.pages.find(page => page.action === 'LIST')?.path!,
          icon: resource.icon!,
        };
      } else {
        if (aclItemFound.action.includes('CREATE'))
          menuItem = {
            title: resource.name!,
            link: resource.pages.find(page => page.action === 'CREATE')?.path!,
            icon: resource.icon!,
          };
      }
      if (submenuResources.length > 0 && menuItem) {
        menuItem.submenu = submenuResources.filter(submenuResource => {
          const submenuAclItemFound = acl.find(aclItem => aclItem.resource === submenuResource.key);
          return submenuAclItemFound && submenuResource.pages.find(page => page.action === 'LIST') && submenuResource.pages.find(page => page.action === 'CREATE');
        }).map(submenuResource => {
          const submenuAclItemFound = acl.find(aclItem => aclItem.resource === submenuResource.key)!;
          let submenuItem: { title: string, link: string };
          if (submenuAclItemFound.action === '*' || submenuAclItemFound.action.includes('LIST')) {
            submenuItem = {
              title: submenuResource.name!,
              link: submenuResource.pages.find(page => page.action === 'LIST')?.path!,
            };
          } else {
            submenuItem = {
              title: submenuResource.name!,
              link: submenuResource.pages.find(page => page.action === 'CREATE')?.path!,
            };
          }
          return submenuItem;
        });
      }
      return menuItem;
    }));
}

menu.push({
  title: 'Ajuda',
  link: '/ajuda',
  icon:
    <svg fill="#fff" width="25" height="30" viewBox="0 0 230 260">
      <g>
        <path d="M109.575,0C49.156,0,0.002,49.155,0.002,109.574c0,60.42,49.154,109.576,109.573,109.576s109.573-49.156,109.573-109.576
                C219.148,49.155,169.994,0,109.575,0z M109.575,204.15c-52.148,0-94.573-42.427-94.573-94.576C15.002,57.426,57.427,15,109.575,15
                s94.573,42.426,94.573,94.574C204.148,161.724,161.723,204.15,109.575,204.15z
                M109.576,152.243c-5.738,0-10.406,4.667-10.406,10.403c0,5.743,4.668,10.415,10.406,10.415
                c5.738,0,10.406-4.672,10.406-10.415C119.982,156.91,115.314,152.243,109.576,152.243z M109.576,167.243
                c-2.533,0-4.594-2.063-4.594-4.597c0-2.528,2.061-4.585,4.594-4.585s4.594,2.057,4.594,4.585
                C114.17,165.181,112.109,167.243,109.576,167.243z
                M109.575,46.089c-19.154,0-34.737,15.583-34.737,34.736c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5
                c0-10.883,8.854-19.736,19.737-19.736c10.884,0,19.738,8.854,19.738,19.736c0,10.883-8.854,19.737-19.738,19.737
                c-4.142,0-7.5,3.358-7.5,7.5v27.122c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-20.447c15.56-3.439,27.238-17.333,27.238-33.913
                C144.313,61.672,128.73,46.089,109.575,46.089z"
        />
      </g>
    </svg>,
});

function AdminRoutes(props: AdminRoutesProps) {

  const signed = JSON.parse(localStorage.getItem('admin') || 'false');

  const [device, setDevice] = useState('desktop');

  useEffect(() => {
    if (isMobile) {
      setDevice('mobile');
      if (device === "mobile") {
        menu.push({
          title: 'Logout',
          link: 'home',
          icon:
            <svg width="30" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30.8581 14.6686L26.4987 10.4499C26.4537 10.4063 26.4004 10.3718 26.3416 10.3482C26.2828 10.3246 26.2199 10.3125 26.1562 10.3125C26.0926 10.3125 26.0297 10.3246 25.9709 10.3482C25.9121 10.3718 25.8587 10.4063 25.8138 10.4499L24.3607 11.8561C24.3157 11.8996 24.28 11.9513 24.2556 12.0081C24.2313 12.065 24.2187 12.126 24.2187 12.1875C24.2187 12.2491 24.2313 12.31 24.2556 12.3669C24.28 12.4238 24.3157 12.4754 24.3607 12.5189L25.4713 13.5938H15.5C15.3715 13.5938 15.2483 13.6432 15.1575 13.7311C15.0667 13.819 15.0156 13.9382 15.0156 14.0625C15.0156 14.1868 15.0667 14.3061 15.1575 14.394C15.2483 14.4819 15.3715 14.5313 15.5 14.5313H26.6406C26.7364 14.5312 26.83 14.5037 26.9097 14.4522C26.9893 14.4007 27.0514 14.3275 27.088 14.2419C27.1247 14.1562 27.1343 14.062 27.1156 13.9711C27.0969 13.8802 27.0508 13.7967 26.9831 13.7311L25.3881 12.1875L26.1562 11.4441L29.8307 15L26.1562 18.5559L25.3881 17.8125L26.9831 16.2689C27.0508 16.2034 27.0969 16.1199 27.1156 16.0289C27.1343 15.938 27.1247 15.8438 27.088 15.7581C27.0514 15.6725 26.9893 15.5993 26.9097 15.5478C26.83 15.4963 26.7364 15.4688 26.6406 15.4688H15.5C15.3715 15.4688 15.2483 15.5182 15.1575 15.6061C15.0667 15.694 15.0156 15.8132 15.0156 15.9375C15.0156 16.0618 15.0667 16.1811 15.1575 16.269C15.2483 16.3569 15.3715 16.4063 15.5 16.4063H25.4713L24.3607 17.4811C24.3157 17.5246 24.28 17.5763 24.2556 17.6331C24.2313 17.69 24.2187 17.751 24.2187 17.8125C24.2187 17.8741 24.2313 17.935 24.2556 17.9919C24.28 18.0488 24.3157 18.1004 24.3607 18.1439L25.8138 19.5502C25.8588 19.5937 25.9121 19.6283 25.9709 19.6518C26.0297 19.6754 26.0926 19.6875 26.1562 19.6875C26.2198 19.6875 26.2828 19.6754 26.3416 19.6518C26.4004 19.6283 26.4537 19.5937 26.4987 19.5502L30.8581 15.3314C30.9031 15.2879 30.9388 15.2363 30.9631 15.1794C30.9875 15.1225 31 15.0616 31 15C31 14.9385 30.9875 14.8775 30.9631 14.8206C30.9388 14.7638 30.9031 14.7121 30.8581 14.6686Z" fill="white" />
              <path d="M21.7969 17.3435C21.6684 17.3435 21.5452 17.3929 21.4544 17.4808C21.3635 17.5687 21.3125 17.6879 21.3125 17.8123V26.2498H11.625V3.74977H21.3125V12.1873C21.3125 12.3116 21.3635 12.4308 21.4544 12.5187C21.5452 12.6066 21.6684 12.656 21.7969 12.656C21.9253 12.656 22.0485 12.6066 22.1394 12.5187C22.2302 12.4308 22.2812 12.3116 22.2812 12.1873V3.28102C22.2812 3.1567 22.2302 3.03747 22.1394 2.94956C22.0485 2.86165 21.9253 2.81227 21.7969 2.81227H11.625V0.468516C11.625 0.394339 11.6069 0.321214 11.572 0.255151C11.5372 0.189089 11.4866 0.131975 11.4245 0.0885038C11.3624 0.0450331 11.2905 0.0164468 11.2147 0.00509474C11.139 -0.00625733 11.0616 -5.10733e-05 10.9888 0.0232034L0.332523 3.42942C0.235728 3.46035 0.151482 3.52004 0.0917785 3.59999C0.0320751 3.67994 -4.19323e-05 3.77609 4.10885e-08 3.87473V26.1248C-4.19323e-05 26.2234 0.0320751 26.3196 0.0917785 26.3995C0.151482 26.4795 0.235728 26.5392 0.332523 26.5701L10.9888 29.9763C11.0616 29.9996 11.139 30.0058 11.2147 29.9944C11.2905 29.9831 11.3624 29.9545 11.4245 29.911C11.4866 29.8676 11.5372 29.8104 11.572 29.7444C11.6069 29.6783 11.625 29.6052 11.625 29.531V27.1873H21.7969C21.9253 27.1873 22.0485 27.1379 22.1394 27.05C22.2302 26.9621 22.2812 26.8428 22.2812 26.7185V17.8123C22.2812 17.6879 22.2302 17.5687 22.1394 17.4808C22.0485 17.3929 21.9253 17.3435 21.7969 17.3435ZM10.6562 28.8826L0.968749 25.786V4.2135L10.6562 1.11694V28.8826Z" fill="white" />
              <path d="M8.23437 13.5938C7.94697 13.5938 7.66603 13.6762 7.42706 13.8307C7.1881 13.9853 7.00185 14.2049 6.89186 14.4619C6.78188 14.7188 6.7531 15.0016 6.80917 15.2743C6.86524 15.5471 7.00364 15.7977 7.20686 15.9944C7.41008 16.191 7.66901 16.325 7.95088 16.3792C8.23276 16.4335 8.52494 16.4056 8.79046 16.2992C9.05598 16.1928 9.28293 16.0125 9.4426 15.7813C9.60227 15.55 9.6875 15.2781 9.6875 15C9.68707 14.6272 9.53384 14.2697 9.26142 14.0061C8.989 13.7424 8.61964 13.5942 8.23437 13.5938ZM8.23437 15.4687C8.13857 15.4687 8.04493 15.4413 7.96527 15.3898C7.88562 15.3382 7.82353 15.265 7.78687 15.1794C7.75021 15.0937 7.74062 14.9995 7.75931 14.9086C7.778 14.8176 7.82413 14.7341 7.89187 14.6685C7.95961 14.603 8.04592 14.5583 8.13988 14.5403C8.23384 14.5222 8.33123 14.5315 8.41974 14.5669C8.50824 14.6024 8.58389 14.6625 8.63712 14.7396C8.69034 14.8167 8.71875 14.9073 8.71875 15C8.71863 15.1243 8.66756 15.2434 8.57675 15.3313C8.48594 15.4192 8.3628 15.4686 8.23437 15.4687Z" fill="white" />
            </svg>
          , submenu: []
        });
      }
    }
  }, [device]);



  return (
    <BrowserRouter {...(props.useBasename ? { basename: 'admin' } : {})}>

      <div className="app-container">

        {signed ? (
          <>

            <TopMenu />
            <SideMenu
              device={device}
              options={menu}
            />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/ajuda" component={Ajuda} />
              {routes.map(route => <Route key={`${route.component.name}_${route.path}`} component={route.component} path={route.path} />)}
              <Route component={NotFound} />
            </Switch>
          </>
        ) : (
          <>
            <Switch>
              <Route path="/" exact component={AdminLogin} />
              <Route path="/aprovar-atividades/:token" component={AprovarAtividades} />
              <Route path="/esqueci/" component={Esqueci} />
              <Route path="/nova-senha/:id?" component={NovaSenha} />
              <Route component={NotFound} />
            </Switch>
          </>)}
      </div>

    </BrowserRouter >
  );


}

export default AdminRoutes;