import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';


const useModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    pass: {
      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
  }),
);

export default useModalStyles;