import joinURL from '../utils/joinURL';
import jwt from 'jsonwebtoken';
import Swal from 'sweetalert2';

export class API {
  private baseURL: string;

  constructor(baseURL?: string) {
    this.baseURL = baseURL || process.env.REACT_APP_API_URL || 'http://localhost:8080';
  }

  private async processRequest(verb: string, url: string, data?: { [key: string]: any }, headers?: { [key: string]: string }, useAuth: boolean = true) {
    let fetchHeaders = headers;


    if (useAuth && localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')) {
      fetchHeaders = Object.assign(fetchHeaders || {}, {
        'X-Access-Token': localStorage.getItem('accessToken'),
        'X-Refresh-Token': localStorage.getItem('refreshToken'),
      });
    }
    try {
      const response = await fetch(joinURL(this.baseURL, url), {
        method: verb,
        ...(data ? { body: JSON.stringify(data) } : {}),
        headers: { ...fetchHeaders, 'Content-Type': 'application/json;charset=utf-8' },
        mode: 'cors'
      });

      if (!response.ok) {
        throw response;
      }
      if (response.headers.has('X-Access-Token') && response.headers.has('X-Refresh-Token')) {
        const parsedIncomingAccessToken = jwt.decode(response.headers.get('X-Access-Token')!, { json: true })!;
        const parsedIncomingRefreshToken = jwt.decode(response.headers.get('X-Refresh-Token')!, { json: true })!;
        const parsedStoredAccessToken = jwt.decode(localStorage.getItem('accessToken')!, { json: true })!;
        const parsedStoredRefreshToken = jwt.decode(localStorage.getItem('refreshToken')!, { json: true })!;
        if (
          parsedStoredAccessToken !== undefined && parsedStoredAccessToken !== null
          && parsedStoredRefreshToken !== undefined && parsedStoredRefreshToken !== null
          && new Date(parsedIncomingAccessToken.exp * 1000) > new Date(parsedStoredAccessToken.exp * 1000)
          && new Date(parsedIncomingRefreshToken.exp * 1000) > new Date(parsedStoredRefreshToken.exp * 1000)
        ) {
          localStorage.setItem('accessToken', response.headers.get('X-Access-Token')!);
          localStorage.setItem('refreshToken', response.headers.get('X-Refresh-Token')!);
        }
      }
      return response;
    } catch (error) {
      
      if (error) {
        switch (error.status) {
          case 401:
            const adminSigned = localStorage.getItem('admin');
            const userSigned = localStorage.getItem('user');
            if (adminSigned || userSigned) {
              localStorage.removeItem('admin');
              localStorage.removeItem('user');
              localStorage.removeItem('username');
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              localStorage.removeItem('acl');
              
              window.location.pathname = '/';
            }
            break;
          case 403:
            Swal.fire({
              title: 'Ação não permitida',
              text: 'Seu perfil não tem permissão para acessar esta tela.',
              icon: 'error',
            });
            break;
          case 404:
            Swal.fire({
              title: '404',
              text: 'Não encontrado 😢',
              icon: 'error',
            });
            console.log(error);
            break;
        }
      }

      return error as Response;
    }

  }

  put = (url: string, data?: { [key: string]: any }, headers?: { [key: string]: string }, useAuth: boolean = true) => this.processRequest('PUT', url, data, headers, useAuth);
  get = (url: string, data?: { [key: string]: any }, headers?: { [key: string]: string }, useAuth: boolean = true) => this.processRequest('GET', url, data, headers, useAuth);
  post = (url: string, data?: { [key: string]: any }, headers?: { [key: string]: string }, useAuth: boolean = true) => this.processRequest('POST', url, data, headers, useAuth);
  patch = (url: string, data?: { [key: string]: any }, headers?: { [key: string]: string }, useAuth: boolean = true) => this.processRequest('PATCH', url, data, headers, useAuth);
  delete = (url: string, data?: { [key: string]: any }, headers?: { [key: string]: string }, useAuth: boolean = true) => this.processRequest('DELETE', url, data, headers, useAuth);
}

export default new API();
