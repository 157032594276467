import React from 'react';
import { MetricGraphsCard } from '../../../components/MetricGraphsCard';

function HomeAdmin() {
  return (
    <main>
      <div className="home">
        <div className="grid-box">
          <MetricGraphsCard />
        </div>
      </div>
    </main>
  )
}

export default HomeAdmin;