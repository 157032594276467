import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import { Button,Select,  FormControl, InputLabel } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FilterListIcon } from '@material-ui/data-grid';

import './styles.scss';
import Swal from 'sweetalert2';
import { Action, Resource } from '../../../../utils/aclUtils';
import nodata from '../../../../assets/images/nodata.svg';

import api from '../../../../services/api';
import { Link } from 'react-router-dom';

import jwt from 'jsonwebtoken';
import { SearchIcon } from '@material-ui/data-grid';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    pass: {
      display: 'abolsute',

      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        },
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
);

interface Data {
  registrationDate: string;
  hora: string;
  solicitacao: string;
  type: string;
  status: string;
  options: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'hora', numeric: false, disablePadding: true, label: 'Descrição' },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Natureza'
  },
  {
    id: 'hora',
    numeric: false,
    disablePadding: true,
    label: 'Horas estimadas',
  },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'options', numeric: false, disablePadding: true, label: '' },
];

interface Admin {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
}

interface DataList {
  type: string;
  registrationDate: Date;
  responsable: User;
  orderOrigin: string;
  orderType: string;
  orderSite: string;
  description: string;
  comment: string;
  expectHours: string;
  hourlyRate: string;
  totalValue: string;
  approvalDate: Date;
  approvalResponsable: Admin;
  approvalProtocol: string;
  discount: string;
  grossValue: string;
  netValue: string;
  totalHours: string;
  paidHours: string;
  netHours: string;
  totalServiceValue: string;
  allowance: string;
  allowanceResponsable: Admin;
  status: string;
  enabled: boolean;
}

function Solicitacoes() {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('type');

  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = useState(0);

  const [rows, setRows] = React.useState([]);
  const [rowId, setRowId] = React.useState('');
  const [searchStatus, setSearchStatus] = React.useState('');
  const [searchServices, setSearchServices] = useState("");
 
  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchStatus(event.target.value as string);
  };

  const [display, setDisplay] = React.useState('none');
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleFilter = () => {
    if (display === 'grid') {
      setDisplay('none');
    } else {
      setDisplay('grid');
    }
  };
  const handleChangeTypeService= (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchServices(event.target.value as string);
  };
  
  const handleSetRowId = (rowId) => {
    setRowId(rowId);
  };

  useEffect(() => {
    const getData = async () => {
      const response = await api.get(
        `/recommendation?order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}&orderType=SL-Solicitação`
      );

      if (response.status && response.status.toString()[0] === '2') {
        const data = await response.json();
       
        setRows(data.data);

        setTotalPages(data.total);
      }
    };

    getData();
  }, [orderBy, order, page, rowsPerPage]);

  const handleSearch = async () => {
    const queryParams = new URLSearchParams();
    if (searchServices) {
      queryParams.append('type', searchServices);
    }
    if (searchStatus) {
      queryParams.append('status', searchStatus);
    }
    

    const getData = async () => {
      const response = await api.get(
        `/recommendation?${queryParams.toString()}&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}&orderType=SL-Solicitação`
      );
      if (response.status && response.status.toString()[0] === '2') {
        const data = await response.json();
        setRows(data.data);
        setTotalPages(data.total);
      }
    };
    getData();
  };

  interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (
      event: React.MouseEvent<unknown>
    ) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let aclItem;
  if (localStorage.getItem('acl')) {
    const aclString = localStorage.getItem('acl');
    const acl: { resource: Resource; action: Action[] | '*' }[] = JSON.parse(
      aclString!
    );
    aclItem = acl.find((aclItem) => aclItem.resource === 'Service');
  }
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <div className="homeUser">
        <main>
          <Paper className={classes.paper}>
            <TableContainer>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: 20}}>
                <Typography
                  variant="h6"
                  id="tableTitle"
                  component="div"
                  style={{ padding: 24 }}
                >
                  Solicitações
                </Typography>

                <Tooltip title="Filtro de dados">
                  <IconButton onClick={handleFilter} aria-label="filter">
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </div>

              <div id="filtros" style={{ display: display, transition: "width 2s, height 4s", transitionDuration: "2s" }}>
                <div className="filterField" style={{width:"100%", display: 'flex', alignItems:  'center', justifyContent:  'center'}}>
                  <FormControl aria-atomic>
                          <InputLabel>Natureza</InputLabel>
                          <Select
                            fullWidth={true}
                            id="select"
                            native
                            required
                            value={searchServices}
                            onChange={handleChangeTypeService}
                            label="Natureza*"
                          >
                            <option aria-label="none" value="" />
                            <option value={"1-Configuração"}>Configuração</option>
                            <option value={'2-Implantação'}>Implantação</option>
                            <option value={'3-Treinamento'}>Treinamento</option>
                            <option value={'4-Desenvolvimento'}>Desenvolvimento</option>
                            <option value={'5-Visita Técnica'}>Visita Técnica</option>
                            <option value={'6-Reunião Técnica'}>Reunião Técnica</option> 
                            
                          </Select>
                  </FormControl>

                  <FormControl>
                      <InputLabel>Status</InputLabel>
                      <Select
                        id="select"
                        native
                        required
                        value={searchStatus}
                        onChange={handleChangeStatus}
                        label="Status*"
                      >
                        <option aria-label="none" value="" />
                        <option value={"1-Em Aberto"}>Em Aberto</option>
                        <option value={'2-Firmada'}>Firmada</option>
                        
                      </Select>

                  </FormControl>

                  <Button
                    variant="contained"
                    className="searchButton"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >Pesquisar</Button>
                </div>

              </div>
              
              {totalPages > 0 ? (
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  
                  <TableBody>
                    {rows.map((row: any, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" id={labelId} scope="row">
                            {row.description}
                          </TableCell>
                          <TableCell align="left">{row.type}</TableCell>
                          <TableCell align="left">{row.expectHours}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>

                          <TableCell align="right">
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                            >
                              <Link to={'visualizar-solicitacoes/' + row.id}>
                                <VisibilityIcon />
                              </Link>
                            </IconButton>
  
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                  <div className="no-data">
                    <img src={nodata} className="no-data-svg" alt="Sem dados" />
                    <div>Nenhum registro disponível para exibição</div>
                  </div>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalPages}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </main>
      </div>
    </>
  );
}

export default Solicitacoes;
