import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import './styles.scss';

import Modal from '@material-ui/core/Modal';



function Ajuda() {

  const [open, setOpen] = React.useState(true);
  const history = useHistory();

  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push('/home');
  };

  const modalBody = (
    <div className="ajuda">
      <div className="modal">

        <div>Ainda não há nada por aqui...</div>
      A tela de ajuda ainda não está disponível. (Admin)
    </div>
    </div>
  );



  return (
    <>
      <main>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </main>
    </>
  )
}

export default Ajuda;
