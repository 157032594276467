import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../../../services/api';


import './styles.scss';
import { ImageLoader } from '../../../../components/ImageLoader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
  }),
);

interface ProdutosCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
}

interface Versoes {
  id: string,
  name: string,
  defaultDir: string,
  code: string,
  product: string,
  enabled: true,
}


function ProdutosCadastrar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [execPath, setExecPath] = useState('');
  const [initDir, setinitDir] = useState('');

  const [versions, setVersions] = useState<Versoes[]>([]);
  const [tsplus, setTsPlus] = useState(false);

  const [image, setImage] = useState('');

  const handleImage = (image: string) => {
    setImage(image);
  }

  const clearImage = () => setImage('')

  const [isNew, setIsNew] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/products/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setName(data.name);
            setDescription(data.description);
            setExecPath(data.execPath);
            setinitDir(data.initDir);
            setTsPlus(data.tsplus)
            setImage(data.image);
          }
        };

        getData();

        const getVersoes = async () => {
          const responseVersions = await api.get(`/versions?product=${id}`);
          if (responseVersions.status && responseVersions.status.toString()[0] === '2') {
            const dataVersions = (await responseVersions.json()).data;

            if (dataVersions) {
              setVersions(dataVersions);
            }

          }
        };
        getVersoes();

      }
    }
  }, [id, isNew]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }

      let result;
      if (isNew) {
        result = await api.put("/products", {
          id: id,
          name: name,
          description: description,
          execPath: execPath,
          initDir: initDir,
          tsplus: tsplus,
          enabled: true,
          image: image

        });
      }
      else {
        result = await api.patch("/products/" + id, {
          name: name,
          description: description,
          execPath: execPath,
          initDir: initDir,
          tsplus: tsplus,
          enabled: true,
          image: image
        });
      }
      if (result) {
        if (result.status && result.status.toString()[0] === '2') {
          Swal.fire({
            title: 'Cadastro realizado com sucesso!',
            icon: 'success',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Continuar',
            cancelButtonColor: '#418107',
            cancelButtonText: 'Ver Todos'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(false);
            } else {
              history.push('/produtos');
            }
          });
        }
        else {
          Swal.fire({
            title: 'Não foi possível salvar 😢',
            icon: 'error',
          });
        }
      }

    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Produtos</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid2}>
                      <TextField

                        label="Nome"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                      <TextField

                        label="Descrição"
                        variant="outlined"
                        name="description"
                        value={description}
                        onChange={(e) => { setDescription(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid2}>
                      <TextField

                        label="Caminho de Execução"
                        variant="outlined"
                        required
                        name="execPath"
                        value={execPath}
                        onChange={(e) => { setExecPath(e.target.value) }}
                      />
                      <TextField

                        label="Diretório de Iniciação"
                        variant="outlined"
                        name="initDir"
                        value={initDir}
                        onChange={(e) => { setinitDir(e.target.value) }}
                      />
                    </div>
                    <div className="imageUpload">
                      <ImageLoader
                        withIcon={true}
                        label='Adicione uma imagem para ser a capa do produto'
                        buttonText='Clicando aqui'
                        fileSizeError="A imagem selecionada excede o tamanho permitido (1MB)."
                        fileTypeError="Extensão não suportada."
                        imageSetter={handleImage}
                        singleImage
                        imgExtension={['.jpg', '.png']}
                        maxFileSize={1048576}
                      />
                      {image &&
                        <div className="filesimages">
                          <div>
                            <img src={image} height="150px" alt='icon' />
                          </div>
                          <div>
                            <IconButton className="deleteButton" onClick={clearImage}>
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                      }
                    </div>
                    <div className={classes.button}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tsplus}
                            onChange={(e) => { setTsPlus(!tsplus) }}
                            color="primary"
                            name="tsplus"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Tsplus"
                      />
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/produtos') }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ProdutosCadastrar;
