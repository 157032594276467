import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';
import { Action, Resource } from '../../../../utils/aclUtils';
import { CheckCircleIcon } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      cursor: 'not-allowed ',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '25%',
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);


interface ParamTypes {
  id: string;
}

type ACLTableRow = { resource: Resource, action: Action[] | '*' };

const resourceOptions: { label: string, value: Resource }[] = [
  {
    label: 'Administradores',
    value: 'Admin',
  },
  {
    label: 'Alertas',
    value: 'Alert',
  },
  {
    label: 'Atividades',
    value: 'Activity',
  },
  {
    label: 'Avisos',
    value: 'WarningType',
  },
  {
    label: 'Clientes',
    value: 'Company',
  },
  {
    label: 'Filiais',
    value: 'Branch',
  },
  {
    label: 'Manutenção',
    value: 'Maintenance',
  },
  {
    label: 'Métricas dos Servidores',
    value: 'ServerMetrics',
  },
  {
    label: 'Níveis de alerta',
    value: 'AlertLevel',
  },
  {
    label: 'Perfis',
    value: 'Profile',
  },
  {
    label: 'Produtos',
    value: 'Product',
  },
  {
    label: 'Serviços',
    value: 'Service',
  },
  {
    label: 'Servidores',
    value: 'Server',
  },
  {
    label: 'Clusters',
    value: 'Cluster',
  },
  {
    label: 'Solicitações',
    value: 'Quote',
  },
  {
    label: 'Tipos de alerta',
    value: 'AlertType',
  },

  {
    label: 'Usuários',
    value: 'User',
  },
  {
    label: 'Versão',
    value: 'Version',
  },
];

const actionOptions: { label: string, value: Action }[] = [
  {
    label: 'Criar',
    value: 'CREATE',
  },
  {
    label: 'Ler',
    value: 'READ',
  },
  {
    label: 'Listar',
    value: 'LIST',
  },
  {
    label: 'Atualizar',
    value: 'UPDATE',
  },
  {
    label: 'Excluir',
    value: 'DELETE',
  },
];

const resourceFormatter = (resource: Resource) => resourceOptions.find(resourceOption => resourceOption.value === resource)?.label || resource;
const actionFormatter = (actions: Action[] | '*') => actions === '*' ? 'Todas' : actions.map(action => actionOptions.find(actionOption => actionOption.value === action)?.label || action).join(', ');

function PerfilVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [rows, setRows] = useState<ACLTableRow[]>([]);
  const [isNew, setIsNew] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/profiles/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setName(data.name);
            setRows(data.acl);
          }
        };

        getData();
      }
    }
  }, [id, isNew]);

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Perfil</h1>
                  <form>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <TextField
                        disabled
                        label="Nome"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                    <br></br>
                    <h2>Permissões</h2>
                    <div className={classes.grid}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                          <TableHead className={classes.head}>
                            <TableRow>
                              <TableCell>Recurso</TableCell>
                              <TableCell>Ações</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={classes.body}>
                            {rows.map((row, index) => (
                              <TableRow key={`row-aclitem-${index}`}>
                                <TableCell component="th" scope="row" >
                                  {resourceFormatter(row.resource)}
                                </TableCell>
                                <TableCell>{actionFormatter(row.action)}</TableCell>
                                <TableCell align="right" padding="none">
                                  <IconButton  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/perfis') }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default PerfilVisualizar;