import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';


import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select, Switch, FormControlLabel } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';


import MaskedInput from 'react-text-mask';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../../../services/api';
import { matchPath } from "react-router";

import './styles.scss';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),

        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    pass: {
      display: 'abolsute',

      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface UsuarioCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
  companyid: string;
}

interface Cliente {
  id: string;
  name: string;
  cnpj: string;
}
interface Profile {
  id: string;
  name: string,
  code: string,
}

interface CellMaskProps {
  inputRef: () => void;
}

function CellMask(props: CellMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />

  );
}



interface State {
  cellphone: string;
}

function UsuarioCadastrar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const [isNew, setIsNew] = useState(false);
  const [isCompanyUsers, setIsCompanyUsers] = useState(false);

  const [email, setEmail] = useState('');
  const [movideskId, setMovideskId] = useState('');
  const [status, setStatus] = useState('2');
  const [userTSPlus, setUserTSPlus] = useState('');
  const [passwordTSPlus, setPasswordTSPlus] = useState('');
  const [admin, setAdmin] = useState(false);
  const [values, setValues] = React.useState<State>({
    cellphone: '',
  });

  const [showPassword1, setShowPassword1] = React.useState(false);
  const [passwordIcon1, setPasswordIcon1] = React.useState(<ShowEye />);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [passwordIcon2, setPasswordIcon2] = React.useState(<ShowEye />);

  const [company, setCompany] = React.useState<Cliente | null | undefined>({ id: '', name: '', cnpj: '' });
  const [companiesDB, setCompaniesDB] = React.useState<Cliente[]>([]);

  const [profile, setProfile] = React.useState<Profile | null | undefined>({ id: '', name: '', code: '' });
  const [profiles, setProfiles] = React.useState<Profile[]>([]);
  const [isRead, setIsRead] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  }

  const classes = useStyles();
  const history = useHistory();

  const { pathname } = useLocation()

  const match = matchPath(pathname, {
    path: "/editar-usuario/:id?",
    exact: true,
    strict: false
  });


  if (!id) {
    if (params.id) {
      setId(params.id);
      if (match?.url) 
        setIsRead(true)
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  function handlePassword1() {
    setShowPassword1(!showPassword1);
    if (showPassword1) {
      setPasswordIcon1(<ShowEye />);
    } else {
      setPasswordIcon1(<HideEye />);
    }
  }
  function handlePassword2() {
    setShowPassword2(!showPassword2);
    if (showPassword2) {
      setPasswordIcon2(<ShowEye />);
    } else {
      setPasswordIcon2(<HideEye />);
    }
  }

  useEffect(() => {
    const getCompanies = async () => {
      const response = await api.get('/companies?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setCompaniesDB((await response.json()).data);
      }
    };
    getCompanies();

    if (params.companyid) {

      const getMainCompanies = async () => {
        const response = await api.get(`/companies/${params.companyid}`);
        if (response.status && response.status.toString()[0] === '2') {
          const data = await response.json()
          if (params.companyid) {
            setIsCompanyUsers(true)
            setCompany(data)

          }

        }
      };
      getMainCompanies();
    }

    const getProfiles = async () => {
      const response = await api.get('/profiles?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setProfiles((await response.json()).data);
      }
    };
    getProfiles();

    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/users/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setValues(values => ({
              ...values,
              cellphone: data.cellphone,
            }));
            setName(data.name);
            setLogin(data.login);
            setPassword(data.password);
            setMovideskId(data.movideskId);
            setEmail(data.email);
            setStatus(data.status);
            setUserTSPlus(data.userTSPlus);
            setAdmin(data.admin);
            setCompany(data.company);
            setProfile(data.profile);
            setPasswordTSPlus(data.passwordTSPlus);
          }
        };

        getData();
      }
    }
  }, [id, isNew,]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }

      if (values.cellphone.replace(/ /g, '').length > 5) {
        if (values.cellphone.replace(/ /g, '').length < 16) {
          Swal.fire({
            title: 'Celular inválido',
            text: 'Preencha todos caracteres',
            icon: 'error',
          });
          return;
        }
      }

      let result;
      if (isNew) {
        result = await api.put("/users", {
          id: id,
          login: login,
          password: password,
          name: name,
          email: email,
          movideskId: movideskId,
          status: Number(status),
          cellphone: values.cellphone,
          userTSPlus: userTSPlus,
          passwordTSPlus: passwordTSPlus,
          admin: admin,
          company: { id: company?.id },
          profile: { id: profile?.id },
          enabled: true,
        });
      }
      else {
        result = await api.patch("/users/" + id, {
          login: login,
          name: name,
          email: email,
          movideskId: movideskId,
          status: Number(status),
          cellphone: values.cellphone,
          userTSPlus: userTSPlus,
          passwordTSPlus: passwordTSPlus,
          admin: admin,
          company: { id: company?.id },
          profile: { id: profile?.id },
          enabled: true,
        });
      }


      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/usuarios');
          }
        });
      }
      else {
        if (result.status === 409) {
          const errorText = await result.text();
          if (errorText === 'E_USER_LOGIN') {
            Swal.fire({
              title: 'Login já existente',
              icon: 'warning',
            });
            return;
          }
          if (errorText === 'E_USER_LOGIN_TSPLUS') {
            Swal.fire({
              title: 'Login TSPlus já existente',
              icon: 'warning',
            });
            return;
          }
          if (errorText === 'E_USER_EMAIL') {
            Swal.fire({
              title: 'Email já existente',
              icon: 'warning',
            });
            return;
          }
        }
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Usuário</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Status</InputLabel>
                        <Select
                          required
                          id="select"
                          native
                          value={status}
                          onChange={handleChangeStatus}
                          label="Status"

                        >
                          <option aria-label="None" value="" />
                          <option value={2}>Ativo</option>
                          <option value={4}>Inativo</option>
                          <option value={1}>Pendente</option>
                          <option value={3}>Suspenso</option>
                        </Select>

                      </FormControl>
                      <Autocomplete
                        aria-required
                        id="controllable-states-demo"
                        value={profile}
                        getOptionLabel={(profile) => profile.name}
                        options={profiles}
                        onChange={(event, value) => { setProfile(value) }}
                        renderInput={(params) => <TextField {...params} label="Perfil" required variant="outlined" />}
                      />
                      <TextField
                        label="ID Movidesk"
                        variant="outlined"
                        name="movideskId"
                        value={movideskId}
                        onChange={(e) => { setMovideskId(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      {
                        isCompanyUsers ?
                          <TextField
                            label="Cliente"
                            variant="outlined"
                            required
                            name="company"
                            value={company?.name}
                            disabled
                          />
                          :
                          <Autocomplete
                        aria-required
                        id="controllable-states-demo"
                            disabled={isRead}
                        value={company}
                        getOptionLabel={(company) => company.name}
                        options={companiesDB}
                        onChange={(event, value) => { setCompany(value) }}
                        renderInput={(params) => <TextField {...params} label="Cliente" required variant="outlined" />}
                      />
                      }
                      <TextField

                        label="Nome"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField

                        label="Login"
                        variant="outlined"
                        required
                        name="login"
                        value={login}
                        onChange={(e) => { setLogin(e.target.value) }}
                      />
                      {isNew ? (
                        <>
                          <div className="userPass">
                            <div className={classes.pass}>
                              <TextField
                                type={showPassword1 ? 'text' : 'password'}

                                label="Senha"
                                variant="outlined"
                                required
                                name="password"
                                onChange={(e) => { setPassword(e.target.value) }}
                              />
                              <div className="container-pass">
                                <span className="passMUI" onClick={(e) => { handlePassword1() }}>{passwordIcon1}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (<></>)}
                      <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                      <TextField
                        label="Celular"
                        variant="outlined"
                        name="cellphone"
                        InputProps={{
                          inputComponent: CellMask as any,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={values.cellphone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField

                        label="Login TSPlus"
                        variant="outlined"
                        required
                        name="userTSPlus"
                        value={userTSPlus}
                        onChange={(e) => { setUserTSPlus(e.target.value) }}
                      />

                      <div className={classes.pass}>
                        <TextField
                          type={showPassword2 ? 'text' : 'password'}

                          label="Senha TSPlus"
                          variant="outlined"
                          required
                          name="passwordTSPlus"
                          value={passwordTSPlus}
                          onChange={(e) => { setPasswordTSPlus(e.target.value) }}
                        />
                        <div className="container-pass">
                          <span className="passMUI" onClick={(e) => { handlePassword2() }}>{passwordIcon2}</span>
                        </div>
                      </div>

                    </div>
                    <div className={classes.button}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={admin}
                            onChange={(e) => { setAdmin(!admin) }}
                            color="primary"
                            name="admin"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Admin"
                      />
                    </div>



                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.goBack() }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default UsuarioCadastrar;
