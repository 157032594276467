import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select, Switch } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';

import MaskedInput from 'react-text-mask';

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import api from '../../../../services/api';

import { IBGE, UF } from '../../../../services/ibge';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import './styles.scss';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid4: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '25%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);


interface ClienteCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
}

interface Product {
  id: string;
  name: string;
}

interface PhoneMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function PhoneMask(props: PhoneMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface CellMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CellMask(props: CellMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface CnpjMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CnpjMask(props: CnpjMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface CpfMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function CpfMask(props: CpfMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface State {
  cnpj: string;
  cpf: string;
  phone: string;
  cellphone: string;
}
interface Versions {
  id: string,
  name: string,
  code: string,
  product: { id: string, name: string },
  enabled: true,
}
interface Cluster {
  id: string;
  name: string,
}

function ClienteCadastrar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [movideskId, setMovideskId] = useState('');

  const [contactEmail, setContactEmail] = useState('');
  const [financeEmail, setFinanceEmail] = useState('');
  const [boardEmail, setBoardEmail] = useState('');
  const [pontoFocal, setPontoFocal] = useState('');
  const [status, setStatus] = useState('2');

  const [isNew, setIsNew] = useState(false);
  const [radioValue, setRadioValue] = useState('cnpj');

  const [ufs, setUFs] = useState<UF[]>([]);
  const [state, setState] = useState<UF | null>({ name: '', initials: '' });

  const [cities, setCities] = useState<string[]>([]);
  const [city, setCity] = useState<string | null>("");
  const [allowedIP, setAllowedIP] = useState<string | null>("");

  const [ipEnabled, setIpEnabled] = useState(false);

  const [values, setValues] = React.useState<State>({
    cnpj: '',
    cpf: '',
    phone: '',
    cellphone: '',
  });

  const [cluster, setCluster] = React.useState<Cluster | null | undefined>({ id: '', name: '' });
  const [clusters, setClusters] = React.useState<Cluster[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueRadio = (event.target as HTMLInputElement).value;

    if (valueRadio === 'cnpj') {
      setRadioValue('cnpj');
    }
    if (valueRadio === 'cpf') {
      setRadioValue('cpf');

    }
    if (valueRadio === 'foreign') {
      setRadioValue('foreign')

    }

  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };



  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    setCities([]);
    if (state && state.initials) {
      new IBGE().getMunicipios(state.initials).then(cities => {
        setCities(cities);
      });
    }

  }, [state]);


  useEffect(() => {
    const getUF = async () => {
      new IBGE().getEstados().then(ufs => {
        setUFs(ufs);
      })
    }
    getUF();

    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/companies/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setValues(values => ({
              ...values,
              cnpj: data.cnpj,
              cpf: data.cpf,
              phone: data.telephone,
              cellphone: data.cellphone,
            }));
            setName(data.name);
            setCompanyName(data.companyName);
            setMovideskId(data.movideskId);
            setCity(data.city);
            setState({ name: data.state, initials: data.state });
            setContactEmail(data.contactEmail);
            setFinanceEmail(data.financeEmail);
            setBoardEmail(data.boardEmail);
            setPontoFocal(data.focalPoint)
            setStatus(data.status);
            setAllowedIP(data.allowedIP)
            setIpEnabled(data.ipEnabled);
            setCluster(data.cluster);

            if (data.cpf !== undefined && data.cnpj !== undefined) {
              if (data.cpf.length > 1) {
                setRadioValue('cpf');
              }
              else {
                if (data.cnpj.length > 1) {
                  setRadioValue('cnpj');
                }
                else {
                  setRadioValue('foreign');
                }
              }
            }
          };
        }

        getData();



      }
    }

    const getClusters = async () => {
      const response = await api.get('/clusters?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setClusters((await response.json()).data);
      }
    };
    getClusters();
  }, [id, isNew]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }

      switch (radioValue) {
        case 'cnpj':
          values.cpf = "";
          if (values.cnpj.replace(/ /g, '').length < 18) {
            Swal.fire({
              title: 'CNPJ inválido',
              text: 'Preencha todos caracteres',
              icon: 'error',
            });
            return;
          }
          const cnpjUnformatted = values.cnpj.replace(/\./g, '').replace('/','').replace('-','');
          const cnpjIsValid = cnpj.isValid(cnpjUnformatted);
          if(!cnpjIsValid){
            Swal.fire({
              title: 'CNPJ inválido',
              text: 'Verifique os digitos',
              icon: 'error',
            });
            return;
          }
          break;
        case 'cpf':
          values.cnpj = "";
          if (values.cpf.replace(/ /g, '').length < 14) {
            Swal.fire({
              title: 'CPF inválido',
              text: 'Preencha todos caracteres',
              icon: 'error',
            });
            return;
          }
          const cpfUnformatted = values.cpf.replace(/\./g, '').replace('-','');
          const cpfIsValid = cpf.isValid(cpfUnformatted);
          if(!cpfIsValid){
            Swal.fire({
              title: 'CPF inválido',
              text: 'Verifique os digitos',
              icon: 'error',
            });
            return;
          }
          break;
        case 'foreign':
          values.cpf = "";
          values.cnpj = "";
          break;
      }

      if (values.phone.replace(/ /g, '').length > 4) {
        if (values.phone.replace(/ /g, '').length < 14) {
          Swal.fire({
            title: 'Telefone inválido',
            text: 'Preencha todos caracteres',
            icon: 'error',
          });
          return;
        }
      }

      if (values.cellphone.replace(/ /g, '').length > 5) {
        if (values.cellphone.replace(/ /g, '').length < 16) {
          Swal.fire({
            title: 'Celular inválido',
            text: 'Preencha todos caracteres',
            icon: 'error',
          });
          return;
        }
      }


      let result;
      if (isNew) {
        result = await api.put("/companies", {
          id: id,
          name: name,
          companyName: companyName,
          movideskId: movideskId,
          cnpj: values.cnpj,
          cpf: values.cpf,
          city: city,
          state: state?.initials,
          telephone: values.phone,
          cellphone: values.cellphone,
          contactEmail: contactEmail,
          financeEmail: financeEmail,
          boardEmail: boardEmail,
          focalPoint: pontoFocal,
          ipEnabled: ipEnabled,
          allowedIP: allowedIP,
          status: Number(status),
          enabled: true,
          cluster: cluster,
        });
      }
      else {
        result = await api.patch("/companies/" + id, {
          name: name,
          companyName: companyName,
          movideskId: movideskId,
          cnpj: values.cnpj,
          cpf: values.cpf,
          city: city,
          state: state?.initials,
          telephone: values.phone,
          cellphone: values.cellphone,
          contactEmail: contactEmail,
          financeEmail: financeEmail,
          boardEmail: boardEmail,
          focalPoint: pontoFocal,
          allowedIP: allowedIP,
          ipEnabled: ipEnabled,
          status: Number(status),
          enabled: true,
          cluster: cluster,
        });
      }

      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          } else {
            history.push('/clientes');
          }
        });
      }
      else {
        if (result.status === 409) {
          const errorText = await result.text();
          if (errorText === 'E_COMPANY_CNPJ') {
            Swal.fire({
              title: 'CNPJ já cadastrado',
              icon: 'warning',
            });
            return;
          }
          if (errorText === 'E_COMPANY_CPF') {
            Swal.fire({
              title: 'CPF já cadastrado',
              icon: 'warning',
            });
            return;
          }
        }
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        icon: 'error',
      });
    }

  }


  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Cliente</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <FormControl className="radioButton">
                        <RadioGroup row aria-label="doc" name="doc" value={radioValue} onChange={handleRadioChange}>
                          <FormControlLabel value="cnpj" control={<Radio />} label="Pessoa Jurídica" disabled={(isNew) ? false : true} />
                          <FormControlLabel value="cpf" control={<Radio />} label="Pessoa Física" disabled={(isNew) ? false : true} />
                          <FormControlLabel value="foreign" control={<Radio />} label="Estrangeiro" disabled={(isNew) ? false : true} />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className={classes.grid}>

                      {radioValue === 'cnpj' && (
                        <TextField

                          label="CNPJ"
                          variant="outlined"
                          required
                          name="cnpj"
                          InputProps={{
                            inputComponent: CnpjMask as any,
                          }}
                          InputLabelProps={{ shrink: true }}
                          value={values.cnpj}
                          onChange={handleChange}
                        />
                      )}
                      {radioValue === 'cpf' && (
                        <TextField

                          label="CPF"
                          variant="outlined"
                          required
                          name="cpf"
                          InputProps={{
                            inputComponent: CpfMask as any,
                          }}
                          InputLabelProps={{ shrink: true }}
                          value={values.cpf}
                          onChange={handleChange}
                        />
                      )}
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Status*</InputLabel>
                        <Select

                          id="select"
                          native
                          required
                          value={status}
                          onChange={handleChangeStatus}
                          label="Status*"
                        >
                          <option aria-label="None" value="" />
                          <option value={2}>Ativo</option>
                          <option value={4}>Inativo</option>
                          <option value={1}>Pendente</option>
                          <option value={3}>Suspenso</option>
                        </Select>

                      </FormControl>
                      <TextField
                        label="ID Movidesk"
                        variant="outlined"
                        name="movideskId"
                        value={movideskId}
                        onChange={(e) => { setMovideskId(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      {radioValue === 'cnpj' ? (
                        <>
                          <TextField

                            label="Nome Fantasia"
                            variant="outlined"
                            required
                            name="name"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                          />
                          <TextField

                            label="Razão Social"
                            variant="outlined"
                            required
                            name="companyName"
                            value={companyName}
                            onChange={(e) => { setCompanyName(e.target.value) }}
                          />
                        </>
                      ) : (
                          <TextField

                            label="Nome"
                            variant="outlined"
                            required
                            name="name"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                          />
                        )}

                    </div>

                    <div className={classes.grid}>
                      {(radioValue === 'cnpj' || radioValue === 'cpf') ? (
                        <>
                          <Autocomplete

                            id="controllable-states-demo"
                            value={state}
                            getOptionLabel={(uf) => uf.initials}
                            options={ufs}
                            onChange={(event, value) => { setState(value); setCity(null) }}
                            renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" />}
                          />
                          <Autocomplete

                            id="controllable-states-demo"
                            value={city}
                            getOptionLabel={(city) => city}
                            options={cities}
                            onChange={(event, value) => { setCity(value) }}
                            renderInput={(params) => <TextField {...params} label="Cidade" variant="outlined" />}
                          />
                        </>
                      ) : (
                          <></>
                        )}

                      <TextField

                        label="Telefone"
                        variant="outlined"
                        name="phone"
                        InputProps={{
                          inputComponent: PhoneMask as any,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <TextField

                        label="Celular"
                        variant="outlined"

                        name="cellphone"
                        InputProps={{
                          inputComponent: CellMask as any,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={values.cellphone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={classes.grid3}>
                      <TextField

                        label="Email de Contato"
                        variant="outlined"

                        name="state"
                        value={contactEmail}
                        onChange={(e) => { setContactEmail(e.target.value) }}
                      />
                      <TextField

                        label="Email Financeiro"
                        variant="outlined"
                        name="financeEmail"
                        value={financeEmail}
                        onChange={(e) => { setFinanceEmail(e.target.value) }}
                      />
                      <TextField

                        label="Email da Direção"
                        variant="outlined"
                        name="boardEmail"
                        value={boardEmail}
                        onChange={(e) => { setBoardEmail(e.target.value) }}
                      />
                      <TextField
                        label="pontoFocal"
                        variant="outlined"
                        name="pontoFocal"
                        value={pontoFocal}
                        onChange={(e) => { setPontoFocal(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <br></br>
                      <h2>Configurações</h2>
                    </div>
                    <div className={classes.button}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={ipEnabled}
                            onChange={(e) => { setIpEnabled(!ipEnabled) }}
                            color="primary"
                            name="switchIP"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Ativa filtro de IP"
                      />
                    </div>

                    <div className={classes.grid}>
                      <TextField
                        label="IPs Permitidos"
                        variant="outlined"
                        value={allowedIP}
                        multiline
                        onChange={(e) => {
                          setAllowedIP(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <Autocomplete
                        getOptionLabel={(clusters) => clusters.name}
                        options={clusters}
                        value={cluster}
                        onChange={(event, value) => { setCluster(value) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Clusters"
                          />
                        )}
                      />
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/clientes') }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ClienteCadastrar;
