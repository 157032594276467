import React from 'react';
import Swal from 'sweetalert2';
import api from '../../services/api';

import logo from '../../assets/images/logo-white.svg';

import './styles.scss';



const TopMenu: React.FC = () => {

  const Logout = async () => {
    try {
      if (localStorage.getItem('admin')) {

        const headers = {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken')
        };
        await api.delete('/auth/admin', headers);


        localStorage.removeItem('admin');
        localStorage.removeItem('signed');
        localStorage.removeItem('login');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('acl');
        
        window.location.pathname = "/";
      }
      else {
        
        const headers = {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken')
        };
        await api.delete('/auth/', headers);
        
        
        localStorage.removeItem('admin');
        localStorage.removeItem('user');
        localStorage.removeItem('login');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('acl');
        
        window.location.pathname = "/";
      }
    } catch (error) {
      console.log('error signing out: ', error);
      Swal.fire({
        title: 'Erro 😢',
        text: 'Erro ao fazer logout, por favor tente novamente!',
        icon: 'error',
      });
    }
  }

  return (
    <div className="fixed-top">
      {localStorage.getItem('admin') ? (
        <div className="top-menu ">
          <div className="logo">
            <img src={logo} className="logo" alt="Logo Aoki" />
          </div>

          <div className="profile">
            <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="30.5" cy="30.5" r="29" stroke="white" strokeWidth="3" />
              <g clipPath="url(#clip0)">
                <path d="M26.0425 32.5255V29.6324C26.7112 29.3098 28.6231 27.0916 28.8237 25.3598C28.9426 24.9504 29.0343 23.484 29.0343 23.154C29.0343 21.3452 29.007 17.6085 30.1685 14.6831C29.7624 12.718 28.7049 11.0631 26.4313 10.8447C25.8246 9.86465 24.2668 9.36841 22.2409 9.36841C14.1424 9.50487 13.3127 14.9734 14.9399 21.2583C14.6254 21.4345 14.1053 21.9952 14.234 22.9902C14.4768 24.8462 15.3039 25.3176 15.829 25.3573C16.0321 27.0916 17.944 29.3098 18.6127 29.6324V32.528C17.1267 36.8676 8.21094 32.5255 8.21094 44.1053H26.0425C26.0425 38.9692 28.9996 36.7559 31.4638 35.4856C29.1507 34.694 26.7508 34.5973 26.0425 32.5255Z" fill="white" />
                <path d="M43.8753 34.3988V32.5329C49.2817 32.5329 50.5374 30.4487 50.5374 30.4487C47.5109 28.0295 52.7911 10.8149 41.0866 10.8149C39.4174 10.8149 38.1246 12.2615 38.1246 12.2615C28.5525 12.2615 33.8698 28.1139 31.0564 30.3916C31.0564 30.3916 32.5497 32.5503 37.9314 32.5503V34.3963C36.4455 38.3166 29.0156 36.8676 29.0156 44.1052H52.7911C52.7911 36.8676 45.3612 38.3166 43.8753 34.3988Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="44.5789" height="34.7368" fill="white" transform="translate(8.21094 9.36841)" />
                </clipPath>
              </defs>
            </svg>
            <div>
              <strong className="name">{localStorage.getItem('login')}</strong>
              <span onClick={Logout}>Sair</span>
            </div>
          </div>
        </div>
      ) : (
          <div className="topUser">
            <div className="top-menu ">
              <div className="logo">
                <img src={logo} className="logo" alt="Logo Aoki" />
              </div>

              <div className="profile">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30.5" cy="30.5" r="29" stroke="white" strokeWidth="3" />
                  <g clipPath="url(#clip0)">
                    <path d="M26.0425 32.5255V29.6324C26.7112 29.3098 28.6231 27.0916 28.8237 25.3598C28.9426 24.9504 29.0343 23.484 29.0343 23.154C29.0343 21.3452 29.007 17.6085 30.1685 14.6831C29.7624 12.718 28.7049 11.0631 26.4313 10.8447C25.8246 9.86465 24.2668 9.36841 22.2409 9.36841C14.1424 9.50487 13.3127 14.9734 14.9399 21.2583C14.6254 21.4345 14.1053 21.9952 14.234 22.9902C14.4768 24.8462 15.3039 25.3176 15.829 25.3573C16.0321 27.0916 17.944 29.3098 18.6127 29.6324V32.528C17.1267 36.8676 8.21094 32.5255 8.21094 44.1053H26.0425C26.0425 38.9692 28.9996 36.7559 31.4638 35.4856C29.1507 34.694 26.7508 34.5973 26.0425 32.5255Z" fill="white" />
                    <path d="M43.8753 34.3988V32.5329C49.2817 32.5329 50.5374 30.4487 50.5374 30.4487C47.5109 28.0295 52.7911 10.8149 41.0866 10.8149C39.4174 10.8149 38.1246 12.2615 38.1246 12.2615C28.5525 12.2615 33.8698 28.1139 31.0564 30.3916C31.0564 30.3916 32.5497 32.5503 37.9314 32.5503V34.3963C36.4455 38.3166 29.0156 36.8676 29.0156 44.1052H52.7911C52.7911 36.8676 45.3612 38.3166 43.8753 34.3988Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="44.5789" height="34.7368" fill="white" transform="translate(8.21094 9.36841)" />
                    </clipPath>
                  </defs>
                </svg>
                <div>
                  <strong className="name">{localStorage.getItem('login')}</strong>
                  <span onClick={Logout}>Sair</span>
                </div>
              </div>
            </div>
          </div>
        )}

    </div>
  );
}

export default TopMenu;
