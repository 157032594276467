import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { TextField, Button } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/CreateNewFolder';

import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import nodata from "../../../../assets/images/nodata.svg";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Modal from '@material-ui/core/Modal';
import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';

import { Action, Resource } from '../../../../utils/aclUtils';
import includesAll from '../../../../utils/includesAll';

import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';

import { NavLink } from 'react-router-dom';

import Swal from 'sweetalert2';

import api from '../../../../services/api';
import { FilterListIcon, SearchIcon } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VisibilityIcon from '@material-ui/icons/Visibility';


interface Data {
  name: string;
  login: string;
  profile: string;
  email: string;
  options: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
  { id: 'login', numeric: false, disablePadding: true, label: 'Login' },
  { id: 'profile', numeric: false, disablePadding: true, label: 'Perfil' },
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'options', numeric: false, disablePadding: true, label: '' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    grid2: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
      },
    },

  }),
);



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    pass: {
      display: 'abolsute',

      '& > *': {
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

let aclItem;
if (localStorage.getItem('acl')) {
  const aclString = localStorage.getItem('acl');
  const acl: { resource: Resource, action: Action[] | '*' }[] = JSON.parse(aclString!);
  aclItem = acl.find(aclItem => aclItem.resource === 'Admin');
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface Profile {
  id: string,
  name: string
}

export default function Admins() {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(0);

  const [rows, setRows] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowId, setRowId] = React.useState('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetRowId = (rowId) => {
    setRowId(rowId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [searchName, setSearchName] = React.useState('');
  const [searchEmail, setSearchEmail] = React.useState('');
  const [searchLogin, setSearchLogin] = React.useState('');
  const [profile, setProfile] = React.useState<Profile | null | undefined>({ id: '', name: '' });
  const [profileList, setProfileList] = React.useState<Profile[]>([]);
  const [display, setDisplay] = React.useState('none');
  const handleFilter = () => {
    if (display === 'grid') {
      setDisplay('none');
    } else {
      setDisplay('grid');
    }
  }

  useEffect(() => {
    handleSearch()

    const getProfiles = async () => {
      const response = await api.get('/profiles?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setProfileList((await response.json()).data);
      }
    };
    getProfiles();
  }, [order, orderBy, page, rowsPerPage]);

  const handleSearch = async () => {
    const queryParams = new URLSearchParams();
    if (searchName) {
      queryParams.append('name', searchName);
    }
    if (searchEmail) {
      queryParams.append('email', searchEmail);
    }
    if (profile) {
      queryParams.append('profile', profile.id);
    }
    if (searchLogin) {
      queryParams.append('login', searchLogin);
    }

    const getData = async () => {
      const response = await api.get(`/admins?${queryParams.toString()}&order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);
      if (response.status && response.status.toString()[0] === '2') {
        const data = await response.json();
        setRows(data.data);
        setTotalPages(data.total);
      }
    };
    getData();
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;



  const handleDelete = async (id: string) => {
    handleCloseMenu();
    Swal.fire({
      title: 'Você tem certeza que deseja excluir o cadastro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#418107",
      confirmButtonText: "Sim, Excluir!",
      cancelButtonText: "Cancelar!"
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const deleteConfirmed = async () => {
            await api.delete(`/admins/${id}`);
            const response = await api.get(`/admins?order=${orderBy}&direction=${order.toUpperCase()}&page=${page}&size=${rowsPerPage}`);
            if (response.status && response.status.toString()[0] === '2') {
              const data = await response.json();
              setRows(data.data);
              setTotalPages(data.total);
            }
          }

          deleteConfirmed();
        } catch (error) {
          console.log(`erroou: ${error}`);
        }
      }
    });

  };

  const [open, setOpen] = React.useState(false);
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [passwordIcon1, setPasswordIcon1] = React.useState(<ShowEye />);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [passwordIcon2, setPasswordIcon2] = React.useState(<ShowEye />);
  const [id, setID] = React.useState('');

  function handlePassword1() {
    setShowPassword1(!showPassword1);
    if (showPassword1) {
      setPasswordIcon1(<ShowEye />);
    } else {
      setPasswordIcon1(<HideEye />);
    }
  }
  function handlePassword2() {
    setShowPassword2(!showPassword2);
    if (showPassword2) {
      setPasswordIcon2(<ShowEye />);
    } else {
      setPasswordIcon2(<HideEye />);
    }
  }

  async function handleSubmitNovaSenha(e: React.FormEvent) {
    e.preventDefault();
    try {
      if (password1 === password2) {

        const data = {
          id: id,
          password: password1
        };
        await api.post('/auth/admin/redefine-password', data);

        handleClose();
        Swal.fire({
          title: 'Senha redefinida com sucesso!',
          icon: 'success',
        });
      }
      else {
        handleClose();
        Swal.fire({
          title: 'Senhas diferentes',
          text: 'Digite a mesma senha para os dois campos',
          icon: 'warning',
        }).then((result) => {
          if (result.isConfirmed) {
            handleOpen(id);
          }
        });

      }

    }
    catch (error) {
      Swal.fire({
        title: 'Erro 😢',
        text: error,
        icon: 'error',
      });

    }
  }

  const handleOpen = (id: string) => {
    setOpen(true);
    handleCloseMenu();
    setID(id);
  };

  const handleClose = () => {
    setOpen(false);

    setPassword1('');
    setPassword2('');
  };

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selecionado(s)
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Usuários
          </Typography>
        )}
        <Tooltip title="Filtro de dados">
          <IconButton onClick={handleFilter} aria-label="filter">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Listar inativos">
          <IconButton aria-label="Listar inativos">
            <NavLink to="admins-inativos"><DeleteSweepIcon /></NavLink>
          </IconButton>
        </Tooltip>


        {(aclItem && (aclItem.action === '*' || aclItem.action.includes('CREATE'))) && <Tooltip title="Cadastrar">
          <IconButton aria-label="Cadastrar">
            <NavLink to="cadastro-admin"><AddIcon /></NavLink>
          </IconButton>
        </Tooltip>}


      </Toolbar>
    );
  };


  const modalBody = (
    <div className="modal">
      <form onSubmit={handleSubmitNovaSenha}>
        <h1>Redefinição de Senha </h1>
        <div className={classes.grid2}>
          <div className={classes.pass}>
            <TextField
              type={showPassword1 ? 'text' : 'password'}

              label="Nova senha"
              variant="outlined"
              required
              name="password1"
              value={password1}
              onChange={(e) => { setPassword1(e.target.value) }}
            />
            <div className="container-pass">
              <span className="passMUI" onClick={(e) => { handlePassword1() }}>{passwordIcon1}</span>
            </div>
          </div>
          <div className={classes.pass}>
            <TextField
              type={showPassword2 ? 'text' : 'password'}

              label="Repita a nova senha"
              variant="outlined"
              required
              name="password2"
              value={password2}
              onChange={(e) => { setPassword2(e.target.value) }}
            />
            <div className="container-pass">
              <span className="passMUI" onClick={(e) => { handlePassword2() }}>{passwordIcon2}</span>
            </div>
          </div>
        </div>
        <div className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            startIcon={<SaveIcon />}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<BackIcon />}
            onClick={() => { handleClose() }}
          >
            Voltar
          </Button>
        </div>
      </form>
    </div>
  );

  return (

    <main>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <div id="filtros" style={{ display: display, transition: "width 2s, height 4s", transitionDuration: "2s" }}>
              <div className="filterField">
                <TextField
                  label="Nome"
                  variant="standard"
                  value={searchName}
                  onChange={(e) => { setSearchName(e.target.value) }}
                />

                <TextField
                  label="Login"
                  variant="standard"
                  value={searchLogin}
                  onChange={(e) => { setSearchLogin(e.target.value) }}
                />

                <Autocomplete
                  aria-required
                  id="controllable-states-demo"
                  className="autocompleteSearch"
                  value={profile}
                  getOptionLabel={(company) => company.name}
                  options={profileList}
                  onChange={(event, value) => { setProfile(value) }}
                  renderInput={(params) =>
                    <TextField {...params} label="Perfil" />
                  }
                />

                <TextField
                  label="Email"
                  variant="standard"
                  value={searchEmail}
                  onChange={(e) => { setSearchEmail(e.target.value) }}
                />
                <Button
                  variant="contained"
                  className="searchButton"
                  color="primary"
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}
                >Pesquisar</Button>
              </div>


            </div>
            {totalPages > 0 ? (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                /><TableBody>
                  {rows.map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">

                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">
                          {row.login}
                        </TableCell>
                        <TableCell align="left">
                          {row.profile.name}
                        </TableCell>
                        <TableCell align="left">
                          {row.email}
                        </TableCell>
                        <TableCell align="right">
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                          >
                            {modalBody}
                          </Modal>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => { handleClick(e); handleSetRowId(row.id) }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >
                            {(aclItem && (aclItem.action === '*' || aclItem.action.includes('READ'))) &&
                              <NavLink to={"visualizar-admin/" + rowId}>
                                <StyledMenuItem>
                                  <ListItemIcon>
                                    <VisibilityIcon />
                                  </ListItemIcon>
                                  <div className="actionText">Visualizar</div>
                                </StyledMenuItem>
                              </NavLink>}
                            {(aclItem && (aclItem.action === '*' || aclItem.action.includes('UPDATE'))) &&

                              <StyledMenuItem onClick={() => handleOpen(rowId)}>
                                <ListItemIcon>
                                  <VpnKeyIcon />
                                </ListItemIcon>
                                <div className="actionText">Redefinir senha</div>
                              </StyledMenuItem>}
                            {(aclItem && (aclItem.action === '*' || includesAll(['READ', 'UPDATE'], aclItem.action))) &&
                              <NavLink to={"editar-admin/" + rowId}>
                                <StyledMenuItem>
                                  <ListItemIcon>
                                    <EditIcon />
                                  </ListItemIcon>
                                  <div className="actionText">Editar</div>
                                </StyledMenuItem>
                              </NavLink>}
                            {(aclItem && (aclItem.action === '*' || aclItem.action.includes('DELETE'))) &&

                              <StyledMenuItem onClick={() => handleDelete(rowId)}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <div className="actionText">Excluir</div>
                              </StyledMenuItem>}

                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            ) : (
              <>
                <div className="no-data">
                  <img src={nodata} className="no-data-svg" alt="Sem dados" />
                  <div>Nenhum registro disponível para exibição</div>
                </div>
              </>)}


          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </main>
  );
}

