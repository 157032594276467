import React from 'react';
import jwt from 'jsonwebtoken';

function logout() {
  localStorage.removeItem('admin');
  localStorage.removeItem('user');
  localStorage.removeItem('username');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('acl');

  window.location.pathname = "/";

  return (<> </>);
}

function LogoutCheck(props) {

  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (accessToken !== null && refreshToken !== null) {

    const parsedAccessToken = jwt.decode(accessToken, { json: true });
    const parsedRefreshToken = jwt.decode(refreshToken, { json: true });

    if (parsedAccessToken !== null && parsedRefreshToken !== null) {

      if (new Date(parsedAccessToken.exp * 1000) <= new Date()) {


        if (new Date(parsedRefreshToken.exp * 1000) <= new Date()) {

          return logout();

        }
      }
      return <>{props.children}</>;
    }
    return logout();
  }
  return <>{props.children}</>;
}



export default LogoutCheck;