import React, { ChangeEvent, useEffect, useState } from 'react'

import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';

import { TextField } from '@material-ui/core';
import useModalStyles from './styles'

interface Props {
  label: string
  name: string
  type?: string
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const Input = ({ label, name, type = "text", onChange }: Props) => {


  const classes = useModalStyles()

  const [showPassword, setShowPassword] = useState(false);

  const isPassword = type === "password"

  const handleShowClick = () => setShowPassword(!showPassword)

  return (
    <div className={classes.pass}>
      <TextField
        type={isPassword && showPassword ? 'text' : type}
        label={label}
        variant="outlined"
        required
        name={name}
        onChange={onChange}
      />
      <div className="container-pass">
        <span
          className="passMUI"
          onClick={handleShowClick}
        >
          {isPassword ? (showPassword ? <ShowEye /> : <HideEye />) : null}
        </span>
      </div>
    </div>
  )
}

export default Input;