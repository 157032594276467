import OpenInNew from "@material-ui/icons/OpenInNew";
import React from "react"
import './styles.scss';


export const MetricGraphsCard = () => {
    const orgId = "1";
    const refresh = "10s";
    const from = "now-6h";
    const to = "now";
        
    const activeConnectionsLink = `https://grafana.aokiinova.com.br/public-dashboards/60b4fab4845f457e90c233ae6c03db21?orgId=1&refresh=10s&from=now-6h&to=now`;
    
    interface LinkButtonProps {
        link: string
    }
    const LinkButton = ({ link }: LinkButtonProps) => (
        <div className="linkButtonContainer">
            <a href={link} target="_blank">
                <OpenInNew />
            </a>
        </div>
    )

    return (
        <div className="card grafanaCard">
            <h1>
                Gráficos de Métricas
                <a href="https://grafana.aokiinova.com.br/login" target="_blank">
                    <OpenInNew />
                </a>
            </h1>
            <LinkButton link={activeConnectionsLink} />
            <iframe className="grafanaContainer" src={activeConnectionsLink} />
        </div>
    )
}