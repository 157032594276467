import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';


import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import api from '../../../../services/api';


import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface AvisoCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
}


function AvisoCadastrar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');

  const [isNew, setIsNew] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/warnings/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setName(data.name);
            setCode(data.code);
          }
        };

        getData();
      }
    }
  }, [id, isNew]);


  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (!id) {
        Swal.fire({
          title: 'Erro de ID 😢',
          icon: 'error',
        });
        return;
      }

      let result
      if (isNew) {
        result = await api.put("/warnings", {
          id: id,
          name: name,
          code: code,
          enabled: true,
        });
      }
      else {
        result = await api.patch("/warnings/" + id, {
          name: name,
          code: code,
          enabled: true,
        });
      }


      if (result.status && result.status.toString()[0] === '2') {
        Swal.fire({
          title: 'Cadastro realizado com sucesso!',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          cancelButtonColor: '#418107',
          cancelButtonText: 'Ver Todos'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          } else {
            history.push('/avisos');
          }
        });
      }
      else {
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: error,
        icon: 'error',
      });
    }

  }

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Avisos</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid2}>
                      <TextField
                        
                        label="Código"
                        variant="outlined"
                        required
                        name="code"
                        value={code}
                        onChange={(e) => { setCode(e.target.value) }}
                      />
                      <TextField
                        
                        label="Nome"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>

                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Salvar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/avisos') }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AvisoCadastrar;
