import React from 'react'
import { createNumberMask } from "text-mask-addons";
import MaskedInput from 'react-text-mask'


interface MaskProps {
  inputRef: (ref: HTMLInputElement | null) => void,
  onChange: () => void
  }

const CurrencyMask: React.FC<MaskProps> = (props) => {
  const { inputRef, onChange,...other } = props;

  const currencyMaskOptions = {
    prefix: 'R$ ',
    suffix: '',
    includeThousandSSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 12,
    allowNegative: false,
    allowLeadingZeroes: false
  }

  return (
  <MaskedInput
    {...other}
    ref={(ref: any) => {
    inputRef(ref ? ref.inputElement : null);
    }}
    mask={createNumberMask({...currencyMaskOptions})}
    placeholderChar={'\u2000'}
    showMask
  />
  );
}

export default CurrencyMask;