import React from 'react';


import './styles.scss';
import notFound from '../../assets/images/404.svg';

function PageNotFound() {
  const signed = JSON.parse(localStorage.getItem('admin') || localStorage.getItem('user') || 'false');

  return (
    <>
      {(signed) ? (
        <main>
          <div className="verticalAlign">
            <div className="notFound">
              <img src={notFound} className="svg" alt="notFound" />
              <h1>Página não encontrada <span role="img" aria-label="sad">😢</span></h1>
              <p>O endereço acessado não existe ou você não tem permissão para acessá-lo.</p>
            </div>
          </div>
        </main>
      ) : (
          <div className="verticalAlign">
            <div className="notFound">
              <img src={notFound} className="svg" alt="notFound" />
              <h1>Página não encontrada <span role="img" aria-label="sad">😢</span></h1>
              <p>O endereço acessado não existe ou você não tem permissão para acessá-lo.</p>
            </div>
          </div>
        )}

    </>
  )
}

export default PageNotFound;
