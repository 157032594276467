import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import LogoutCheck from './components/LogoutCheck';

ReactDOM.render(
  <React.StrictMode>
    <LogoutCheck> <App /> </LogoutCheck>
  </React.StrictMode>,
  document.getElementById('root')
);
