export interface movideskResponse {
  id: string;
  message: string;
  createdBy: {
    businessName: string;
    email: string;
  };
  owner: {
    businessName: string;
    email: string;
  };
  subject: string;
  status: string;
  createdDate: Date;
  lastUpdate: Date;
  actions: {
    createdDate: Date;
    description: string;
    status: string
  }[];
  clients: {
    businessName: string;
  }[]
}

export interface movideskPost {
  type: number;
  subject: string;
  category: string;
  urgency: string;
  createdBy: { id: string };
  status: string;
  clients: { id: string }[];
  actions: { id: number, type: number, description: string, createdBy: { id: string } }[];
}


export class MovideskAPI {
  async getTicketsList(movideskId: string | null): Promise<movideskResponse[]> {
    console.log(process.env.REACT_APP_MOVIDESK_API)
    const response = await fetch(`https://api.movidesk.com/public/v1/tickets?token=${process.env.REACT_APP_MOVIDESK_API}
                                  &$select=id,subject,status,createdDate,lastUpdate
                                  &$expand=owner,createdBy,clients,actions($select=id,type,origin,description,status,justification,createdDate,isDeleted,tags)
                                  &$filter=clients/any(client: client/id eq '${movideskId}')
                                  &$orderBy=lastUpdate desc
                                  `);
    return (await response.json() as movideskResponse[]);
  }
}
