import React from 'react';
import { IImageLoaderProps } from './index.d';
import ImageUploader from 'react-images-upload';


export const ImageLoader = ({ singleImage, imageSetter, multipleImageSetter, ...rest }: IImageLoaderProps) => {
  const onDrop = (file: any, picture: string[]) => {
    if(singleImage && imageSetter)
      imageSetter(picture[0])
    else if(multipleImageSetter)
      multipleImageSetter(picture)
  }
  
  return <ImageUploader {...rest} onChange={onDrop}/>;
}
