import React, { useState, FormEvent, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import ShowEye from '@material-ui/icons/Visibility';
import HideEye from '@material-ui/icons/VisibilityOff';

import './styles.scss';
import Swal from 'sweetalert2';


import gear from '../../../../assets/images/gear.svg';
import logo from '../../../../assets/images/logo.svg';
import oracle from '../../../../assets/images/oracle.png';


function NovaSenhaAdmin() {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordIcon1, setPasswordIcon1] = React.useState(<ShowEye />);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordIcon2, setPasswordIcon2] = React.useState(<ShowEye />);
  const [status, setStatus] = useState(0);

  const history = useHistory();

  interface ParamTypes {
    id: string;
  }

  const params = useParams<ParamTypes>();

  useEffect(() => {
    const getData = async () => {
      const responseVerify = await api.get('/auth/admin/verify-recovery/' + params.id);
      setStatus(responseVerify.status);
    };

    getData();
  }, [params.id]);

  function handlePassword1() {
    setShowPassword1(!showPassword1);
    if (showPassword1) {
      setPasswordIcon1(<ShowEye />);
    } else {
      setPasswordIcon1(<HideEye />);
    }
  }
  function handlePassword2() {
    setShowPassword2(!showPassword2);
    if (showPassword2) {
      setPasswordIcon2(<ShowEye />);
    } else {
      setPasswordIcon2(<HideEye />);
    }
  }


  async function signIn(e: FormEvent) {
    e.preventDefault();

    try {


      if (password1 === password2) {

        const data = {
          password: password1
        };


        if (status.toString()[0] === '2') {
          await api.post('/auth/admin/change-password/' + params.id, data);

          Swal.fire({
            title: 'Senha redefinida com sucesso',
            icon: 'success',
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: 'Voltar para o login',
            confirmButtonColor: '#418107',
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/');
            }
          });
        }
        else {
          Swal.fire({
            title: 'Erro 😢',
            text: 'Ocorreu algum problema',
            icon: 'error',
            confirmButtonText: 'Voltar para o login',
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/');
            }
          });
        }
      }
      else {
        Swal.fire({
          title: 'Senhas diferentes',
          text: 'Digite a mesma senha para os dois campos',
          icon: 'warning',
        });
      }
    }
    catch (error) {

      Swal.fire({
        title: 'Erro 😢',
        text: 'Ocorreu algum problema',
        icon: 'error',
        showCancelButton: true,
        cancelButtonColor: '#418107',
        cancelButtonText: 'Tentar novamente',
        confirmButtonText: 'Voltar para o login',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/');
        }
      });
    }


  }

  return (
    <>

      <div className="esqueciAdmin">
        <div className="grid-container">
          <div className="item-left">
            <img src={gear} className="gear" alt="Engrenagem" />
          </div>
          <div className="item-right">

            <div className="form">

              <img src={logo} className="logo" alt="Logo Aoki" />
              <br></br><p>A D M I N</p>
              <form className="materializeForm" onSubmit={signIn}>
                <div className="container-pass">
                  <input
                    type={showPassword1 ? 'text' : 'password'}
                    className="txtbox"
                    placeholder="Digite a nova senha"
                    autoFocus
                    required
                    name="password1"
                    value={password1}
                    onChange={(e) => { setPassword1(e.target.value) }}
                  />
                  <span onClick={(e) => { handlePassword1() }}>{passwordIcon1}</span>
                </div>
                <div className="container-pass">
                  <input
                    type={showPassword2 ? 'text' : 'password'}
                    className="txtbox"
                    placeholder="Confirme a senha"
                    required
                    name="password2"
                    value={password2}
                    onChange={(e) => { setPassword2(e.target.value) }}
                  />
                  <span onClick={(e) => { handlePassword2() }}>{passwordIcon2}</span>
                </div>
                <button>REDEFINIR</button>
                <img src={oracle} className="oracle" alt="Logo Oracle" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NovaSenhaAdmin;
