import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export const unformatDate = (date) => {
  if (date) {
    return date.split('T')[0];
  }
  else
    return ''
}
export const formatDate = (date) => {
  if (date) {
    if (date.includes('T'))
      return date
    else
      return `${date}T00:00:01.592Z`;
  }
  else
    return null
}

export const formatDateWithHoursAndMinutes = (date) => {
  if (date) {
    var d = new Date(date),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = '' + d.getFullYear(),
      hour = '' + d.getHours(),
      minutes = '' + d.getMinutes();
      
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      if (hour.length < 2) hour = '0' + hour;
      if (minutes.length < 2) minutes = '0' + minutes;
    
    var result = [day, month, year].join(' / ');
    return result + " " + hour + ":" + minutes;
  }
  else
    return ''
}

export const dateToString = (date: string) => date ? format(new Date(date), 'dd \'de\' MMMM \'de\' yyyy, HH:mm', { locale: ptBR }) : '-'