const handleResponse = (response: Response, error = 'Erro') => {

    if (!response) return false;

    if (response.ok) return true;

    const [code]: string = response.status.toString()

    const errors = {
        '4': error,
        '5': 'Erro, Tente Novamente'
    }

    throw errors[code]
}

export { handleResponse }