import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import ShowEye from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';

import Modal from '@material-ui/core/Modal';

import { DataGrid, ColDef, ValueFormatterParams } from '@material-ui/data-grid';

import nodata from "../../../../assets/images/nodata.svg";

import { MovideskAPI, movideskResponse } from '../../../../services/movidesk';
import jwt from 'jsonwebtoken';

import api from '../../../../services/api';

import './styles.scss'


import { NavLink } from 'react-router-dom';

import { format } from 'date-fns';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';

import Swal from 'sweetalert2';


import { LocaleText } from '../../../../utils/localeTextConstants';

export const ptBR: LocaleText = {
  rootGridLabel: 'grid',
  noRowsLabel: 'No rows',
  errorOverlayDefaultLabel: 'An error occurred.',

  toolbarDensity: 'Densidade',
  toolbarDensityLabel: 'Densidade',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Padrão',
  toolbarDensityComfortable: 'Confortável',

  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Mostrar Selecionador de Colunas',


  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar Filtros',
  toolbarFiltersTooltipHide: 'Esconder Filtros',
  toolbarFiltersTooltipShow: 'Mostrar Filtros',
  toolbarFiltersTooltipActive: (count) => `${count} active filter(s)`,

  columnsPanelTextFieldLabel: 'Achar coluna',
  columnsPanelTextFieldPlaceholder: 'Título da Coluna',
  columnsPanelDragIconLabel: 'Reorder Coluna',
  columnsPanelShowAllButton: 'Mostrar Tudo',
  columnsPanelHideAllButton: 'Esconder Tudo',


  filterPanelAddFilter: 'Adicionar Filtro',
  filterPanelDeleteIconLabel: 'Apagar',
  filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'E',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',


  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar columns',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Esconder',
  columnMenuUnsort: 'Desorganizar',
  columnMenuSortAsc: 'Organizar Crescente',
  columnMenuSortDesc: 'Organizar Descrescente',

  columnHeaderFiltersTooltipActive: (count) => `${count} filtro(s) ativos`,
  columnHeaderFiltersLabel: 'Mostrar Filtros',
  columnHeaderSortIconLabel: 'Organizar',

  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  footerTotalRows: 'Total de Linhas:',

  footerPaginationRowsPerPage: 'Linhas por página:',
};



const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: '#fff  ',
          backgroundColor: '#01a3ff',
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);



const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Tickets
          </Typography>
      <Tooltip title="Novo Ticket">

        <IconButton aria-label="Novo Ticket">
          <NavLink to="criar-ticket"><AddIcon /></NavLink>
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    title: {
      flex: '1 1 100%',
    },
    paper: {
      width: '100%',
      height: '900px',
      marginBottom: theme.spacing(2),
    },
    paperNoData: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function TicketList() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [noData, setNoData] = React.useState(false);
  const [rows, setRows] = React.useState<movideskResponse[]>([]);
  const [id, setId] = React.useState('');
  const [movideskId, setMovideskId] = React.useState('');
  const [notFirst, setNotFirst] = React.useState(false);

  const formDateToBr = (date) => {
    if (date) {
      const timeArray = date.split('T')[1].split('.')[0].split(':');
      const hour = Number(timeArray[0]);
      let newHour;
      if (hour < 13) {
        if (hour > 2)
          newHour = `0${hour - 3}`;
        else {
          switch (hour) {
            case 2: newHour = 23
              break;
            case 1: newHour = 22
              break;
            case 0: newHour = 21
              break;
            default: newHour = `00`
          }
        }

      }
      else
        newHour = `${hour - 3}`
      const newTime = `${newHour}:${timeArray[1]}:${timeArray[2]}`;
      const newDate = `${date.split('T')[0]}T${newTime}.${date.split('.')[1]}`
      return newDate;
    }
    else
      return null
  }


  useEffect(() => {
    const getUser = async () => {
      setId((jwt.decode(localStorage.getItem('accessToken')!, { json: true })!).sub);
      if (id) {
        const responseUser = await api.get('/users/' + id);
        if (responseUser.status && responseUser.status.toString()[0] === '2') {
          const data = await responseUser.json();
          setMovideskId(data.movideskId);
          setNotFirst(true);
        }
      }
      else
        setNotFirst(false);
    }
    getUser();
  }, [id])



  useEffect(() => {
    const getData = async () => {

      if (notFirst) {
        if (movideskId) {
          const response = await new MovideskAPI().getTicketsList(movideskId);
          if ((response as unknown as movideskResponse).message) {
            Swal.fire({
              title: 'Não foi possível exibir os tickets da Movidesk',
              text: 'Ocorreu um erro na comunicação com a API da Movidesk.',
              icon: 'error',
            });
            setNoData(true);
          }
          else {

            if ((await response.length) === 0 && movideskId) {
              setNoData(true);
            }
            else {
              setRows(await response);

              if (movideskId.length !== 0)
                setLoading(false);
            }
          }
        }
        else {
          Swal.fire({
            title: 'Não foi possível exibir os tickets da Movidesk',
            text: 'Verifique se o seu sua conta possui suporte a Movidesk.',
            icon: 'error',
          });
          setNoData(true);
        }

      };
    }
    getData();

  }, [rows.length, movideskId, notFirst]);

  useEffect(() => {
    const reformatRows = function (rows) {
      if (!rows.message) {
        return rows.map(function (row) {
          row["businessName"] = row.createdBy.businessName;
          if (row.lastUpdate) {
            
            row["createdDate"] = formDateToBr(row.createdDate);
            row["lastUpdate"] = formDateToBr(row.lastUpdate);
            row["lastUpdateFormatted"] = format(new Date(row.lastUpdate), 'yyyy\'/\'MM\'/\'dd, HH:mm', { locale: dateFnsPtBR });
          }
          return (row);
        });
      }

    };
    reformatRows(rows);
  }, [rows]);

  //MODAL
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState({} as movideskResponse);

  const handleOpen = (row: movideskResponse) => {
    setOpen(true);
    setRow(row);
  };

  const handleClose = () => {
    setOpen(false);

  };

  const modalBody = (
    <div className="ticketDetails">
      <div className="modal">
        <div className="body" >
          <div className="spaceBetween">
            <div className="title"><h2>Detalhes do Ticket</h2></div>
            <Tooltip title="Fechar">
              <IconButton aria-label="Fechar" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div className={classes.grid}>
            <TextField

              label="Assunto"
              variant="standard"
              name="subject"
              value={row.subject}
            />
            {row.clients &&
              <TextField

                label="Cliente"
                variant="standard"
                name="subject"
                value={row.clients[0].businessName}
              />
            }

          </div>
          <div className={classes.grid}>
            <TextField

              label="ID"
              variant="standard"
              name="id"
              value={row.id}
            />
            <TextField

              label="Status"
              variant="standard"
              name="status"
              value={row.status}
            />
          </div>
          {row.createdBy &&
            <div className={classes.grid}>
              <TextField

                label="Solicitante"
                variant="standard"
                name="createdBy"
                value={row.createdBy.businessName}
              />
              <TextField

                label="Email Solicitante"
                variant="standard"
                name="createdByEmail"
                value={row.createdBy.email}
              />
            </div>
          }
          {row.owner &&
            <div className={classes.grid}>
              <TextField

                label="Responsável"
                variant="standard"
                name="owner"
                value={row.owner.businessName}
              />
              <TextField

                label="Email Responsável"
                variant="standard"
                name="ownerEmail"
                value={row.owner.email}
              />
            </div>
          }
          {row.createdBy &&
            <div className={classes.grid}>
              <TextField

                label="Data de Criação"
                variant="standard"
                name="subject"
                value={format(new Date(row.createdDate), 'dd \'de\' MMMM \'de\' yyyy, HH:mm', { locale: dateFnsPtBR })}
              />
              <TextField

                label="Última Atualização"
                variant="standard"
                name="subject"
                value={format(new Date(row.lastUpdate), 'dd \'de\' MMMM \'de\' yyyy, HH:mm', { locale: dateFnsPtBR })}
              />
            </div>
          }
          <div className="title"><h2>Histórico</h2></div>
          {row.actions &&
            <>
              {row.actions.map((action) => (
                <div className={classes.grid}>
                  <TextField
                    multiline
                    label={`(${action.status}) ${format(new Date(action.createdDate), 'dd \'de\' MMMM \'de\' yyyy, HH:mm', { locale: dateFnsPtBR })}`}
                    variant="outlined"
                    name="subject"
                    value={`\n${action.description}`}
                  />
                </div>
              ))}
            </>
          }

        </div>
      </div>
    </div>

  );
  //_____________________________

  return (
    <main>
      <div className={classes.root}>


        {noData ? (
          <Paper className={classes.paperNoData}>
            <EnhancedTableToolbar />
            <div className="no-data" style={{ paddingBottom: '3rem' }}>
              <img src={nodata} className="no-data-svg" alt="Sem dados" />
              <div>Nenhum registro disponível para exibição. Verifique seu campo de email.</div>
            </div>
          </Paper>
        ) : (
            <Paper className={classes.paper}>

              <EnhancedTableToolbar />
              <div style={{ height: '100vh', width: 'auto', padding: '1rem' }}>
                <DataGrid
                  rows={rows}
                  localeText={ptBR}
                  showToolbar
                  autoHeight
                  pageSize={10}
                  loading={loading}
                  columns={[
                    { field: 'id', headerName: 'ID', width: 90 },
                    { field: 'businessName', headerName: 'Criador', width: 160 },
                    { field: 'subject', headerName: 'Assunto', width: 400 },
                    { field: 'status', headerName: 'Status', width: 140 },
                    { field: 'lastUpdateFormatted', headerName: 'Última Atualização', width: 180 },
                    {
                      field: 'options', headerName: ' ', width: 70, disableColumnMenu: true,
                      renderCell: (params: ValueFormatterParams) => (
                        <>
                          <Tooltip title="Ver Detalhes">
                            <IconButton aria-label="Ver Detalhes" onClick={() => handleOpen(params.row as movideskResponse)}>
                              <ShowEye />
                            </IconButton>
                          </Tooltip>
                        </>

                      ),
                    }

                  ] as ColDef[]} />
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {modalBody}
              </Modal>
            </Paper>
          )}

      </div>
    </main>
  );
}
