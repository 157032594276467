import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Switch, FormControlLabel } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
  }),
);


interface ParamTypes {
  id: string;
}

interface Versoes {
  id: string,
  name: string,
  defaultDir: string,
  code: string,
  product: string,
  enabled: true,
}


function ProdutosVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [execPath, setExecPath] = useState('');
  const [initDir, setinitDir] = useState('');

  const [versions, setVersions] = useState<Versoes[]>([]);


  const [isNew, setIsNew] = useState(false);
  const [tsplus, setTsPlus] = useState(false);

  const [image, setImage] = useState('');

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/products/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();

            setName(data.name);
            setDescription(data.description);
            setExecPath(data.execPath);
            setinitDir(data.initDir);
            setTsPlus(data.tsplus);
            setImage(data.image);
          }
        };

        getData();

        const getVersoes = async () => {
          const responseVersions = await api.get(`/versions?product=${id}`);
          if (responseVersions.status && responseVersions.status.toString()[0] === '2') {
            const dataVersions = (await responseVersions.json()).data;

            if (dataVersions) {
              setVersions(dataVersions);
            }

          }
        };
        getVersoes();

      }
    }
  }, [id, isNew]);



  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Produtos</h1>
                  <form >
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid2}>
                      <TextField
                        disabled
                        label="Nome"
                        variant="outlined"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Descrição"
                        variant="outlined"
                        name="description"
                        value={description}
                        onChange={(e) => { setDescription(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid2}>
                      <TextField
                        disabled
                        label="Caminho de Execução"
                        variant="outlined"
                        required
                        name="execPath"
                        value={execPath}
                        onChange={(e) => { setExecPath(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Diretório de Iniciação"
                        variant="outlined"
                        name="initDir"
                        value={initDir}
                        onChange={(e) => { setinitDir(e.target.value) }}
                      />
                    </div>
                    <div className={classes.button}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tsplus}
                            color="primary"
                            name="tsplus"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Tsplus"
                      />
                    </div>
                    <div>
                      {image &&
                        <div className="filesimages">
                          <div>
                            <img src={image} height="150px" alt='icon' />
                          </div>
                        </div>
                      }
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/produtos') }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ProdutosVisualizar;
