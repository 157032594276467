interface IBGEUFResponse {
  nome: string;
  sigla: string;
}

interface IBGECidadeResponse {
  nome: string;
}

export interface UF {
  name: string;
  initials: string;
}

export class IBGE {
  async getEstados(): Promise<UF[]> {
    const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    return (await response.json() as IBGEUFResponse[]).map(item => ({
      name: item.nome,
      initials: item.sigla
    } as UF)).sort((a, b) => {
      if (a.name < b.name) return -1;
      if (b.name < a.name) return 1;
      return 0;
    });
  }

  async getMunicipios(uf: string): Promise<string[]> {
    const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
    return (await response.json() as IBGECidadeResponse[]).map(city => city.nome);
  }
}
