import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, Select } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import IPut from 'iput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

function IPutComponent(props) {
  const { inputRef, ...other } = props;
  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
    },
  }));

  return <IPut {...other} />;
}

interface ServidorCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
}
function ServidorVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [hostname, setHostname] = useState('');
  const [cloudId, setCloudId] = useState('');
  const [ip, setIp] = useState('');
  const [externalIp, setExternalIp] = useState('');
  const [serverType, setServerType] = useState('');
  const [url, setUrl] = useState('');

  const [isNew, setIsNew] = useState(false);
  const [isIp, setIsIp] = useState(false);
  const [isExternalIp, setIsExternalIp] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (ip.length > 0) {
      setIsIp(true);

    }
  }, [ip]);

  useEffect(() => {
    if (externalIp.length > 0) {
      setIsExternalIp(true);

    }
  }, [externalIp]);

  useEffect(() => {
    if (!isNew) {
      if (id) {
        try {
          const getData = async () => {
            const response = await api.get('/servers/' + id);
            if (response.status && response.status.toString()[0] === '2') {
              const data = await response.json();

              setHostname(data.hostname);
              setIp(data.ip);
              setExternalIp(data.externalIp);
              setCloudId(data.cloudId);
              setServerType(data.serverType);
              setUrl(data.url);
            }
          };

          getData();
        }
        catch (error) {
          Swal.fire({
            title: 'IP Interno inválido',
            text: 'Preencha todos octetos',
            icon: 'error',
          });
        }

      }

    }
  }, [id, isNew]);

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Servidores</h1>
                  <form >
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid2}>
                      <TextField
                        disabled
                        label="ID Cloud"
                        variant="outlined"
                        required
                        name="cloudId"
                        value={cloudId}
                        onChange={(e) => { setCloudId(e.target.value) }}
                      />
                      <TextField
                         disabled 
                        label="Hostname"
                        variant="outlined"
                        required
                        name="hostname"
                        value={hostname}
                        onChange={(e) => { setHostname(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid2}>
                      {!isNew ? (
                        <>
                          {isIp ? (
                            <TextField
                            disabled  
                              label="IP Interno"
                              variant="outlined"
                              required
                              name="ip"
                              value={ip}                                                      
                            />
                          ) : (
                              <></>
                            )}
                          {isExternalIp ? (
                            <TextField
                            disabled
                              label="IP Externo"
                              variant="outlined"
                              required
                              name="externalIp"
                              value={externalIp}                            
                            />
                          ) : (
                              <></>
                            )}

                        </>) : (
                          <>
                            <TextField
                              disabled  
                              label="IP Interno"
                              variant="outlined"
                              required
                              name="ip"
                              value={ip}
                              InputProps={{
                                inputComponent: IPutComponent,
                                inputProps: { component: IPut },
                              }}
                              onChange={e => { setIp(e.toString()) }}
                              InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                               disabled
                              label="IP Externo"
                              variant="outlined"
                              required
                              name="externalIp"
                              value={externalIp}
                              InputProps={{
                                inputComponent: IPutComponent,
                                inputProps: { component: IPut },
                              }}
                              InputLabelProps={{ shrink: true }}
                              onChange={e => { setExternalIp(e.toString()) }}
                            /></>
                        )}
                    </div>
                    <div className={classes.grid2}>                            
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Tipo de Máquina</InputLabel>
                        <Select
                         disabled
                          id="select"
                          required
                          native
                          value={serverType}                        
                          label="Tipo de Máquina"
                        >
                          <option aria-label="None" value="" />
                          <option value={'AD'}>Active Directory</option>
                          <option value={'AS'}>Application Server</option>
                          <option value={'DBS'}>Database Server</option>
                        </Select>

                      </FormControl>
                      <TextField
                         disabled
                        label="URL TSPlus"
                        variant="outlined"
                        name="url"
                        value={url}
                        onChange={(e) => { setUrl(e.target.value) }}
                      />
                    </div>
                    <div className={classes.button}>                  
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/servidores') }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ServidorVisualizar;
