import React from 'react';

import AdminRoutes from './admin.routes';
import UsuarioRoutes from './usuario.routes';

interface RoutesProps {
  frontend: string;
  isLocalhost: boolean;
};

export default function Routes(props: RoutesProps) {

  switch (props.frontend) {
    case 'admin':
      return <AdminRoutes useBasename={props.isLocalhost} />;
    case 'usuario':
    default:
      return <UsuarioRoutes useBasename={props.isLocalhost} />;
  }
};
