import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../services/api';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
  }),
);

interface ParamTypes {
  id: string;
}

interface Categorias {
  id: string,
  categoryDescription: string,
}

function ServicosVisualizar() {
  const [id, setId] = useState('');
  const params = useParams<ParamTypes>();
  const [serviceDescription, setDescription] = useState('');
  const [executionTime, setExecutionTime] = useState('');
  const [internalPrice, setInternalPrice] = useState('');
  const [onlinePrice, setOnlinePrice] = useState('');
  const [onsitePrice, setOnsitePrice] = useState('');
  const [category, setCategory] = useState<Categorias | null | undefined>({ id: '', categoryDescription: '' });
  const [categoryList, setCategoryList] = useState<Categorias[]>([]);

  const [isNew, setIsNew] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  if (!id) {
    if (params.id) {
      setId(params.id);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/services/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data = await response.json();
            setDescription(data.serviceDescription);
            setExecutionTime(data.executionTime);
            setCategory(data.category);
            setInternalPrice(data.internalPrice);
            setOnlinePrice(data.onlinePrice);
            setOnsitePrice(data.onsitePrice);
          }
        };

        getData();
      }
    }
    const getCategoryList = async () => {
      const responseCategory = await api.get('/categories');
      if (responseCategory.status && responseCategory.status.toString()[0] === '2') {
        const dataCategory = (await responseCategory.json()).data;
        console.log(dataCategory);
        if (dataCategory) {
          setCategoryList(dataCategory);
        }

      }
    };
    getCategoryList();

  }, [id, isNew]);

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Serviços</h1>
                  <form >
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid2}>
                      <TextField
                        disabled
                        label="Descrição"
                        variant="outlined"
                        required
                        name="serviceDescription"
                        value={serviceDescription}
                        onChange={(e) => { setDescription(e.target.value) }}
                      />
                      <Autocomplete
                        disabled
                        value={category}
                        getOptionLabel={(category) => category.categoryDescription}
                        options={categoryList}
                        onChange={(event, value) => { setCategory(value) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            label="Categorias"
                          />
                        )}
                      />
                    </div>
                    <div className={classes.grid2}>
                      <TextField
                        disabled
                        label="Tempo de execução em horas"
                        variant="outlined"
                        required
                        name="executionTime"
                        value={executionTime}
                        onChange={(e) => { setExecutionTime(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Preço hora interno"
                        variant="outlined"
                        required
                        name="internalPrice"
                        value={internalPrice}
                        onChange={(e) => { setInternalPrice(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Preço hora online"
                        variant="outlined"
                        required
                        name="onlinePrice"
                        value={onlinePrice}
                        onChange={(e) => { setOnlinePrice(e.target.value) }}
                      />
                      <TextField
                        disabled
                        label="Preço hora presencial"
                        variant="outlined"
                        required
                        name="onsitePrice"
                        value={onsitePrice}
                        onChange={(e) => { setOnsitePrice(e.target.value) }}
                      />

                    </div>
                    <div className={classes.button}>

                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/servicos') }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ServicosVisualizar;
