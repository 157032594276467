import React, { useState, FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Input, Button, InputLabel, Select } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import jwt from 'jsonwebtoken';

import Swal from 'sweetalert2';


import './styles.scss';

import api from '../../../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid2: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '50%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    grid3: {
      display: 'flex',
      '@media screen and (max-width: 991px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '33.33%',
        '@media screen and (max-width: 991px)': {
          width: '100%',
        }
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

interface TicketsCadastrar {
  id: string;
}

interface ParamTypes {
  id: string;
}


function TicketsCadastrar() {
  const [id, setId] = useState('');
  const [type, setType] = useState('1');

  const classes = useStyles();
  const history = useHistory();

  const [companyId, setCompanyId] = React.useState('');
  const [movideskId, setMovideskId] = React.useState('');
  const [companyMovideskId, setCompanyMovideskId] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [urgency, setUrgency] = React.useState('');

  const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
  }
  const handleChangeUrgency = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUrgency(event.target.value as string);
  }


  useEffect(() => {
    const getUser = async () => {
      setId((jwt.decode(localStorage.getItem('accessToken')!, { json: true })!).sub);
      if (id) {
        const responseUser = await api.get('/users/' + id);
        if (responseUser.status && responseUser.status.toString()[0] === '2') {
          const data = await responseUser.json();
          setCompanyId(data.company.id);
          setMovideskId(data.movideskId)
        }
      }
    }
    getUser();
  }, [id])

  useEffect(() => {
    const getCompany = async () => {
      if (companyId) {
        const responseCompany = await api.get('/companies/' + companyId);
        if (responseCompany.status && responseCompany.status.toString()[0] === '2') {
          const data = await responseCompany.json();
          setCompanyMovideskId(data.movideskId);
        }
      }
    }
    getCompany();
  }, [companyId, movideskId])



  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value);
  };



  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      if (companyMovideskId) {
        if(movideskId){
          const data = {
            type: 2,
            subject,
            category:'Dúvida',
            urgency: 'Média',
            createdBy: { id: movideskId },
            status: "Novo",
            clients: [{ id: companyMovideskId }],
            actions: [{
              id: 1,
              type: 2,
              description,
              createdBy: { id: movideskId }
            }]
          }
          console.log(data);
          const response = await api.post("/movidesk", data);
          if (response.status && response.status.toString()[0] === '2') {
            Swal.fire({
              title: 'Criação realizada com sucesso!',
              icon: 'success',
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: 'Continuar',
              cancelButtonColor: '#418107',
              cancelButtonText: 'Ver Todos'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload(false);
              } else {
                history.goBack();
              }
            });
          }
          else {
            Swal.fire({
              title: 'Não foi possível salvar 😢',
              icon: 'error',
            });
          }
        }
        else{
          Swal.fire({
            title: 'Tivemos um problema...',
            text: 'Verifique se sua conta possui suporte à Movidesk',
            icon: 'error',
          });
        }
        
      }
      else{
        Swal.fire({
          title: 'Tivemos um problema...',
          text: 'Verifique se sua empresa possui suporte à Movidesk',
          icon: 'error',
        });
      }


    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: error,
        icon: 'error',
      });
    }


  }

  return (
    <>
      <main>
        <div className="cadastrar">
          <div className="grid-box grid-one">
            <div className="card">
              <div className="card-body">
                <div>
                  <h1>Criar Ticket</h1>
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="hidden"
                      name="id"
                      value={id}
                    />
                    <div className={classes.grid}>
                      <TextField

                        label="Assunto"
                        variant="outlined"
                        name="subject"
                        value={subject}
                        onChange={(e) => { setSubject(e.target.value) }}
                      />
                    </div>
                    <div className={classes.grid}>
                      <TextField

                        multiline
                        rowsMax={8}
                        rows={4}
                        label="Descrição"
                        variant="outlined"
                        name="subject"
                        value={description}
                        onChange={(e) => { setDescription(e.target.value) }}
                      />
                    </div>

                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Criar
                        </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => { history.push('/tickets') }}
                      >
                        Voltar
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default TicketsCadastrar;
