import React, { FormEvent, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  TextField,
  Input,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBack';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';

import { v4 as uuidv4 } from 'uuid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../../../services/api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import './styles.scss';
import Swal from 'sweetalert2';
import { CheckCircleIcon } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    grid: {
      display: 'flex',
      '@media screen and (max-width: 1130px)': {
        display: 'block',
      },
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        '@media screen and (max-width: 1130px)': {
          width: '100%',
        },
      },
    },
    button: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    head: {
      backgroundColor: '#e8f1fd',
    },
    body: {
      fontSize: 11,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
interface ParamTypes {
  id: string;
}

interface Admin {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
}
interface Categorias {
  id: string;
  category?: listCategory;
  serviceDescription: string;
  executionTime?: string;
  internalPrice: string;
  onlinePrice: string;
  onsitePrice: string;
}

interface listCategory {
  categoryDescription: string;
  enabled: boolean;
}
interface Company {
  id: string;
  name: string;
}

interface Data {
  type: string;
  responsable: User;
  orderOrigin: string;
  orderType: string;
  orderSite: string;
  description: string;
  comment: string;
  expectHours: number;
  hourlyRate: number;
  totalValue: number;
  approvalDate: Date;
  responsableAoki: Admin;
  company: Company;
  approvalResponsable: Admin;
  approvalProtocol: string;
  discount: string;
  grossValue: string;
  netValue: string;
  totalHours: string;
  paidHours: string;
  netHours: string;
  totalServiceValue: number;
  allowance: string;
  allowanceResponsable: Admin;
  status: string;
  enabled: boolean;
  code: string;
}
export default function Visualizar() {
  const params = useParams<ParamTypes>();

  const [id, setId] = useState('');
  const [type, setType] = useState('');
  const [orderOrigin, setOrderOrigin] = useState('2-Consultor Aoki');
  const [orderType, setOrderType] = useState('RC-Recomendação');
  const [orderSite, setOrderSite] = useState('');
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [expectHours, setExpectHours] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [discount, setDiscount] = useState('');
  const [grossValue, setGrossValue] = useState(0);
  const [netValue, setNetValue] = useState(0);
  const [totalServiceValue, setTotalServiceValue] = useState(0);
  const [allowance, setAllowance] = useState('');
  const [status, setStatus] = useState('1-Em Aberto');
  const [isNew, setIsNew] = useState(false);
  const [paidHours, setPaidHours] = useState('');
  const [netHours, setNetHours] = useState(0);
  const [code, setCode] = useState('');

  const [allowanceResponsable, setAllowanceResponsable] = React.useState<
    Admin | any
  >({ id: '', name: '' });

  const [disableFields, setDisableFields] = useState(false);
  const [company, setCompany] = React.useState<Company | null | undefined>({
    id: '',
    name: '',
  });
  const [companyList, setCompanyList] = React.useState<Company[]>([]);

  const [responsableAoki, setResponsableAoki] = React.useState<
    Admin | null | undefined
  >({ id: '', name: '' });
  const [adminList, setAdminList] = React.useState<Admin[]>([]);

  const [category, setCategory] = useState<any | null>({
    id: '',
    serviceDescription: '',
    executionTime: '',
    internalPrice: '',
    onlinePrice: '',
    onsitePrice: '',
  });

  const [categoryList, setCategoryList] = useState<Categorias[]>([]);

  const [categoryIndex, setCategoryIndex] = useState<number>(0);

  const [isRead, setIsRead] = useState(false);

  const path = window.location.pathname;

  if (!id) {
    if (params.id) {
      setId(params.id);
      if (path.includes('visualizar')) setIsRead(true);
    } else {
      setId(uuidv4());
      setIsNew(true);
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (id) {
        const getData = async () => {
          const response = await api.get('/recommendation/' + id);
          const response2 = await api.get('/recommendation-service/' + id);
          if (response.status && response.status.toString()[0] === '2') {
            const data: Data = await response.json();
            setType(data.type);
            setCompany(data.company);
            setResponsableAoki(data.responsableAoki);
            setOrderOrigin(data.orderOrigin);
            setOrderType(data.orderType);
            setDescription(data.description);
            setComment(data.comment);
            setExpectHours(data.expectHours);
            setHourlyRate(data.hourlyRate);
            setTotalValue(data.totalValue);
            setStatus(data.status);
            setCode(data.code);
          }

          if (response2.status && response2.status.toString()[0] === '2') {
            const data = await response2.json();

            const serviceList = data.map((item) => ({
              recommendationServiceId: item.id,
              service: {
                id: item.service.id,
                name: item.service.category.categoryDescription,
                discount: item.discount,
                grossValue: item.grossValue,
                netHours: item.netHours,
                hourlyRate: item.hourlyRate,
                expectedHours: item.expectedHours,
                paidHours: item.paidHours,
                totalServiceValue: item.totalServiceValue,
              },
              totalHours: item.totalHours,
            }));
            setServices(serviceList);
          }
        };

        getData();
      }
    }
    const getAdminList = async () => {
      const response = await api.get('/admins?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setAdminList((await response.json()).data);
      }
    };
    getAdminList();

    const getCompanyList = async () => {
      const response = await api.get('/companies?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setCompanyList((await response.json()).data);
      }
    };

    getCompanyList();

    const getCategoryList = async () => {
      const response = await api.get('/services?direction=ASC');
      if (response.status && response.status.toString()[0] === '2') {
        setCategoryList((await response.json()).data);
      }
    };

    getCategoryList();
  }, [id, isNew]);

  useEffect(() => {
    if (!isNew) {
      setDisableFields(true);
    } else {
      setDisableFields(false);
    }
  }, [isNew]);

  const classes = useStyles();
  const history = useHistory();

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  const handleChangeOrderType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setOrderType(event.target.value as string);
  };

  const handleChangeOrderOrigin = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setOrderOrigin(event.target.value as string);
  };

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };

  /*services */
  interface IService {
    recommendationServiceId: string;
    service: {
      id: string;
      name: string;
      discount: string;
      paidHours: string;
      grossValue: number;
      allowance: string;
      netHours: number;
      netValue: number;
      allowanceResponsable: string | any;
      totalHours: number;
      totalServiceValue: number;
    };
  }

  const [services, setServices] = useState<IService[]>([]);

  useEffect(() => {
    const expectHoursArray = services.map((s) => {
      return s.service.netHours;
    });

    const allExpectHours: number = expectHoursArray.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue;
      },
      0
    );

    setExpectHours(allExpectHours);

    const totalValueArray = services.map((s) => {
      return s.service.totalServiceValue;
    });

    const allTotalValue: number = totalValueArray.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue;
      },
      0
    );

    setTotalValue(allTotalValue);

    const allHourlyRate: any = () => {
      if (allTotalValue !== 0 || allExpectHours !== 0) {
        return allTotalValue / allExpectHours;
      } else return 0;
    };
    setHourlyRate(allHourlyRate);
  }, [netHours, expectHours, paidHours, services]);

  function handleDeleteRow(index: number) {
    setServices([...services.slice(0, index), ...services.slice(index + 1)]);
  }

  function handleAddRow() {
    if (
      category &&
      category.id &&
      grossValue &&
      allowanceResponsable &&
      allowanceResponsable.id
    ) {
      if (
        !services
          .map((service) => service.service.name)
          .includes(category.category.categoryDescription)
      ) {
        setServices(
          services.concat({
            recommendationServiceId: '',
            service: {
              id: category.id,
              name: category.category.categoryDescription,
              discount,
              grossValue,
              paidHours,
              allowance,
              netHours,
              netValue,
              allowanceResponsable,
              totalHours: category.executionTime,
              totalServiceValue,
            },
          })
        );

        setCategory({
          id: '',
          executionTime: '0',
          serviceDescription: '',
        });
        setDiscount('');
        setPaidHours('');
        setGrossValue(0);
        setOrderSite('0');
        setAllowance('');
        setAllowanceResponsable({});
      } else {
        Swal.fire({
          title: 'Item já adicionado',
          text:
            'Exclua a linha do item desejado e o adicione novamente para fazer alterações',
          icon: 'warning',
        });
      }
    } else {
      Swal.fire({
        title: 'Atenção',
        text: 'Preencha todos os campos para adicionar um serviço.',
        icon: 'warning',
      });
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      let result, result2;
      if (isNew) {
        result = await api
          .put('/recommendation', {
            id,
            type,
            company,
            responsableAoki,
            orderOrigin,
            orderType,
            orderSite,
            description,
            comment,
            expectHours,
            totalValue,
            hourlyRate,
            status,
            enabled: true,
          })
          .then(async (response) => {
            const data = await response.json();

            result2 = await api.put('/recommendation-service', {
              recommendation: { id: data.id },
              services: services.map((service) => ({
                id: service.service.id,
                discount: service.service.discount,
                paidHours: service.service.paidHours,
                grossValue: service.service.grossValue,
                allowance: service.service.allowance,
                netHours: service.service.netHours,
                netValue: service.service.netValue,
                allowanceResponsable: service.service.allowanceResponsable,
                totalHours: service.service.totalHours,
                totalServiceValue: service.service.totalServiceValue,
              })),

              flag: status,
            });
          });
      } else {
        result = await api
          .patch('/recommendation/' + id, {
            type,
            company,
            responsableAoki,
            orderOrigin,
            orderType,
            orderSite,
            description,
            comment,
            expectHours,
            totalValue,
            hourlyRate,
            status,
            enabled: true,
          })
          .then(async (response) => {
            const data = await response.json();

            result2 = await api.patch('/recommendation-service/' + id, {
              recommendation: { id: data.id },
              services: services.map((service) => ({
                recommendationServiceId: service.recommendationServiceId,
                id: service.service.id,
                discount: service.service.discount,
                paidHours: service.service.paidHours,
                grossValue: service.service.grossValue,
                allowance: service.service.allowance,
                netHours: service.service.netHours,
                netValue: service.service.netValue,
                allowanceResponsable: service.service.allowanceResponsable,
                totalHours: service.service.totalHours,
                totalServiceValue: service.service.totalServiceValue,
              })),

              flag: status,
            });
          });
      }
      if (result2) {
        if (result2.status && result2.status.toString()[0] === '2') {
          Swal.fire({
            title: 'Serviço cadastrado com sucesso!',
            icon: 'success',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Continuar',
            cancelButtonColor: '#418107',
            cancelButtonText: 'Ver Todos',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(false);
            } else {
              history.push('/solicitacoes');
            }
          });
        }
      } else {
        Swal.fire({
          title: 'Não foi possível salvar 😢',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Não foi possível cadastrar. Tente novamente mais tarde',
        icon: 'error',
      });
    }
  }

  async function recommedationAproved(recommedation_id: string) {
    Swal.fire({
      title: 'Você deseja aprovar essa recomendação?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#418107',
      confirmButtonText: 'Sim, Aprovar!',
      cancelButtonText: 'Cancelar!',
    })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            const aprovedRecommedation = async () => {
              api
                .post(`/recommendation/change-status/${recommedation_id}`)
                .then((response) => history.push('/recomendacoes'));
            };

            aprovedRecommedation();
          } catch (error) {
            history.push('/recomendacoes');
          }
        }
      })
      .catch((error) => {
        history.push('/recomendacoes');
      });
  }

  useEffect(() => {
    const selectedCategory = () =>
      categoryList.map((item, index) => {
        if (item.id === category.id) {
          setCategoryIndex(index);
        }
      });
    selectedCategory();
  }, [category]);

  function parseNumber(value) {
    return Number(value.replace(',', '.'));
  }

  useEffect(() => {
    if (orderSite === '1-Presencial') {
      setGrossValue(parseNumber(categoryList[categoryIndex].onsitePrice));
    } else if (orderSite === '2-Online') {
      setGrossValue(parseNumber(categoryList[categoryIndex].onlinePrice));
    } else if (orderSite === '3-Interno') {
      setGrossValue(parseNumber(categoryList[categoryIndex].internalPrice));
    }
  }, [categoryIndex, orderSite]);

  useEffect(() => {
    let netHours = Number(category.executionTime - Number(paidHours));
    setNetHours(netHours);

    let finalPrice = Number(grossValue - grossValue * (Number(discount) / 100));
    setNetValue(finalPrice);

    let totalnetHours = Number(netHours * finalPrice);
    setTotalServiceValue(totalnetHours);
  }, [discount, paidHours, category.executionTime, grossValue]);

  return (
    <main>
      <div className="cadastrar">
        <div className="grid-box grid-one">
          <div className="card">
            <div className="card-body">
              <div>
                <h1>Aprovação de Recomendações</h1>
                <form onSubmit={handleSubmit}>
                  <Input type="hidden" name="id" value={id} />
                  <div className={classes.grid}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Tipo de solicitação</InputLabel>
                      <Select
                        value={orderType}
                        onChange={handleChangeOrderType}
                        label="Tipo de solicitação"
                        disabled={isRead}
                      >
                        <MenuItem value={'SL-Solicitação'}>
                          SL-Solicitação
                        </MenuItem>
                        <MenuItem value={'RC-Recomendação'}>
                          RC-Recomendação
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {!isRead === false && (
                      <TextField
                        label="Código do produto"
                        disabled
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={code}
                        multiline
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      />
                    )}

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Origem da solicitação</InputLabel>
                      <Select
                        value={orderOrigin}
                        onChange={handleChangeOrderOrigin}
                        label="Origem da solicitação"
                        disabled={isRead}
                      >
                        <MenuItem value={'1-Cliente'}>1-Cliente</MenuItem>
                        <MenuItem value={'2-Consultor Aoki'}>
                          2-Consultor Aoki
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Natureza</InputLabel>
                      <Select
                        value={type}
                        onChange={handleChangeType}
                        label="Natureza"
                        disabled={isRead}
                      >
                        <MenuItem value={'1-Configuração'}>
                          1-Configuração
                        </MenuItem>
                        <MenuItem value={'2-Implantação'}>
                          2-Implantação
                        </MenuItem>
                        <MenuItem value={'3-Treinamento'}>
                          3-Treinamento
                        </MenuItem>
                        <MenuItem value={'4-Desenvolvimento'}>
                          4-Desenvolvimento
                        </MenuItem>
                        <MenuItem value={'5-Relatório'}>5-Relatório</MenuItem>
                        <MenuItem value={'6-Visita Técnica'}>
                          6-Visita Técnica
                        </MenuItem>
                        <MenuItem value={'7-Reunião Técnica'}>
                          7-Reunião Técnica
                        </MenuItem>
                        <MenuItem value={'X-Outros'}>X-Outros</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.grid}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Status*</InputLabel>
                      <Select
                        id="select"
                        native
                        required
                        value={status}
                        disabled={isRead}
                        onChange={handleChangeStatus}
                        label="Status*"
                      >
                        <option aria-label="None" value="" />
                        <option value={'1-Em Aberto'}>1-Em Aberto</option>
                        <option value={'2-Firmada'}>2-Firmada</option>
                        <option value={'3-Cancelada'}>3-Cancelada</option>
                      </Select>
                    </FormControl>
                    <Autocomplete
                      value={company}
                      disabled={isRead}
                      getOptionLabel={(company) =>
                        company.name ? company.name : ''
                      }
                      options={companyList}
                      onChange={(event, value) => {
                        setCompany(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          variant="outlined"
                          label="Empresa"
                        />
                      )}
                    />
                    <Autocomplete
                      value={responsableAoki}
                      getOptionLabel={(admin) => (admin.name ? admin.name : '')}
                      options={adminList}
                      disabled={isRead}
                      onChange={(event, value) => {
                        setResponsableAoki(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          variant="outlined"
                          label="Responsável Aoki"
                        />
                      )}
                    />
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      label="Descrição"
                      required
                      variant="outlined"
                      value={description}
                      disabled={isRead}
                      multiline
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      label="Observação(Uso restrito)"
                      variant="outlined"
                      disabled={isRead}
                      value={comment}
                      multiline
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                  </div>
                  <div className={classes.grid}>
                    <TextField
                      label="Quantidade de horas previstas"
                      disabled
                      variant="outlined"
                      name="expectHours"
                      value={expectHours}
                      onChange={(e) => {
                        setExpectHours(Number(e.target.value));
                      }}
                    />
                    <TextField
                      label="Preço hora médio"
                      disabled
                      variant="outlined"
                      name="hourlyRate"
                      value={hourlyRate.toFixed(2)}
                      onChange={(e) => {
                        setHourlyRate(Number(e.target.value));
                      }}
                    />
                    <TextField
                      label="Valor total"
                      variant="outlined"
                      disabled
                      name="totalValue"
                      value={totalValue.toFixed(2)}
                      onChange={(e) => {
                        setTotalValue(Number(e.target.value));
                      }}
                    />
                  </div>
                  <div className={classes.grid}>
                    <br></br>
                    <h2>Recomendações</h2>
                  </div>

                  {!isRead === true && (
                    <div className={classes.grid}>
                      <Autocomplete
                        value={category}
                        getOptionLabel={(data: any) =>
                          data?.category?.categoryDescription
                            ? data?.category?.categoryDescription
                            : ''
                        }
                        options={categoryList}
                        onChange={(event, value) => {
                          setCategory({
                            ...category,
                            ...value,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={disableFields}
                            variant="outlined"
                            label="Itens da solicitação*"
                          />
                        )}
                      />
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Local de execução*</InputLabel>
                        <Select
                          value={orderSite}
                          onChange={(e) =>
                            setOrderSite(e.target.value as string)
                          }
                          label="Local de execução"
                          disabled={isRead}
                        >
                          <MenuItem value={'1-Presencial'}>
                            1-Presencial
                          </MenuItem>
                          <MenuItem value={'2-Online'}>2-Online</MenuItem>
                          <MenuItem value={'3-Interno'}>3-Interno</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  <div className={classes.grid}>
                    {!isRead === true && (
                      <TextField
                        label="Descrição"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        disabled
                        value={category?.serviceDescription}
                        onChange={(e: any) => {
                          setCategory({
                            ...category,
                            serviceDescription: e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>

                  {!isRead === true && (
                    <div className={classes.grid}>
                      <TextField
                        label="Preço unitário"
                        variant="outlined"
                        value={grossValue.toFixed(2)}
                        disabled
                        onChange={(e) => {
                          setGrossValue(Number(e.target.value));
                        }}
                      />
                      <TextField
                        label="Desconto %"
                        variant="outlined"
                        type="number"
                        value={discount}
                        onChange={(e: any) => {
                          let value: string = e.target.value;
                          let parsedValue: number = Number(value);

                          if (parsedValue < 0 || parsedValue > 99.9) {
                            const notValid =
                              value.split(',')[1]?.length > 2 ||
                              value.split('.')[1]?.length > 2;

                            Swal.fire({
                              title: 'Atenção',
                              text: 'Digite um valor entre 0,00 e 99,9 ',
                              icon: 'warning',
                            });
                            setDiscount('');

                            if (notValid && parsedValue) return;
                          } else {
                            setDiscount(value);
                          }
                        }}
                      />
                      <TextField
                        label="Preço Líquido"
                        disabled
                        variant="outlined"
                        value={netValue.toFixed(2)}
                        onChange={(e) => {
                          setNetValue(parseFloat(e.target.value));
                        }}
                      />
                    </div>
                  )}
                  {!isRead === true && (
                    <div className={classes.grid}>
                      <TextField
                        label=" Quantidade total ou horas"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={category.executionTime}
                        onChange={(e) =>
                          setCategory({
                            ...category,
                            executionTime: e.target.value,
                          })
                        }
                      />
                      <TextField
                        label="Quantidade bonificada"
                        variant="outlined"
                        type="number"
                        value={paidHours}
                        onChange={(e: any) => {
                          let value: string = e.target.value;
                          let parsedValue: number = Number(value);

                          if (
                            parsedValue < 0 ||
                            parsedValue >= category.executionTime
                          ) {
                            Swal.fire({
                              title: 'Atenção',
                              text:
                                'Digite um valor menor que a Quantidade total ou horas',
                              icon: 'warning',
                            });
                            setPaidHours('');
                          } else {
                            return setPaidHours(value);
                          }
                        }}
                      />
                      <TextField
                        label="Quantidade líquida"
                        variant="outlined"
                        disabled
                        value={netHours}
                        onChange={(e: any) => {
                          setNetHours(parseFloat(e.target.value));
                        }}
                      />
                      <TextField
                        label="Valor total líquido"
                        disabled
                        variant="outlined"
                        value={totalServiceValue.toFixed(2)}
                        onChange={(e) => {
                          setTotalServiceValue(parseFloat(e.target.value));
                        }}
                      />
                    </div>
                  )}
                  {!isRead === true && (
                    <div className={classes.grid}>
                      <TextField
                        label="Natureza da bonificação"
                        variant="outlined"
                        value={allowance}
                        multiline
                        onChange={(e) => {
                          setAllowance(e.target.value);
                        }}
                      />
                      <Autocomplete
                        value={allowanceResponsable}
                        getOptionLabel={(admin) =>
                          admin.name ? admin.name : ''
                        }
                        options={adminList}
                        onChange={(event, value) => {
                          setAllowanceResponsable(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Responsável pela bonificação (AOKI)*"
                          />
                        )}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginBottom: 40,
                    }}
                  >
                    {!isRead === true && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        type="button"
                        endIcon={<AddIcon />}
                        onClick={() => {
                          handleAddRow();
                        }}
                      >
                        Adicionar Serviço
                      </Button>
                    )}
                  </div>

                  <div className={classes.grid}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead className={classes.head}>
                          <TableRow>
                            <TableCell>Serviço</TableCell>
                            <TableCell>Horas Líquidas </TableCell>
                            <TableCell>Preço Unitário</TableCell>
                            <TableCell>Desconto</TableCell>
                            <TableCell>Horas Pagas</TableCell>
                            <TableCell>Valor Total do Serviço</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.body}>
                          {services.map((service, index) => (
                            <TableRow key={`row-aclitem-${index}`} hover>
                              <TableCell component="th" scope="row">
                                {service.service.name}
                              </TableCell>
                              <TableCell>{service.service.netHours}</TableCell>
                              <TableCell>
                                R$ {service.service.grossValue}
                              </TableCell>
                              <TableCell>
                                {service.service.discount
                                  ? service.service.discount
                                  : '0'}
                                %
                              </TableCell>
                              <TableCell>
                                {service.service.paidHours
                                  ? service.service.paidHours
                                  : '0'}
                              </TableCell>
                              <TableCell>
                                R${' '}
                                {service.service.totalServiceValue.toFixed(2)}
                              </TableCell>
                              <TableCell align="right" padding="none">
                                {!isRead === true ? (
                                  <Tooltip
                                    title="Excluir"
                                    onClick={() => handleDeleteRow(index)}
                                  >
                                    <IconButton aria-label="Excluir">
                                      <HighlightOffIcon className="delete" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <IconButton>
                                    <CheckCircleIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <div className={classes.button}>
                    <div
                      style={{
                        display: 'flex',
                        width: '99%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<BackIcon />}
                        onClick={() => {
                          history.push('/recomendacoes');
                        }}
                      >
                        Voltar
                      </Button>

                      {isRead && (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          startIcon={<CheckBoxRoundedIcon />}
                          onClick={() => {
                            recommedationAproved(id);
                          }}
                        >
                          Aprovar
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
